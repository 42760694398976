import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import { RADIUS } from "@consts/roundCorners";
import { ASPECT_RATIO } from "@consts/aspectRatio";

import imageDivka from "./krejcova.png";
import imageLogo from "./logoCT.svg";
import {
  container, containerBox, containerBoxIcon, containerBoxlogo, containerBoxlogoImg, containerLink,
  containerMainTitle, containerParagraph, containerSubTitle, titlePosition
} from "@components/reusables/QuestionContactBox/QuestionContactBox.css";

export const QuestionContactBox = () => {
  return (
    <div className={classNames(container)}>
      <h2 className={classNames(containerMainTitle)}>Máte dotaz? Kontaktujte nás</h2>
      <div className={classNames(containerBox)}>
        <div className={classNames(containerBoxlogo)}>
          <ImageBlock
            image={{
              downloadUrl: imageLogo.src,
              width: 96,
              height: 96,
              title: "Česká Televize",
            }}
            cropAspectRatio={ASPECT_RATIO.SQUARE}
            radius={RADIUS.CIRCLE}
          />
          <h3 className={classNames(containerSubTitle)}>
            Novinářské dotazy
            <span className={classNames(titlePosition)}>tiskový servis</span>
          </h3>
          <div>
            <a
              href="mailto:pressct@ceskatelevize.cz"
              className={classNames(containerBoxIcon,containerLink)}
            >
              <SVGmail />
              pressct@ceskatelevize.cz
            </a>
          </div>
        </div>
      </div>
      <div className={classNames(containerBox)}>
        <div className={classNames(containerBoxlogo)}>
          <div className={classNames(containerBoxlogoImg)}>
            <ImageBlock
              image={{
                downloadUrl: imageDivka.src,
                width: 96,
                height: 96,
                title: "Vendula Krejčová",
              }}
              cropAspectRatio={ASPECT_RATIO.SQUARE}
              radius={RADIUS.CIRCLE}
            />
          </div>
          <h3 className={classNames(containerSubTitle)}>
            Vendula Krejčová
            <span className={classNames(titlePosition)}>tisková mluvčí</span>
          </h3>
          <div>
            <a
              href="mailto:vendula.krejcova@ceskatelevize.cz"
              className={classNames(containerBoxIcon, containerLink)}
            >
              <SVGmail />
              vendula.krejcova@ceskatelevize.cz
            </a>
            <a href="tel:+420736531228" className={classNames(containerBoxIcon, containerLink)}>
              <SVGphone />
              736 531 228
            </a>
          </div>
        </div>
      </div>
      <p className={classNames(containerParagraph)}>
        Jste divák a potřebujete poradit? Obraťte se, prosím, na Divácké centrum České televize{" "}
        <Link className={classNames(containerLink)} href="../kontakty/#kontaktniFormular">zde</Link>.
      </p>
    </div>
  );
};

const SVGmail = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.22231 4.22199C8.5183 -0.073997 15.4823 -0.073997 19.7783 4.22199C24.0743 8.51798 24.0743 15.482 19.7783 19.778C15.4823 24.074 8.5183 24.074 4.22231 19.778C-0.0728005 15.482 -0.0728005 8.51798 4.22231 4.22199ZM19.0003 10.5L12.4476 13.7764L12.3241 13.8281C12.1143 13.8999 11.8864 13.8999 11.6765 13.8281L11.5531 13.7764L5.00035 10.5V15.4C5.00035 16.2346 5.63931 16.9199 6.45471 16.9935L6.60035 17H17.4003C18.2349 17 18.9202 16.361 18.9938 15.5456L19.0003 15.4V10.5ZM17.4003 7H6.60035C5.76578 7 5.08046 7.63896 5.00689 8.45437L5.00035 8.6V9.25L11.5531 12.5264L11.6765 12.5781C11.8864 12.6499 12.1143 12.6499 12.3241 12.5781L12.4476 12.5264L19.0003 9.25V8.6C19.0003 7.76544 18.3614 7.08011 17.546 7.00654L17.4003 7Z"
        fill="#041E42"
      />
    </svg>
  );
};

const SVGphone = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.72143 19.778C9.01754 24.074 15.9818 24.074 20.2779 19.778C24.574 15.482 24.574 8.51798 20.2779 4.22199C15.9818 -0.073997 9.01754 -0.073997 4.72143 4.22199C0.426191 8.51798 0.426191 15.482 4.72143 19.778ZM10.6278 7.42711C10.9538 7.75235 11.023 8.25508 10.7969 8.6564L9.88427 10.0414C9.73307 10.3088 9.77932 10.644 9.99734 10.8607L13.6268 14.4812C13.8442 14.6984 14.1802 14.7442 14.448 14.5931L15.8357 13.682C16.2379 13.4558 16.7418 13.5248 17.068 13.8507L18.6991 15.4778C19.1003 15.8788 19.1003 16.5282 18.6991 16.9292L17.9269 17.6995C17.0462 18.5759 15.685 18.7527 14.6087 18.1305L14.535 18.0885C11.3351 16.2646 8.65874 13.651 6.76256 10.4986L6.39182 9.88131C5.74341 8.80512 5.91292 7.42673 6.80288 6.5388L7.54261 5.80088C7.73546 5.60824 7.99717 5.5 8.27008 5.5C8.54299 5.5 8.8047 5.60824 8.99754 5.80088L10.6278 7.42711Z"
        fill="#041E42"
      />
    </svg>
  );
};

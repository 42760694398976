import React from "react";
import {
  UneditablePageRegister,
  UneditablePageBase,
  UneditablePageProps,
} from "@components/core/PageTypes/UneditablePage/UneditablePage";
import { ApplicationsPage } from "./pages/applications/ApplicationsPage/ApplicationsPage";
import { ApplicationsSmartTVPage } from "./pages/applications/ApplicationsSmartTVPage/ApplicationsSmartTVPage";
import { ApplicationsHbbTVPage } from "./pages/applications/ApplicationsHbbTVPage/ApplicationsHbbTVPage";
import { ApplicationsMobilePage } from "./pages/applications/ApplicationsMobilePage/ApplicationsMobilePage";
import { RegionalBroadcastingHomePage } from "./pages/RegionalBroadcastingHomePage/RegionalBroadcastingHomePage";

// registrovane staticke stranky
const staticPages: UneditablePageRegister = {
  ApplicationsPage: ApplicationsPage,
  ApplicationsSmartTVPage: ApplicationsSmartTVPage,
  ApplicationsHbbTVPage: ApplicationsHbbTVPage,
  ApplicationsMobilePage: ApplicationsMobilePage,
  RegionalBroadcastingHomePage: RegionalBroadcastingHomePage,
};

export const UneditablePage = ({ page, allPages }: UneditablePageProps) => {
  return (
    <UneditablePageBase page={page} allPages={allPages} registerdUneditablePages={staticPages} />
  );
};

import React, { useRef, useState, memo } from "react";
import classNames from "classnames";
import {deleteButton} from "@components/reusables/Form/inputs/FileInput/FileInput.css";

export const MB = 1048576;
const DEFAULT_MAX_SIZE = 4 * MB; // 4 MB

interface FileFieldProps extends Omit<React.HTMLProps<HTMLInputElement>, "type"> {
  maxSize?: number | string | null | undefined;
  required?: boolean;
  displayValidationStatus?: boolean;
}

export const FileInput = memo(
  React.forwardRef<HTMLInputElement, FileFieldProps>(
    (
      { maxSize, required = false, onChange, displayValidationStatus, ...restProps },
      forwardedRef
    ) => {
      const inputElmRef = useRef<HTMLInputElement>(null);
      const originalOnChange = onChange;
      const maxSizeReal: number = maxSize ? (maxSize as number) : DEFAULT_MAX_SIZE;
      const [allowReset, setAllowReset] = useState<boolean>(false);

      const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputElement = inputElmRef.current;
        const value = inputElement?.value;
        const files = event.target.files;
        if (files?.length == 1 && files[0].size > maxSizeReal) {
          handleReset();
          return;
        }
        setAllowReset(!!value);
        inputElement && callForwardedRef(inputElement);
        originalOnChange && originalOnChange(event);
      };

      const callForwardedRef = (inputElement: HTMLInputElement) => {
        if (typeof forwardedRef === "function") {
          forwardedRef(inputElement);
        } else if (forwardedRef && forwardedRef.current) {
          forwardedRef.current.value = inputElement.value;
        }
      };

      const handleReset = () => {
        if (inputElmRef && inputElmRef.current) {
          inputElmRef.current.value = "";
          inputElmRef.current.dispatchEvent(new Event("change", { bubbles: true }));
        }
      };

      return (
        <>
          <input
            type="file"
            ref={inputElmRef}
            required={required}
            onChange={(event) => {
              handleOnChange(event);
            }}
            {...restProps}
          />
          {allowReset ? (
            <button
              type="button"
              className={classNames(deleteButton)}
              onClick={() => handleReset()}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(-18, -21)">
                  <g id="Icon" transform="translate(12, 15.5)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="rgb(237 28 36 / 100%)"
                      d="M7.7,5.8l4.3,4.3l4.3-4.3c0.4-0.4,0.9-0.4,1.3-0.1l0.1,0.1l0,0c0.4,0.4,0.4,1,0,1.4l0,0l-4.3,4.3l4.3,4.3
                    c0.4,0.4,0.4,1,0,1.4l0,0c-0.4,0.4-1,0.4-1.4,0L12,12.9l-4.3,4.3c-0.4,0.4-0.9,0.4-1.3,0.1l-0.1-0.1l0,0c-0.4-0.4-0.4-1,0-1.4
                    l0,0l4.3-4.3L6.3,7.2c-0.4-0.4-0.4-1,0-1.4l0,0C6.7,5.4,7.3,5.4,7.7,5.8z"
                    />
                  </g>
                </g>
              </svg>
            </button>
          ) : null}
        </>
      );
    }
  )
);

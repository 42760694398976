import React from 'react';
import { TextOrHTML } from '../../reusables/TextOrHTML/TextOrHTML';
import { THTMLString } from '../../../types/index';

export interface HTMLBlockProps {
  HTML: THTMLString;
}

export const HTMLBlock = ({ HTML }: HTMLBlockProps) => {
  return <TextOrHTML HTML={HTML} />;
};

import React from "react";
import { UneditablePageProps } from "@components/core/PageTypes/UneditablePage/UneditablePage";
import { DefaultLayout } from "@components/webCareer/Layouts/DefaultLayout/DefaultLayout";
import { Content } from "@czechtv/components";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import {
  mediaMinTablet640,
  Hero36Medium,
  Red_base,
} from "@czechtvPackagesOverrides/styles/fromCTV/index";
import { GRID_GAP } from "@consts/consts";

import backImg from "./careerBack.jpg";

const useStyles = createUseStyles({
  moveToTop: {
    marginTop: `-${GRID_GAP * 3}px`,
    [mediaMinTablet640]: {
      marginTop: `-${GRID_GAP * 5}px`,
    },
  },
  alert: {
    display: "block",
    padding: `${GRID_GAP * 4}px ${GRID_GAP * 2}px`,
    backgroundColor: Red_base,
    color: "#FFFFFF",
    textAlign: "center",
    ...Hero36Medium,
    marginBottom: `${GRID_GAP * 4}px`,
    [mediaMinTablet640]: {
      marginBottom: `${GRID_GAP * 8}px`,
      padding: `${GRID_GAP * 10}px`,
    },
  },
});

export const JobsCzTemplatePage: React.FC<UneditablePageProps> = ({ page, allPages }) => {
  const classes = useStyles();
  return (
    <DefaultLayout
      page={page}
      allPages={allPages}
      headerImage={{ downloadUrl: backImg.src, title: "Kariera ČT", width: 2500, height: 713 }}
    >
      <Content>
        <div className={classNames(classes.alert, classes.moveToTop)}>
          Zde bude výpis pozic JOBS.czss
        </div>
      </Content>
    </DefaultLayout>
  );
};

import React from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import { THTMLString } from "../../../types/index";
import {container} from "@components/reusables/TextOrHTML/TextOrHTML.css";

export interface TextOrHTMLProps {
  HTML?: THTMLString;
  text?: string;
}

export const TextOrHTML = ({ text = undefined, HTML = undefined }: TextOrHTMLProps) => {
  const router = useRouter();

  // doacasne reseni linku v HTML - rozpracovane reseni v utils.tsx
  let linkSafeHTML = HTML;
  if (linkSafeHTML !== undefined) {
    linkSafeHTML = linkSafeHTML.replace(
      /href="\/documents/g,
      `download href="/api/media/documents`
    );
    linkSafeHTML = linkSafeHTML.replace(/href="\//g, `href="${router.basePath}/`);
  }

  if (linkSafeHTML !== undefined)
    return (
      <div className={classNames(container)}>
        <div dangerouslySetInnerHTML={{ __html: linkSafeHTML }} />
      </div>
    );
  else
    return <div className={classNames(container)}>{text ? <div>{text}</div> : null}</div>;
};

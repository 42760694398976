export const object2FormData = (dataObject: Record<string, any>): FormData => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(dataObject)) {
    if (value instanceof FileList) {
      // Rozlišujeme způsob přidání do FormData na základě počtu souborů v FileList
      if (value.length == 1) {
        // Pokud je pouze jeden soubor, přidáme ho bez []
        formData.append(key, value[0]);
      } else {
        // Pokud je více souborů, použijeme [] v názvu klíče
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[]`, value[i]);
        }
      }
    } else {
      formData.append(key, value);
    }
  }
  return formData;
}

export type ResponseData = {
  success?: boolean,
  message?: string
}

export const sendForm = async (url: string, data: any): Promise<void> => {
  const formData = object2FormData(data);

  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    // prisel JSON (to je dobre)
    if (response.headers.get("Content-Type")?.includes("application/json")) {

      // chyba v response
      if (!response.ok) {
        throw new Error(
          `Error: ${response.status}: Form could not be submitted`
        );
      }

      // ziskani response dat
      const responseData: ResponseData = await response.json();

      // chyba zpracovani na serveru
      if (responseData.success === false) {
        throw new Error(`Error: On server form processing - ${responseData.message}`);
      }

    }

    // prislo neco jineho nez JSON
    else {
      const responseText = await response.text();
      throw new Error(`Error: Bad response format (status: ${response.status}) - ${response.statusText}. Details: ${responseText}`)
    }

  }

  // vyjimka pri fetchi
  catch (error: any) {
    console.error(`Error: Fetch exception`, error.message);
    throw error;
  }

}

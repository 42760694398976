type InternshipTypeWithTargetEmail = {
  label: string;
  targetEmail?: string;
}

export const defaultToEmail = 'kariera@ceskatelevize.cz';
//export const defaultToEmail = 'andrea.poborska@ceskatelevize.cz';

export const intershipTypesWithTargetEmails: InternshipTypeWithTargetEmail[] = [
  { label: "Stáž pro studenty SŠ - školní / odborná praxe", },
  { label: "Stáž pro studenty VŠ - program ČT Start", },
  { label: "Stáž pro studenty VŠ - krátkodobá odborná praxe", },
  { label: "Stáž ve zpravodajství - ČT 24 Akademie", targetEmail: "akademie@ceskatelevize.cz" },
]

export const intershipType = intershipTypesWithTargetEmails.map((item: InternshipTypeWithTargetEmail) => item.label)

export const getToEmail = (label: string) => {
  const targetEmail = intershipTypesWithTargetEmails.find((item: InternshipTypeWithTargetEmail) => item.label === label)?.targetEmail;
  return targetEmail || defaultToEmail;
}

import { ArticleSummaryPage } from "./PageTypes/ArticleSummaryPage/ArticleSummaryPage";
import { ArticlePage } from "./PageTypes/ArticlePage/ArticlePage";
import { UneditablePage } from "./PageTypes/UneditablePage/UneditablePage";
import { staticContentBlocks } from "./StaticContentComponents/index";

const sectionId = "webForMedia";

const baseSectionPath = "/pro-media";

const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);

const pageTypes = [
  { id: "ArticleSummaryPage", component: ArticleSummaryPage },
  { id: "ArticlePage", component: ArticlePage },
  { id: "UneditablePage", component: UneditablePage },
];

export { staticContentBlocks };

export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
  staticContentBlocks: staticContentBlocks,
};

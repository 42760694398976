import { useState, useEffect } from "react";
import Link from "next/link";
import { Spinner } from "@components/reusables/Spinner/Spinner";
import { StaticContentBlockComponentProps } from "@components/blocks/StructuredContentBlock/sublocks/StaticContentBlock/StaticContentBlock";
import { VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";
import {
  ctCouncilVideo,
  ctCouncilVideo_h2,
  playBannerStyle,
  playBannerStyle_h2,
  playBannerStyle_link,
} from "./../../Layouts/CtCouncilHomePageLayout/CtCouncilHomePageLayou.css";

const getData = async (rootUrl: string) => {
  const response = await fetch(`/vse-o-ct/api/services/web-ct-council/actual-meeting/${rootUrl}`);
  return await response.json();
};

export const ActualCtCouncilMeeting = ({ parameters }: StaticContentBlockComponentProps) => {
  const rootUrl = parameters?.rootUrl;
  const [meeting, setMeeting] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!rootUrl) return;
    getData(rootUrl)
      .then((data) => {
        setMeeting(data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  }, [rootUrl]);

  const getBannerTexts = (meeting: any) => {
    return meeting?.onAir
      ? {
          hedline: `Dnes probíhá: ${meeting.title}`,
          button: "Sledovat",
        }
      : {
          hedline: `Pozvánka a materiály: ${meeting.title}`,
          button: "Zobrazit více",
        };
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {meeting && (
            <>
              {/** baner */}
              <div className={playBannerStyle}>
                <h2 className={playBannerStyle_h2}>{getBannerTexts(meeting)?.hedline}</h2>
                <Link href={meeting.path} className={playBannerStyle_link}>
                  {getBannerTexts(meeting)?.button}
                </Link>
              </div>

              {/** video */}
              {meeting?.meetingRecord && meeting?.onAir && (
                <div className={ctCouncilVideo}>
                  <h2 className={ctCouncilVideo_h2}>{meeting.title}</h2>
                  <VideoBlock
                    videoID={meeting.meetingRecord.id}
                    videoIdType={meeting.meetingRecord.videoIdType}
                    previewImageUrl={meeting.meetingRecord.thumbnail?.downloadUrl}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

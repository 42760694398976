import { registeredSiteSections } from "@utils/pages";

export type TComponentSection =
  | "CAREER"
  | "HISTORY"
  | "TECHNOLOGY"
  | "ARCHIVE"
  | "FOR_MEDIA"
  | "CT_COUNCIL"
  | "PEOPLE"
  | "CONTACTS"
  | "CORE";

const getSectionBySlug = (slug: string): any => {
  const splitedSlug = slug.split("_");
  let sectionId = splitedSlug
    .map((part: string) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join("");
  sectionId = `web${sectionId}`;
  return registeredSiteSections.find((section: any) => section.sectionId === sectionId);
};

export const getRegisteredComponent = (
  section: TComponentSection,
  componentName: string
): undefined | any => {
  const staticBlocks = getSectionBySlug(section)?.staticContentBlocks;
  const staticBlock = staticBlocks.find((block: any) => block.id === componentName);
  return staticBlock?.component ? staticBlock.component : undefined;
};

import { useState } from "react";
import { slugify } from "@utils/slugify";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import classNames from "classnames";
import Link from "next/link";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";

import {
  wrap,
  itemWrap,
  itemWrapOpen,
  item,
  itemVisible,
  itemWrapInvisible,
  question,
  answer,
  answerOpen,
  answerFile,
  faqReportBug,
  faqReportBugTxt,
  faqReportBugBtn,
  faqReportBugTxtStrong,
} from "./FaqBlock.css";


interface FaqBlockProps {
  faqCategories: any[];
  displayCategorySlug?: string | undefined;
  faqItemUrl?: string | null; //url z boxu nasli jste chybu
}

interface FaqBoxReportBugProps {
  faqItemUrl: string;
}

const TOP_CATEGORY_LABEL = "Nejčastější dotazy";
export const TOP_CATEGORY_SLUG = slugify(TOP_CATEGORY_LABEL);

const createConsolidatedItem = (
  item: any,
  categoryIdent?: undefined | string,
  categoryLabel?: undefined | string
) => {
  let categorySlug = categoryIdent;
  if (!categorySlug && categoryLabel) categorySlug = slugify(categoryLabel);
  if (!categorySlug) categorySlug = TOP_CATEGORY_SLUG;
  return {
    question: item.question,
    answer: item.answer,
    top: item.top,
    documents: item.documents,
    categoryLabel: categoryLabel != undefined ? categoryLabel : TOP_CATEGORY_LABEL,
    categorySlug: categorySlug,
  };
};

const consolidateFaqs = (faqCategories: any[]) => {
  const faqs: any[] = [];
  const topFaqs: any[] = [];
  faqCategories.map((category: any) => {
    category.value.items.map((item: any) => {
      faqs.push(createConsolidatedItem(item, category.value.ident, category.value.headline));
      item.top && topFaqs.push(createConsolidatedItem(item));
    });
  });
  return [...topFaqs, ...faqs];
};

export const getFaqsCategories = (faqCategories: any[]) => {
  const faqs = consolidateFaqs(faqCategories);
  let categories: any = [];
  faqs.forEach((faq: any) => {
    const categoryLabel = faq.categoryLabel;
    if (!categories.find((c: any) => c.categoryLabel === categoryLabel)) {
      categories.push({ categoryLabel: categoryLabel, categorySlug: faq.categorySlug });
    }
  });
  return categories;
};

const FaqBlockItem = ({ faq, visible = true }: { faq: any; visible?: boolean | undefined }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      className={classNames(
        itemWrap,
        visible ? null : itemWrapInvisible,
        visible && open ? itemWrapOpen : null
      )}
    >
      <dt
        className={classNames(
          item,
          question,
          visible ? itemVisible : null,
          open ? answerOpen : null
        )}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {faq.question}
      </dt>
      <dd className={classNames(item, answer, visible && open ? itemVisible : null)}>
        <HTMLBlock HTML={faq.answer} />

        {/** pripojene soubory */}
        {faq.documents?.length > 0 ? (
          <div className={classNames(answerFile)}>
            <AttachedDocumentsBlock documents={faq.documents} />
          </div>
        ) : null}
      </dd>
    </div>
  );
};

export const FaqBlock = ({ faqCategories, displayCategorySlug = undefined }: FaqBlockProps) => {
  const faqs = consolidateFaqs(faqCategories);

  // viditelnost itemu
  const isVisible = (faq: any) =>
    (displayCategorySlug == undefined && faq.categorySlug == TOP_CATEGORY_SLUG) ||
    (displayCategorySlug != undefined && faq.categorySlug == displayCategorySlug);

  // klic pro item
  const getItemKey = (faq: any) => slugify(`${faq.categorySlug}-${faq.question}`);

  return (
    <div>
      <dl className={classNames(wrap)}>
        {faqs.map((item: any) => (
          <FaqBlockItem key={getItemKey(item)} faq={item} visible={isVisible(item)} />
        ))}
        {/** <FaqBoxReportBug faqItemUrl='test' /> */}
      </dl>

    </div>
  );
};


const FaqBoxReportBug = ({ faqItemUrl }: FaqBoxReportBugProps) => {
  return (
    <div className={classNames(faqReportBug)}>
      <div className={classNames(faqReportBugTxt)}>
        <strong className={classNames(faqReportBugTxtStrong)}>Nenašli jste odpověď na potíže s přehráváním?</strong>
        <br />Popište nám prosím chybu.
      </div>
      <Link href={faqItemUrl} className={classNames(faqReportBugBtn)}>
        Nahlásit chybu
      </Link>
    </div>
  );
};

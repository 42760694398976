import { WithSideMenuLayout } from "@components/webFaq/Layouts/WithSideMenuLayout";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import { FaqPageContent } from "@components/core/PageTypes/FaqPage/FaqPageContent";
import { FaqContactUsBox } from "@components/webFaq/components/FaqContactUsBox";
import { contentLimit } from "./FaqPage.css";


export const FaqPage = ({ page, allPages }: any) => {
  return (
    <WithSideMenuLayout page={page} allPages={allPages}>
      <div className={contentLimit}>
        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} />

        {/** FAQs s navigaci */}
        <FaqPageContent page={page} />

        {/** Info box s odkazem */}
        <FaqContactUsBox />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? (
          <AttachedDocumentsBlock
            documents={page.attachedDocuments.map((item: any) => item.value)}
          />
        ) : null}
      </div>
    </WithSideMenuLayout>
  );
};

import classNames from "classnames";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import {
  BASE_container, BIG_NUMBERS_container, DEFAULT_container
} from "@components/blocks/StructuredContentBlock/sublocks/OrderedListBlock/OrderedListBlock.css";
import {createUseStyles} from "react-jss";
import {GRID_GAP} from "@consts/consts";
import {Header21MediumBold} from "@czechtvPackagesOverrides/styles";
import {Red_base} from "@czechtv/utils";

type TListStyle = "DEFAULT" | "BIG_NUMBERS";

const styles = {
  BIG_NUMBERS: createUseStyles({
    container: {
      "& li::before": {
        content: `counter(CTCareerTendrcounter)`,
        position: `absolute`,
        width: `${GRID_GAP * 6}px`,
        height: `${GRID_GAP * 6}px`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        backgroundColor: `#FFFFFF`,
        borderRadius: `${GRID_GAP * 2}px`,
        left: `0px`,
        top: `0px`,
        ...Header21MediumBold,
        color: Red_base,
      },
    },
  }),
};

export const OrderedListBlock = ({
  markup,
  listStyle = "DEFAULT",
}: {
  markup: string;
  listStyle?: TListStyle;
}) => {
  const bigNumbersStylesLiBefore =  listStyle === "BIG_NUMBERS" ? styles.BIG_NUMBERS().container : '';
  const container = listStyle === "BIG_NUMBERS" ? BIG_NUMBERS_container : DEFAULT_container;

  return (
    <div className={classNames(BASE_container, container, bigNumbersStylesLiBefore)}>
      <HTMLBlock HTML={markup} />
    </div>
  );
};

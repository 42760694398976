import { CtCouncilMeetingPage } from "./PageTypes/CtCouncilMeetingPage/CtCouncilMeetingPage";
import { BaseContentPage } from "./PageTypes/BaseContentPage/BaseContentPage";
import { staticContentBlocks } from "./StaticContentComponents/index";

const sectionId = "webCtCouncil";

const baseSectionPath = "/rada-ct";

const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);

const pageTypes = [
  { id: "CtCouncilMeetingPage", component: CtCouncilMeetingPage },
  { id: "BaseContentPage", component: BaseContentPage },
];

export { staticContentBlocks };

export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
  staticContentBlocks: staticContentBlocks,
};

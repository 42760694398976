import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcMy7Do4iZu6y6tO0qYd9t0HJpbSmuoatNJB%2FpF%2F8SG2oVuvd%2FDnA8Bo9AurZXdb1zrAeL3vps24Xm2%2FdevR92a%2BX9Sdgm292o62TdfefHplgUOLcbNsP68Xs7dNaGjyZVcv2unbBibAj58Mj6dd06zev5QdGPz%2FPm5Af21m7X75Nu8DdPNJ%2FbHyCtBrBeScArx6TRU9pRX%2FDL%2BW%2FVc2jWvIVJExhaJSGS%2BI%2BoUX9CajM%2BkJVyjSXmFFApn2iHEKSlSAVSGjmfUQkldYlhmlJhLeclqMAixJOrqJV7YspVGBySRvngIzCoiw34bYbcIoypVGYm3C2MdR2GawuwCvD36%2FW4BFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmXXPeK%2BAFFhJbJIXBaffIXEfYyLOrOFru4yjLjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabmDzGAQsHxBZaZlD9eAh1eJOvYGTqTiKcesspo5WUHPEcnx6pRJnjUZwQ5Kp9hME0LIKsglx6k9HQ4z2kTnEklZiRtgrOpeVntvA05l142fz1vQ86nZjg%2Fs0wBMU5GxGNG0jbk4ozkdx2XgOKQdIWCuAQU56QpFcQloElKGyfstAQ0TRCl3OaczS7kmgu5m1Qr15eJct2K81QrF8tU5LjbC7k2vZEi1arKcHc9VxSnOlW55MV5ypOE6%2BRztYoj1dCpTrl5gmmk%2BlOdTO7uqwDl5jA4XAdAL043AciK0%2FsAhsRpF8BqcboNYKXaLoCTavsAXqo9BCik2mOAUqodApRS7SlAJdWeA0%2B24THpAIhCjzAAkhAk4mOhSCYAGiFJNgBaKekCoJOSPsB0P2mno0nz3DYd%2F8d0jUpfGzFsqWAPerkdHJdp8rz4C6uv6Qr05gC4OQBtDiCjqvJ27mxnsnZ1ABndJNNuNI3t9vcfw7HEwm8LAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fcomponents.css.ts.vanilla.css%22%2C%22source%22%3A%22W29iamVjdCBPYmplY3RdOmhvdmVyIHsKICB0cmFuc2l0aW9uOiBhbGwgdmFyKC0tXzE5bXJoYWEyNykgdmFyKC0tXzE5bXJoYWEyNik7CiAgYmFja2dyb3VuZC1wb3NpdGlvbjogcmlnaHQgMHB4IGNlbnRlcjsKfQoueDdhMHdtMCB7CiAgcGFkZGluZzogMCAwIHZhcigtLV8xOW1yaGFhMW8pIDA7CiAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFxKSAwIHZhcigtLV8xOW1yaGFhMXEpIDA7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwogIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1fMTltcmhhYTFlKTsKfQoueDdhMHdtMSB7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc2OHB4KSB7CiAgLng3YTB3bTAgewogICAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFzKSAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDEwMjRweCkgewogIC54N2Ewd20wIHsKICAgIG1hcmdpbjogMCAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FwebHistory%2FPageTypes%2FEventsPage%2FEventsPage.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA71WTXOjMAy951d4Zi%2FlYAbIV%2Bte9rR%2Fo2NABLfG9hglbbrT%2F76DCQk4kHSb3XJDRtJ7epJw%2BBRnFb5n65j8nhFSaIW0Fu%2FASBImSwvV44wQKRTQEsSmxMZ%2BNAMiWFobngm1YYRGYXT0cZEKXgm5Z2TH7R2lT%2FFDZUvOF0HzQaaltv7R3h1V3G6EoqgNI5F565lSjagr3yvWzs3wPHdAosfZxyzsmCWOWRuAkYhEvrcJPI%2F5X3sseh5TIE0wdGGS10izUsh8zNtPYK6B0uegzHgOP%2FjS1z4OR6WPw%2FUn5F1%2Bg7xTTFaOSS5qI%2FmekUKCC%2F%2B8rVEUe5pphaCQkaZngaaArwCq%2BYJLsVFUIFQ1IxkoBNuYU569bKzeqpweCP345Z4BIA%2BuCsYJHCG3nFNtc7DU8lxs61HOPWJrX6IovF9PiPSZGYyCsQmOwig%2Berf4GFFaQfOut9gkOhnOi4OWq9pwCwovFPVMnkllCUF4Q%2BriMCKhwCutlW1t3ZwZLbpsDpNAoRUjXErPLVn7YiWrYeXvXeVfRY4lI4uDdl2xu3ejuxQWJEexg7a8BR7lvhXHg8NxaxR%2BPurx%2BJ6Pw%2Flnpr1d5o2SjMSDVOlwFruuGVUvfggePVw3YIqGnLP%2BerVtpMvTlrFS78B%2BveIXmrSXJv8ysHq3uQVc25h02XTmRHTDsbwlRSGkvEIf%2BvRbSJfZw%2FfIUnwV1z9RxRelC56LXRu8txFdz0xC%2BX8S%2FqwgF5zUmQVQhKuc3FVC0cOSjKNkYd4Clzoc3i8HIz4%2FXSe8OV%2BczCN3zFVycru6mT6uwl01S9xDuzqgnfrF67NfvDlk6wVJzyn3t%2B2F3XaR1zxoPzlu1lTq7OXA9Q81x1Eh0AsAAA%3D%3D%22%7D"
export var componentHeader = 'x7a0wm0';
export var componentHeader_h1 = '_1cmtzc71';
export var hisotryItem = '_1cmtzc74';
export var hisotryItem_h3 = '_1cmtzc75';
export var historyBtn = '_1cmtzc77';
export var historyBtn_back = '_1cmtzc7e';
export var historyBtn_backSvg = '_1cmtzc7f';
export var historyBtn_div = '_1cmtzc7a';
export var historyBtn_next = '_1cmtzc7c';
export var historyBtn_nextSvg = '_1cmtzc7d';
export var historyBtn_span = '_1cmtzc7b';
export var historyBtn_svg = '_1cmtzc78';
export var historyBtn_svgPath = '_1cmtzc79';
export var historyBtns = '_1cmtzc76';
export var historyItems = '_1cmtzc73';
export var historySlider = '_1cmtzc72';
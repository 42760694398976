import React, { useRef, useImperativeHandle, forwardRef, memo } from "react";

interface RadioFieldProps extends Omit<React.HTMLProps<HTMLInputElement>, "type"> {
  required?: boolean;
  displayValidationStatus?: boolean;
}

export const RadioInput = memo(
  React.forwardRef<HTMLInputElement, RadioFieldProps>(
    ({ required = false, onChange, displayValidationStatus, ...restProps }, forwardedRef) => {
      const inputElmRef = useRef<HTMLInputElement>(null);

      useImperativeHandle(forwardedRef, () => inputElmRef.current as HTMLInputElement);

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event);
      };

      return (
        <>
          <input
            type="radio"
            ref={inputElmRef}
            required={required}
            onChange={handleChange}
            {...restProps}
          />
        </>
      );
    }
  )
);

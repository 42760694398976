export const toCamelCase = (str: string): string => {
  let newStr = "";
  if (str) {
    let wordArr = str.split(/[-_]/g);
    wordArr.forEach((part, i) => {
      newStr += i == 0 ? part : part.charAt(0).toUpperCase() + part.slice(1)
    })
  } else {
    return newStr
  }
  return newStr;
}

import { useEffect } from 'react'

export const RedirectPage = ({page}: {page: any}) => {
  const redirectUrl = !!page.redirectTarget.internal?.url ? `/vse-o-ct${page.redirectTarget.internal.url}` : page.redirectTarget.external

  useEffect(() => {
    if (!!redirectUrl) {
      window.location.href = redirectUrl
    }
  }, [redirectUrl])

  return (<></>)
}

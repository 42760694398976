import { useRef, useState, useEffect } from "react";
import Link from "next/link";
import { ApplicationsLayout } from "./../../../../../Layouts/ApplicationsLayout/ApplicationsLayout";
import { PageContainer } from "@components/webTechnology/PageTypes/UneditablePage/pages/applications/PageContainer";
import { useWidth } from "@czechtv/components";
import { useHeight } from "@czechtv/components";
import { OtherPagesSwitch } from "../../../../../Layouts/ApplicationsLayout/components/OtherPagesSwitch/OtherPagesSwitch";
import { SmartImage2 } from "@components/reusables/SmartImage/SmartImage2";
import { Content } from "@czechtv/components";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import {
  mediaMinDesktop1024,
  mediaMinTablet768,
  mediaMinTablet640,
  mediaMinMobile360,
  Red_base,
  Grey_80,
  Grey_20,
  Body16Small,
  Body18Medium,
  ButtonTransitionDuration,
  Blue_base,
} from "@czechtvPackagesOverrides/styles/fromCTV/index";
import { GRID_GAP, ANIMATION_BEZIER_HOVER } from "@consts/consts";
import {
  Hero48LargeMedium,
  Hero60XLargeMedium,
  Hero36MediumMedium,
  GreyCool_10,
} from "@czechtvPackagesOverrides/styles";
import { A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import imageTopMobile1 from "./images/mobile_top_mobile1_x2.png";
import imageTopMobile2 from "./images/mobile_top_mobile2_x2.png";
import imageIconCT from "./images/mobile_icon_ct_x2.png";
import imageIconCT24 from "./images/mobile_icon_ct24_x2.png";
import imageIconCTD from "./images/mobile_icon_ctD_x2.png";
import imageIconCTSport from "./images/mobile_icon_ctSport_x2.png";
import ivysilani1 from "./images/ivysilani_1_x2.png";
import ivysilani2 from "./images/ivysilani_2_x2.png";
import ivysilani3 from "./images/ivysilani_3_x2.png";
import ivysilani4 from "./images/ivysilani_4_x2.png";
import ivysilani5 from "./images/ivysilani_5_x2.png";
import ct241 from "./images/ct24_1_x2.png";
import ct242 from "./images/ct24_2_x2.png";
import ct243 from "./images/ct24_3_x2.png";
import ct244 from "./images/ct24_4_x2.png";
import ctsport1 from "./images/ctsport_1_x2.png";
import ctsport2 from "./images/ctsport_2_x2.png";
import ctsport3 from "./images/ctsport_3_x2.png";
import ctsport4 from "./images/ctsport_4_x2.png";
import decko1 from "./images/decko_1_x2.png";
import decko2 from "./images/decko_2_x2.png";
import decko3 from "./images/decko_3_x2.png";
import decko4 from "./images/decko_4_x2.png";
import decko5 from "./images/decko_5_x2.png";
import decko6 from "./images/decko_6_x2.png";
import bedgeG from "./images/bedgeGoogle_x2.png";
import bedgeA from "./images/bedgeApple_x2.png";

const unifiedCarousel = {
  marginLeft: "auto",
  marginRight: "auto",
  paddingLeft: `${GRID_GAP * 2}px`,
  paddingRight: `0px`,
  [mediaMinTablet640]: {
    paddingLeft: `${GRID_GAP * 3}px`,
  },
  [mediaMinTablet768]: {
    paddingLeft: `${GRID_GAP * 3}px`,
  },
  "@media (min-width: 879px)": {
    paddingRight: `${GRID_GAP * 3}px`,
  },
  "& .swiper-slide": {
    textAlign: "center",
  },
  "& .SwiperSlideP": {
    marginTop: `${GRID_GAP * 2}px`,
    textAlign: "center",
    ...Body16Small,
    color: "#000000",
  },
};

const useStyles = createUseStyles({
  header: {
    margin: `${GRID_GAP * 3}px auto ${GRID_GAP * 4}px auto`,
    position: "relative",
    textAlign: "left",
    color: "#FFFF",
    display: "flex",
    padding: `0px 0px ${GRID_GAP * 18}px 0px`,
    [mediaMinTablet640]: {
      margin: `${GRID_GAP * 6}px auto ${GRID_GAP * 10}px auto`,
      padding: `0px 0px ${GRID_GAP * 18}px 0px`,
    },
    [mediaMinTablet768]: {
      margin: `${GRID_GAP * 6}px auto 0px auto`,
      padding: `0px 0px ${GRID_GAP * 0}px 0px`,
    },
    [mediaMinDesktop1024]: {
      margin: `${GRID_GAP * 6}px auto 0px auto`,
      padding: `0px 0px ${GRID_GAP * 5}px 0px`,
    },
    "&::before": {
      position: "absolute",
      background: `radial-gradient(115% 115% at 50% 0%, #0A3368 0%, #001830 100%);`,
      content: '""',
      left: "-100vw",
      bottom: "0%",
      width: "300vw",
      height: "100vh",
      zIndex: "-1",
      "@media (min-width: 640px) and (max-height: 755px)": {
        height: "180vh",
      },
      "@media (min-width: 640px) and (max-height: 430px)": {
        height: "450vh",
      },
      "@media (min-width: 640px) and (max-height: 200px)": {
        height: "1050vh",
      },
      "@media (min-width: 640px) and (max-height: 100px)": {
        height: "10000vh",
      },
    },
    "& h1": {
      margin: `0px 0px ${GRID_GAP * 2}px 0px`,
      padding: "0px",
      order: "1",
      ...Hero36MediumMedium,
      [mediaMinTablet640]: {
        ...Hero48LargeMedium,
      },
      [mediaMinTablet768]: {
        ...Hero60XLargeMedium,
      },
    },
    "& a": {
      cursor: "pointer",
    },
  },
  headerTxt: {
    ...Body18Medium,
    lineHeight: "1.628rem",
    flex: "2",
    position: "relative",
    zIndex: "2",
    "& a": {
      color: "#FFF",
      textDecoration: "underline",
      transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    },
    "& a:hover": {
      color: Red_base,
      transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    },
  },
  headerImage: {
    flex: "1",
    display: "none",
    "@media (min-width: 990px)": {
      display: "block",
    },
  },
  headerImage2: {
    "@media (min-width: 650px)": {
      display: "block !important",
    },
  },
  headerWrap: {
    position: "relative",
  },
  headerIcons: {
    marginTop: `${GRID_GAP * 2}px`,
    display: "flex",
    gap: "0px 8px",
    "@media (min-width: 650px)": {
      marginTop: `${GRID_GAP * 3}px`,
    },
    "@media (min-width: 880px)": {
      marginTop: `${GRID_GAP * 4}px`,
    },
    "& a": {
      display: "block",
      width: "48px",
    },
  },
  headerShape: {
    //backgroundColor: 'rgba(237,28,36,0.6)',
    position: "absolute",
    left: "0px",
    bottom: "0px",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    "@media (min-width: 460px)": {
      height: "100%",
    },
    "@media (min-width: 2440px)": {
      height: "70%",
    },
  },
  headerShapeInner: {
    backgroundColor: GreyCool_10,
    position: "absolute",
    transformOrigin: "left top",
    height: "150%",
    width: "140%",
    left: "0px",
    bottom: "-150%",
    boxShadow: "-11px -11px 54px -18px rgba(0,0,0,0.46)",
  },
  carouselArticle: {
    marginBottom: `${GRID_GAP * 8}px`,
    [mediaMinTablet640]: {
      marginBottom: `${GRID_GAP * 12}px`,
    },
    [mediaMinTablet768]: {
      marginBottom: `${GRID_GAP * 15}px`,
    },
  },
  carouselHeader: {
    textAlign: "center",
    marginBottom: `${GRID_GAP * 2}px`,
    [mediaMinTablet640]: {
      marginBottom: `${GRID_GAP * 4}px`,
    },
    "& h2": {
      ...Hero36MediumMedium,
      textAlign: "center",
      margin: `${GRID_GAP * 3}px 0px ${GRID_GAP * 2}px 0px`,
      [mediaMinTablet640]: {
        margin: `${GRID_GAP * 2}px 0px ${GRID_GAP * 2}px  0px`,
      },
    },
    "& img": {
      width: "96px !important",
      height: "96px  !important",
      marginLeft: "auto",
      marginRight: "auto",
      [mediaMinTablet640]: {
        width: "128px",
        height: "128px",
      },
    },
    "& p": {
      maxWidth: "728px !important",
      display: "block",
      color: Grey_80,
      margin: `0px auto ${GRID_GAP * 3}px auto`,
      [mediaMinTablet640]: {
        margin: `0px auto ${GRID_GAP * 3}px auto`,
      },
    },
  },
  carouselIvysilani: {
    marginTop: "-110px",
    "@media (min-width: 460px)": {
      marginTop: "-125px",
    },
    "@media (min-width: 640px)": {
      marginTop: "-14vw",
    },
    "@media (min-width: 768px)": {
      marginTop: "-5vw",
    },
    "@media (min-width: 2440px)": {
      marginTop: "-135px",
    },
    "& h2": {
      color: Blue_base,
    },
  },
  carouselCt24: {
    "& h2": {
      background: "-webkit-linear-gradient(rgba(89, 203, 232, 1), rgba(0, 51, 160, 1))",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
    },
  },
  carouselCtsport: {
    "& h2": {
      color: "#7EBC00",
    },
  },
  carouselCtd: {
    "& h2": {
      color: Blue_base,
    },
  },
  carousel: {
    maxWidth: "1136px",
    ...unifiedCarousel,
  },
  carouselSmall: {
    maxWidth: "896px",
    ...unifiedCarousel,
  },
  carrouselApp: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: `${GRID_GAP * 4}px`,
    gap: `${GRID_GAP * 2}px`,
    "& img": {
      width: "auto !important",
      height: "40px !important",
      [mediaMinMobile360]: {
        width: "auto !important",
        height: "48px !important",
      },
    },
  },
  appGrid: {
    listStyleType: "none",
    padding: "0",
    margin: "0",
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: `${GRID_GAP * 2}px`,
    [mediaMinTablet640]: {
      gridTemplateColumns: "1fr 1fr",
    },
    "@media (min-width: 960px)": {
      gridTemplateColumns: "1fr 1fr  1fr",
    },
  },
  appGridItem: {
    border: `1px solid ${Grey_20}`,
    borderRadius: `12px`,
    padding: `${GRID_GAP * 2}px`,
    "& a": {
      textDecoration: "none",
      cursor: "pointer",
      color: "#000",
      display: "flex",
      alignItems: `self-start`,
    },
    "& a > div": {
      width: `calc(100% - 64px)`,
      marginLeft: `${GRID_GAP * 2}px`,
      ...Body16Small,
    },
    "& h3": {
      margin: `${GRID_GAP * 1}px 0px ${GRID_GAP * 1}px 0px`,
      ...Body18Medium,
    },
    "& span": {
      width: `64px`,
      display: `block`,
    },
  },
  iconWrap: {
    width: `96px`,
    margin: `0 auto`,
  },
});

export const ApplicationsMobilePage: React.FC = () => {
  const classes = useStyles();
  const trackedElementRef = useRef<HTMLDivElement>(null);
  const trackedElementWidth = useWidth(trackedElementRef);
  const trackedElementHeight = useHeight(trackedElementRef);
  const [angle, setAngle] = useState<null | number>(null);

  // reakce na zmenu velikosti trackovaneho prvku
  useEffect(() => {
    let a = (Math.atan2(trackedElementHeight, trackedElementWidth) * 180) / Math.PI;
    if (trackedElementWidth < 2440) a = 9.152;
    setAngle(a);
  }, [trackedElementWidth, trackedElementHeight]);

  return (
    <PageContainer
      title="Mobilní telefony a tablety"
      description="Sledujte pořady ČT v aplikaci iVysílání, nejnovější zprávy a sport v aplikacích ČT24 a ČT Sport. A na nejmenší čeká na mobilu i jejich oblíbené Déčko."
    >
      <ApplicationsLayout>
        <>
          <div className={classNames(classes.headerWrap)}>
            <Content>
              <section className={classNames(classes.header)}>
                <div className={classNames(classes.headerTxt)}>
                  <h1>Mobilní telefony a tablety</h1>
                  <p>
                    Sledujte pohodlně pořady ČT v aplikaci <Link href="#ivysilani">iVysílání</Link>,
                    procházejte nejnovější zprávy v aplikaci <Link href="#ct24">ČT24</Link>{" "}
                    a nenechte si ujít žádné sportovní utkání díky aplikaci{" "}
                    <Link href="#ct-sport">ČT Sport</Link>. A na nejmenší uživatele tu čeká hned v 
                    <Link href="#decko">několika aplikacích obsah z jejich oblíbeného Déčka</Link>.
                  </p>
                  <div className={classNames(classes.headerIcons)}>
                    <Link href="#ivysilani">
                      <SmartImage2 src={imageIconCT.src} alt="ČT" width={48} height={48} />
                    </Link>
                    <Link href="#ct24">
                      <SmartImage2 src={imageIconCT24.src} alt="ČT 24" width={48} height={48} />
                    </Link>
                    <Link href="#ct-sport">
                      <SmartImage2
                        src={imageIconCTSport.src}
                        alt="ČT sport"
                        width={48}
                        height={48}
                      />
                    </Link>
                    <Link href="#decko">
                      <SmartImage2 src={imageIconCTD.src} alt="ČT D:" width={48} height={48} />
                    </Link>
                  </div>
                </div>
                <div className={classNames(classes.headerImage)}>
                  <SmartImage2
                    src={imageTopMobile1.src}
                    alt="Mobilní telefon"
                    width={284}
                    height={535}
                  />
                </div>
                <div className={classNames(classes.headerImage, classes.headerImage2)}>
                  <SmartImage2
                    src={imageTopMobile2.src}
                    alt="Mobilní telefon"
                    width={284}
                    height={535}
                  />
                </div>
              </section>
            </Content>
            <div ref={trackedElementRef} className={classNames(classes.headerShape)}>
              <div
                className={classNames(classes.headerShapeInner)}
                style={{ transform: `rotate(-${angle}deg)` }}
              ></div>
            </div>
          </div>
          <main>
            <article
              id="ivysilani"
              className={classNames(classes.carouselArticle, classes.carouselIvysilani)}
            >
              <Content>
                <header className={classNames(classes.carouselHeader)}>
                  <div className={classNames(classes.iconWrap)}>
                    <SmartImage2 src={imageIconCT.src} alt="iVysilani" width={96} height={96} />
                  </div>
                  <h2>iVysílání</h2>
                  <p>
                    Líbilo by se vám mít všechny pořady České televize přímo ve vašem mobilu nebo
                    tabletu? Pokud ano, tak se vám jistě bude líbit aplikace iVysílání, ve které
                    můžete sledovat jak živé vysílání všech kanálů ČT, tak pořady z našeho archivu.
                    <br />
                    <br />
                    A jestli máte zrovna plné ruce práce, pustit si je může i jako audio stopu.
                  </p>
                </header>
              </Content>
              <div className={classNames(classes.carousel)}>
                <Swiper
                  modules={[A11y]}
                  spaceBetween={GRID_GAP * 2}
                  slidesPerView={5}
                  breakpoints={{
                    240: {
                      slidesPerView: 1.5,
                    },
                    380: {
                      slidesPerView: 2.5,
                    },
                    640: {
                      slidesPerView: 3.5,
                    },
                    880: {
                      slidesPerView: 5,
                    },
                  }}
                >
                  <SwiperSlide key={0}>
                    <SmartImage2
                      width={214}
                      height={434}
                      src={ivysilani1.src}
                      alt="Mobilní aplikace iVysílání - bez registrace"
                    />
                    <p className="SwiperSlideP">Všechny pořady dostupné i bez registrace</p>
                  </SwiperSlide>
                  <SwiperSlide key={1}>
                    <SmartImage2
                      width={214}
                      height={434}
                      src={ivysilani2.src}
                      alt="Mobilní aplikace iVysílání - přehledné kategorie"
                    />
                    <p className="SwiperSlideP">Přehledné kategorie</p>
                  </SwiperSlide>
                  <SwiperSlide key={2}>
                    <SmartImage2
                      width={214}
                      height={434}
                      src={ivysilani3.src}
                      alt="Mobilní aplikace iVysílání - Nové díly oblíbených pořadů"
                    />
                    <p className="SwiperSlideP">Nové díly oblíbených pořadů</p>
                  </SwiperSlide>
                  <SwiperSlide key={3}>
                    <SmartImage2
                      width={214}
                      height={434}
                      src={ivysilani4.src}
                      alt="Mobilní aplikace iVysílání - Snadné vyhledávání"
                    />
                    <p className="SwiperSlideP">Snadné vyhledávání</p>
                  </SwiperSlide>
                  <SwiperSlide key={4}>
                    <SmartImage2
                      width={214}
                      height={434}
                      src={ivysilani5.src}
                      alt="Mobilní aplikace iVysílání - Živé vysílání"
                    />
                    <p className="SwiperSlideP">Živé vysílání</p>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classNames(classes.carrouselApp)}>
                <a
                  href="https://play.google.com/store/apps/details?id=cz.motion.ivysilani&hl=cs&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmartImage2 src={bedgeG.src} alt="Google App" width={159} height={48} />
                </a>
                <a
                  href="https://apps.apple.com/cz/app/ivys%C3%ADl%C3%A1n%C3%AD/id436780264"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmartImage2 src={bedgeA.src} alt="Apple Store" width={147} height={48} />
                </a>
              </div>
            </article>
            <article
              id="ct24"
              className={classNames(classes.carouselArticle, classes.carouselCt24)}
            >
              <Content>
                <header className={classNames(classes.carouselHeader)}>
                  <div className={classNames(classes.iconWrap)}>
                    <SmartImage2 src={imageIconCT24.src} alt="ČT24" width={96} height={96} />
                  </div>
                  <h2>ČT24</h2>
                  <p>
                    Aplikace ČT24, to je zpravodajství ve vaší kapse. Prostřednictvím
                    nejdůvěryhodnějšího zpravodajského webu v Česku se k vám dostanou nejnovější
                    zprávy z domova i ze světa, všechno přehledně v jediné aplikaci.{" "}
                  </p>
                </header>
              </Content>
              <div className={classNames(classes.carouselSmall)}>
                <Swiper
                  modules={[A11y]}
                  spaceBetween={GRID_GAP * 2}
                  slidesPerView={4}
                  breakpoints={{
                    240: {
                      slidesPerView: 1.5,
                    },
                    380: {
                      slidesPerView: 2.5,
                    },
                    640: {
                      slidesPerView: 3.5,
                    },
                    880: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  <SwiperSlide key={0}>
                    <SmartImage2
                      width={212}
                      height={429}
                      src={ct241.src}
                      alt="ČT24 aplikace - Rychlý přehled zpráv"
                    />
                    <p className="SwiperSlideP">Rychlý přehled zpráv</p>
                  </SwiperSlide>
                  <SwiperSlide key={1}>
                    <SmartImage2
                      width={212}
                      height={429}
                      src={ct242.src}
                      alt="ČT24 aplikace - Živé video i audio vysílání"
                    />
                    <p className="SwiperSlideP">Živé video i audio vysílání</p>
                  </SwiperSlide>
                  <SwiperSlide key={2}>
                    <SmartImage2
                      width={212}
                      height={429}
                      src={ct243.src}
                      alt="ČT24 aplikace - Podrobné zprávy z domova i ze světa"
                    />
                    <p className="SwiperSlideP">Podrobné zprávy z domova i ze světa</p>
                  </SwiperSlide>
                  <SwiperSlide key={3}>
                    <SmartImage2
                      width={212}
                      height={429}
                      src={ct244.src}
                      alt="ČT24 aplikace - Možnost uložit články na později "
                    />
                    <p className="SwiperSlideP">Možnost uložit články na později </p>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classNames(classes.carrouselApp)}>
                <a
                  href="https://play.google.com/store/apps/details?id=eu.inmite.prj.ct.ct24.android&hl=cs&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmartImage2 src={bedgeG.src} alt="Google App" width={159} height={48} />
                </a>
                <a
                  href="https://apps.apple.com/cz/app/%C4%8Dt24/id353233740"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmartImage2 src={bedgeA.src} alt="Apple Store" width={147} height={48} />
                </a>
              </div>
            </article>
            <article
              id="ct-sport"
              className={classNames(classes.carouselArticle, classes.carouselCtsport)}
            >
              <Content>
                <header className={classNames(classes.carouselHeader)}>
                  <div className={classNames(classes.iconWrap)}>
                    <SmartImage2 src={imageIconCTSport.src} alt="ČT SPORT" width={96} height={96} />
                  </div>
                  <h2>ČT Sport</h2>
                  <p>
                    Pro sportovní fanoušky je tu aplikace ČT sport, která nabízí aktuální přehled
                    informací ze světa sportu, živé vysílání kanálu ČT sport i exkluzivní přenosy
                    dostupné jen na internetu. Součástí aplikace jsou i podcasty, ve kterých naši
                    sportovní redaktoři spolu s osobnostmi sportovní žurnalistiky glosují aktuální
                    dění.
                  </p>
                </header>
              </Content>
              <div className={classNames(classes.carouselSmall)}>
                <Swiper
                  modules={[A11y]}
                  spaceBetween={GRID_GAP * 2}
                  slidesPerView={4}
                  breakpoints={{
                    240: {
                      slidesPerView: 1.5,
                    },
                    380: {
                      slidesPerView: 2.5,
                    },
                    640: {
                      slidesPerView: 3.5,
                    },
                    880: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  <SwiperSlide key={0}>
                    <SmartImage2
                      width={212}
                      height={429}
                      src={ctsport1.src}
                      alt="ČT sport aplikace - Aktuální sportovní zpravodajství"
                    />
                    <p className="SwiperSlideP">Aktuální sportovní zpravodajství</p>
                  </SwiperSlide>
                  <SwiperSlide key={1}>
                    <SmartImage2
                      width={212}
                      height={429}
                      src={ctsport2.src}
                      alt="ČT sport aplikace - Živé vysílání ČT sport i speciálních kanálů"
                    />
                    <p className="SwiperSlideP">Živé vysílání ČT sport i speciálních kanálů</p>
                  </SwiperSlide>
                  <SwiperSlide key={2}>
                    <SmartImage2
                      width={212}
                      height={429}
                      src={ctsport3.src}
                      alt="ČT sport aplikace - Pravidelné podcasty"
                    />
                    <p className="SwiperSlideP">Pravidelné podcasty</p>
                  </SwiperSlide>
                  <SwiperSlide key={3}>
                    <SmartImage2
                      width={212}
                      height={429}
                      src={ctsport4.src}
                      alt="ČT sport aplikace - Program sportovních přenosů s možností posunu v čase"
                    />
                    <p className="SwiperSlideP">
                      Program sportovních přenosů s možností posunu v čase
                    </p>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={classNames(classes.carrouselApp)}>
                <a
                  href="https://play.google.com/store/apps/details?id=eu.inmite.prj.ct.ct4.android&hl=cs&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmartImage2 src={bedgeG.src} alt="Google App" width={159} height={48} />
                </a>
                <a
                  href="https://apps.apple.com/cz/app/čt-sport/id522546876"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmartImage2 src={bedgeA.src} alt="Apple Store" width={147} height={48} />
                </a>
              </div>
            </article>
            <article
              id="decko"
              className={classNames(classes.carouselArticle, classes.carouselCtd)}
            >
              <Content>
                <header className={classNames(classes.carouselHeader)}>
                  <div className={classNames(classes.iconWrap)}>
                    <SmartImage2
                      src={imageIconCTD.src}
                      alt="Aplikace a hry ČT :D"
                      width={96}
                      height={96}
                    />
                  </div>
                  <h2>Aplikace a hry ČT :D</h2>
                  <p>
                    Česká televize myslí i na ty nejmenší. Pro děti jsme připravili online hry,
                    zábavné i vzdělávací pořady a videa, kvízy a nejrůznější soutěže. Školáci i
                    předškoláci si tu jistě přijdou na své.{" "}
                  </p>
                </header>
                <ul className={classNames(classes.appGrid)}>
                  <li className={classNames(classes.appGridItem)}>
                    <a
                      href="https://play.google.com/store/apps/developer?id=Česká+televize&hl=cs&gl=US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <SmartImage2
                          src={decko1.src}
                          alt="Aplikace Léto s Déčkem"
                          width={64}
                          height={64}
                        />
                      </span>
                      <div>
                        <h3>Léto s Déčkem</h3>
                        Pomáháte během prázdnin opravovat poruchu na Déčku? Mapu máte vždycky po
                        ruce a získaná hesla si můžete ukládat rovnou během výletu.
                      </div>
                    </a>
                  </li>
                  <li className={classNames(classes.appGridItem)}>
                    <a
                      href="https://play.google.com/store/apps/developer?id=Česká+televize&hl=cs&gl=US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <SmartImage2
                          src={decko2.src}
                          alt="Aplikace Medvědí kuchařka"
                          width={64}
                          height={64}
                        />
                      </span>
                      <div>
                        <h3>Medvědí kuchařka</h3>
                        Miška a Nedvěd mají jednu věc určitě společnou - nehynoucí lásku k jídlu.
                        Rádi vaří, ale ještě radši ochutnávají.
                      </div>
                    </a>
                  </li>
                  <li className={classNames(classes.appGridItem)}>
                    <a
                      href="https://play.google.com/store/apps/developer?id=Česká+televize&hl=cs&gl=US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <SmartImage2
                          src={decko3.src}
                          alt="Aplikace Za devatero horami"
                          width={64}
                          height={64}
                        />
                      </span>
                      <div>
                        <h3>Za devatero horami</h3>
                        Ovečky se ztratily! Pomůžete ovečce Cestovatelce najít cestu zpět do Déčka?
                      </div>
                    </a>
                  </li>
                  <li className={classNames(classes.appGridItem)}>
                    <a
                      href="https://play.google.com/store/apps/developer?id=Česká+televize&hl=cs&gl=US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <SmartImage2
                          src={decko4.src}
                          alt="Aplikace SuperZet"
                          width={64}
                          height={64}
                        />
                      </span>
                      <div>
                        <h3>SuperZet</h3>
                        Déčko potřebuje superhrdinu! A tím je Zet – super zajíc. Možná je malý,
                        možná si nevěří, ale jen on – s fukarem v tlapce – dokáže najít všechny
                        listy a zastavit poblázněné počasí.
                      </div>
                    </a>
                  </li>
                  <li className={classNames(classes.appGridItem)}>
                    <a
                      href="https://play.google.com/store/apps/developer?id=Česká+televize&hl=cs&gl=US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <SmartImage2
                          src={decko5.src}
                          alt="Aplikace Mendel: Navzdory vytrvat"
                          width={64}
                          height={64}
                        />
                      </span>
                      <div>
                        <h3>Mendel: Navzdory vytrvat</h3>
                        Pomoz v zábavné adventuře Gregoru Johannu Mendelovi odhalit zákonitosti,
                        díky nimž se navždy nesmazatelně zapíše do světových dějin!
                      </div>
                    </a>
                  </li>
                  <li className={classNames(classes.appGridItem)}>
                    <a
                      href="https://play.google.com/store/apps/developer?id=Česká+televize&hl=cs&gl=US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <SmartImage2
                          src={decko6.src}
                          alt="Aplikace Koumej kolem dokola"
                          width={64}
                          height={64}
                        />
                      </span>
                      <div>
                        <h3>Koumej kolem dokola</h3>
                        Členové Koumanda – Tomáš, Anička a Julie – ti přichystali úkol. Do téměř
                        identických panoramat schovali 5 rozdílů. Dokážeš je najít všechny?
                      </div>
                    </a>
                  </li>
                </ul>
              </Content>
            </article>
            <OtherPagesSwitch linksTo={["toSmartTv", "toHbbTv"]} />
          </main>
        </>
      </ApplicationsLayout>
    </PageContainer>
  );
};

import React from "react";
import classNames from "classnames";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { LinkListBlock } from "@components/blocks/StructuredContentBlock/sublocks/LinkListBlock/LinkListBlock";
import { ContainerBlock } from "@components/blocks/StructuredContentBlock/sublocks/ContainerBlock/ContainerBlock";
import { LinkContainerBlock } from "@components/blocks/StructuredContentBlock/sublocks/LinkContainerBlock/LinkContainerBlock";
import { VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";
import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import { RADIUS } from "@consts/roundCorners";
import { EventBlock } from "@components/blocks/EventBlock/EventBlock";
import { FaqWidgetBlock } from "@components/blocks/StructuredContentBlock/sublocks/FaqWidgetBlock/FaqWidgetBlock";
import { HeadlineWithIconBlock } from "@components/blocks/StructuredContentBlock/sublocks/HeadlineWithIconBlock/HeadlineWithIconBlock";
import { DefinitionsListBlock } from "@components/blocks/StructuredContentBlock/sublocks/DefinitionsListBlock/DefinitionsListBlock";
import { TableBlock } from "@components/blocks/StructuredContentBlock/sublocks/TableBlock/TableBlock";
import { SuperTableBlock } from "@components/blocks/StructuredContentBlock/sublocks/SuperTableBlock/SuperTableBlock";
import { StaticContentBlock } from "@components/blocks/StructuredContentBlock/sublocks/StaticContentBlock/StaticContentBlock";
import { TestimonialBlock } from "@components/blocks/TestimonialBlock/TestimonialBlock";
import { GalleryBlock } from "@components/blocks/GalleryBlock/GalleryBlock";
import { MediaCardBlock } from "@components/blocks/MediaCardBlock/MediaCardBlock";
import { AnchorBlock } from "@components/blocks/AnchorBlock/AnchorBlock";
import { SocialLinksBlock } from "@components/blocks/SocialLinksBlock/SocialLinksBlock";
import { SingleLink } from "@components/blocks/StructuredContentBlock/sublocks/SingleLink/SingleLink";
import { UnorderedListBlock } from "@components/blocks/StructuredContentBlock/sublocks/UnorderedListBlock/UnorderedListBlock";
import { OrderedListBlock } from "@components/blocks/StructuredContentBlock/sublocks/OrderedListBlock/OrderedListBlock";
import { ShortBlogList } from "@components/blocks/ShortBlogList/ShortBlogList";
import { HorizontalSpaceBlok } from "@components/blocks/HorizontalSpaceBlok/HorizontalSpaceBlok";
import { PersonsBlock } from "@components/blocks/StructuredContentBlock/sublocks/PersonsBlock/PersonsBlock";
import { asSuperTable } from "@utils/apiDataTrasform";
import { contentBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock.css";


export const getSubblockComponent = (subblock: any): JSX.Element => {
  switch (subblock.type) {
    case "headline":
    case "paragraph":
      return <HTMLBlock key={subblock.id} HTML={subblock.value} />;
    case "headlineWithIcon":
      return <HeadlineWithIconBlock key={subblock.id} value={subblock.value} />;
    case "linkList":
    case "childrenLinksList":
      return <LinkListBlock key={subblock.id} value={subblock.value} />;
    case "linkListStyled":
      return (
        <LinkListBlock
          key={subblock.id}
          value={subblock.value.links}
          linkListStyle={subblock.value.style}
        />
      );
    case "definitionsList":
      return (
        <DefinitionsListBlock
          key={subblock.id}
          value={subblock.value.items}
          linkListStyle={subblock.value.style}
        />
      );
    case "container":
      return <ContainerBlock key={subblock.id} container={subblock.value} />;
    case "linkContainer":
      return <LinkContainerBlock key={subblock.id} container={subblock.value} />;
    case "video":
      return (
        <VideoBlock
          key={subblock.id}
          videoID={subblock.value.id}
          videoIdType={subblock.value.videoIdType}
          title={subblock.value.title}
          subtitle={subblock.value.subtitle}
          previewImageUrl={subblock.value.thumbnail?.downloadUrl}
        />
      );
    case "horizontalSpace":
      return <HorizontalSpaceBlok key={subblock.id} multiplier={subblock.value.multiplier} />;
    case "event":
      return <EventBlock key={subblock.id} value={subblock.value} />;
    case "table":
      return <TableBlock key={subblock.id} value={subblock.value} />;
    case "superTable":
      return <SuperTableBlock key={subblock.id} {...asSuperTable(subblock.value)} />;
    case "faqs":
      return <FaqWidgetBlock key={subblock.id} value={subblock.value} />;
    case "imageWithCrop":
      return (
        <ImageBlock
          key={subblock.id}
          radius={RADIUS.BASE}
          image={{
            downloadUrl: subblock.value.image.downloadUrl,
            width: subblock.value.image.width,
            height: subblock.value.image.height,
            title: subblock.value.image.title,
          }}
          cropAspectRatio={subblock.value.crop}
          title={subblock.value.title}
          subtitle={subblock.value.subtitle}
        />
      );
    case "staticContentBlock":
      return <StaticContentBlock key={subblock.id} {...subblock.value} />;
    case "testimonialBlock":
      return <TestimonialBlock key={subblock.id} value={subblock.value} />;
    case "gallery":
      return (
        <GalleryBlock
          key={subblock.id}
          items={subblock.value.items}
          showMasterImage={subblock.value.showMasterImage}
        />
      );
    case "mediaCard":
      return <MediaCardBlock key={subblock.id} item={subblock.value} />;
    case "anchor":
      return <AnchorBlock key={subblock.id} id={subblock.value.id} />;
    case "socialLinks":
      return <SocialLinksBlock key={subblock.id} value={subblock.value} />;
    case "link":
      return <SingleLink key={subblock.id} value={subblock.value} />;
    case "unorderedList":
      return (
        <UnorderedListBlock
          key={subblock.id}
          markup={subblock.value.markup}
          listStyle={subblock.value.listStyle}
        />
      );
    case "orderedList":
      return (
        <OrderedListBlock
          key={subblock.id}
          markup={subblock.value.markup}
          listStyle={subblock.value.listStyle}
        />
      );
    case "shortBlogList":
      return <ShortBlogList key={subblock.id} value={subblock.value} />;
    case "personsBlock":
      return <PersonsBlock key={subblock.id} value={subblock.value} />;
    default:
      console.log("UNKNOWN SUBBLOCK", subblock);
      return (
        <p style={{ color: "red", fontWeight: "bold", fontSize: "28px" }}>
          UNKNOWN SUBBLOCK: {subblock.type}
        </p>
      );
  }
};

export const StructuredContenBlock = ({ content }: { content: any }) => {

  return (
    <div>
      {content.map((subblock: any) => (
        <div key={subblock.id} className={classNames(contentBlock, subblock.type)}>
          {getSubblockComponent(subblock)}
        </div>
      ))}
    </div>
  );
};

import "../../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcMy7Do4iZu6y6tO0qYd9t0HJpbSmuoatNJB%2FpF%2F8SG2oVuvd%2FDnA8Bo9AurZXdb1zrAeL3vps24Xm2%2FdevR92a%2BX9Sdgm292o62TdfefHplgUOLcbNsP68Xs7dNaGjyZVcv2unbBibAj58Mj6dd06zev5QdGPz%2FPm5Af21m7X75Nu8DdPNJ%2FbHyCtBrBeScArx6TRU9pRX%2FDL%2BW%2FVc2jWvIVJExhaJSGS%2BI%2BoUX9CajM%2BkJVyjSXmFFApn2iHEKSlSAVSGjmfUQkldYlhmlJhLeclqMAixJOrqJV7YspVGBySRvngIzCoiw34bYbcIoypVGYm3C2MdR2GawuwCvD36%2FW4BFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmXXPeK%2BAFFhJbJIXBaffIXEfYyLOrOFru4yjLjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabmDzGAQsHxBZaZlD9eAh1eJOvYGTqTiKcesspo5WUHPEcnx6pRJnjUZwQ5Kp9hME0LIKsglx6k9HQ4z2kTnEklZiRtgrOpeVntvA05l142fz1vQ86nZjg%2Fs0wBMU5GxGNG0jbk4ozkdx2XgOKQdIWCuAQU56QpFcQloElKGyfstAQ0TRCl3OaczS7kmgu5m1Qr15eJct2K81QrF8tU5LjbC7k2vZEi1arKcHc9VxSnOlW55MV5ypOE6%2BRztYoj1dCpTrl5gmmk%2BlOdTO7uqwDl5jA4XAdAL043AciK0%2FsAhsRpF8BqcboNYKXaLoCTavsAXqo9BCik2mOAUqodApRS7SlAJdWeA0%2B24THpAIhCjzAAkhAk4mOhSCYAGiFJNgBaKekCoJOSPsB0P2mno0nz3DYd%2F8d0jUpfGzFsqWAPerkdHJdp8rz4C6uv6Qr05gC4OQBtDiCjqvJ27mxnsnZ1ABndJNNuNI3t9vcfw7HEwm8LAAA%3D%22%7D"
import "../../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FwebCareer%2FPageTypes%2FUneditablePage%2Fpages%2FInternshipApplicationFormPage%2Fcomponents%2FInternshipApplicationForm.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA91W227iMBB971eMtFqpWcnIBhqIK632aX9jNSQmWE3s1DEQuuq%2Fr2IS6iSkVL3wsEgQNJ7rmTPOTGLUm9mBwt8bgBxNKhWxuuAQYxbf7tDcEvKHRbnZIDIbwA8gLLi%2Feb6ZHA2ZM0xkWWR44LDKdPxw%2F%2BJqpa3VOYeeo8eg1ikwSaRKB6eFO11h%2FJAavVUJiXWmDYdvv93HHWqTCEMMJnJbDhxoP8OpX9pIPoVvMOuWlBqZ1CFTLDhQ98%2FIhFiRFxlaUSe3zVXJga3N8NTofckBt1bDGVioF3beDbvORFVb1E%2ByN3Xw%2BrcWYSZTRaQVeckhFsoKc0rwNSTuXIS9TOyGA1suiuoilNOV7yDsOKD0u3e26KYvVSaVICc%2BFLqUVmrFwYgMrdyJWvpEpEpE1eDqeEeWx7QacpBMrC2HGT1K11pZUsonwYEZkdciF2cjZLqxHNhktrhrDpzuGnOZHfplUb%2Bqpcu84VhX7%2BCYaA2qNnun1gdpEfQlYScA3%2BidMC7MO32N5PfQ7U7hUZ0DLSr37VFCBUd5zxK%2FGgUX5FpI%2FIRE7s4NfsOjC3Sces6it4Cqgx6k0UgzaAt%2Bh8t0sjzRtsfn6RvpDJAJa4UhZYGxu1XphLKT9VnQWOQMz93eFWnGfBEOC%2FtyrkTX40o04MqZZnnaVlQWjThCcGpT1FXDd3Fm5aw%2B6Ypjo6197UXYeV%2FEL%2Fnsm%2BAhpW%2BDFXv8H1xC%2F8c04DWmAa8wDb9ykUiEMjZCKECVwG0uVVt2OKdFFbjgE39hvLwyPp1WRoDnF3PWNR9h49aZjS%2BKj32388attzQp3a5Nnl7Y6DXl1evZ%2FcmQAzuqX0JlES57qLRljSfcl%2Bz7JcwaF%2Be2OdPgMdwincXoWtqupl6YqNOAsfkcxG%2Bn1fOEH%2FV0CWZGp%2FNPw%2Fn5HzaiRlvxDAAA%22%7D"
export var formCareer = 'caoh3y1';
export var formCareerCheck = 'caoh3y6';
export var formCareerCheckAgreed = 'caoh3y7';
export var formCareerCheckAgreedLink = 'caoh3y8';
export var formCareerFileField = 'caoh3ya';
export var formCareerFileFieldLabel = 'caoh3yb';
export var formCareerFileFieldMandatory = 'caoh3yc';
export var formCareerOneC = 'caoh3y2';
export var formCareerSendGroup = 'caoh3y4';
export var formCareerSendGroupButton = 'caoh3y5';
export var formCareerTextArea = 'caoh3y9';
export var formCareerTwoC = 'caoh3y3';
export var movedUp = 'caoh3y0';
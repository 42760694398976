import { DefaultLayout } from "@components/core/Layouts/DefaultLayout/DefaultLayout";
import { RulesForComplaintsLayout } from "@components/webContacts/Layouts/RulesForComplaintsLayout/RulesForComplaintsLayout";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";

const getLayout = (page: any) => {
  switch (page?.customLayout) {
    case "RulesForComplaintsLayout":
      return RulesForComplaintsLayout;
    default:
      return DefaultLayout;
  }
};
export const BaseContentPage = ({ page, allPages }: any) => {
  const Layout = getLayout(page);

  return (
    <Layout page={page} allPages={allPages}>
      <>
        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? (
          <AttachedDocumentsBlock
            documents={page.attachedDocuments.map((item: any) => item.value)}
          />
        ) : null}
      </>
    </Layout>
  );
};

import { categories, TCategory } from "./categories";

const url = `/vse-o-ct/api/services/web-career/jobs-positions/`;

const getData = async () => {
  const result = { data: [], loaded: true };
  try {
    const response = await fetch(url);
    if (response.ok) {
      result.data = await response.json();
    } else {
      throw new Error("Error fetching data");
    }
  } catch (err) {
    //
  } finally {
    return result;
  }
};

export const getPositions = async () => {
  const data = await getData();
  return decorateWithHumanReadableLocality(data.data);
};

const decorateWithHumanReadableLocality = (positions: any[]) => {
  return positions.map((position: any) => {
    const localityHumanReadable = position.localities
      .map((locality: any) => locality.city.name)
      .join(", ");
    return { ...position, localityHumanReadable };
  });
};

export const getCategoryByUrl = (url: string | undefined) => {
  if (url === undefined) return undefined;
  return categories.find((category) => category.url === url);
};

export const getPositionCategories = (position: any) => {
  let positionCategories: TCategory[] = [];
  categories.forEach((category: TCategory) => {
    if (filterPositionsByCategory([position], category).length > 0)
      positionCategories.push(category);
  });
  return positionCategories;
};

export const filterPositionsByCategory = (positions: any[], category: TCategory | undefined) => {
  if (category === undefined) return positions;
  const filteredPositions: any[] = [];

  // podle jobsCzBranchIds
  if (category?.jobsCzBranchIds !== undefined) {
    const jobsCzBranchIds = category?.jobsCzBranchIds ?? [];
    positions.forEach((position) => {
      const branchIds = position.branches.map((branch: any) => branch.name);
      const intersection = branchIds.some((id: string) => jobsCzBranchIds.includes(id));
      if (intersection) filteredPositions.push(position);
    });
  }

  // podle lokality
  else if (category?.locality !== undefined) {
    const { property, value } = category.locality;
    positions.forEach((position) => {
      let haveLocality = false;
      position.localities.map((locality: any) => {
        if (locality[property]["name"] == value) haveLocality = true;
      });
      if (haveLocality) filteredPositions.push(position);
    });
  }

  // podle typu uvazku
  else if (category?.employmentIncludes !== undefined) {
    const categoryEmploymentIncludes = category?.employmentIncludes
      ? category.employmentIncludes
      : [];
    positions.forEach((position) => {
      const containsEmployment = position.employment.some((employment: any) =>
        categoryEmploymentIncludes.includes(employment.name)
      );
      if (containsEmployment) filteredPositions.push(position);
    });
  }

  return filteredPositions;
};

const sumPositions = (positions: any[], category: TCategory): number =>
  filterPositionsByCategory(positions, category).length;

export const getCategoriesInfo = (positions: any[]) => {
  return categories.map((category: any) => {
    return {
      ...category,
      amount: sumPositions(positions, category),
    };
  });
};

export const getNumOfCities = (positions: any[]) => {
  let cities: string[] = [];
  positions.forEach((position: any) => {
    position.localities.forEach((locality: any) => {
      if (!cities.includes(locality.city.name)) cities.push(locality.city.name);
    });
  });
  return cities.length;
};

import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import Link from "next/link";
import { SmartImage2 } from "@components/reusables/SmartImage/SmartImage2";
import { formatDateTime } from "@utils/datetime";
import { pageHtmlUrl2path } from "@utils/pages";
import classNames from "classnames";
import { RADIUS } from "@consts/roundCorners";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import emptyImage from "./images/empty.png";
import {
  articleH2,
  articleH3,
  articleItem,
  articleItemContent,
  buttonWrap,
  subTop,
  subTopItem,
  subTopItemImage,
  topLink,
  topLinkHeader,
  topLinkImage,
  topLinkTxt,
  topLinkTxtDate,
  topTags,
  topTagsLinkPrimary,
  topTagsLinkSecondary,
  topTagsList,
} from "@components/core/PageTypes/ArticleSummaryPage/ArticleSummaryPage.css";

// clanku na stranku
const ATICLES_PER_PAGE = 5;

// jmena get parametru
const CATEGORY_PARAM_SLUG = "kategorie";
const PAGE_PARAM_SLUG = "strana";

// paginator
const paginationSplit = (arr: any[], pageSize: number): any[] => {
  const paginatedArray = [];
  for (let i = 0; i < arr.length; i += pageSize) {
    // Metoda slice vytváří sub-pole od indexu 'i' do 'i + pageSize'
    paginatedArray.push(arr.slice(i, i + pageSize));
  }
  return paginatedArray;
};

// odstraneni kategorii ktere neobsahuji zadne clanky
const removeEmptyCategories = (page: any) => {
  const categories: any[] = [];
  page.categories.map((category: any) => {
    const numOfArticles = page.articles.filter(
      (article: any) => article.categoryId == category.id
    ).length;
    if (numOfArticles > 0) categories.push(category);
  });
  return categories;
};

// dekorovani clanku dalsimi informacemi
const prepareArticles = (page: any, categories: any[]) => {
  const subtopPagesIds = page.subtopPages.map((p: any) => p.value.id);
  return page.articles.map((article: any) => {
    const isTop = page.topPage?.id && page.topPage.id == article.id;
    return {
      ...article,
      category: categories.find((category: any) => category.id == article.categoryId),
      isTop,
      isSubtop: subtopPagesIds.includes(article.id),
    };
  });
};

type TFilteredArticles = {
  articles: any[];
  topPage: null | any;
  subtopPages: any[];
};

// filtrovani clanku
const filterArticles = (
  allArticles: any[],
  activeCategory: null | any = null
): TFilteredArticles => {
  const filteredArticles: TFilteredArticles = { articles: [], topPage: null, subtopPages: [] };

  // topPage
  // - zobrazuje se pouze pokud neni aktivni kategorie
  // - pokud se zobrazuje tak se vyjima z vypisu clanku
  if (!activeCategory) {
    const topPage = allArticles.find((article) => article.isTop);
    if (topPage) {
      allArticles = allArticles.filter((a: any) => a.id != topPage.id);
      filteredArticles.topPage = topPage;
    }
  }

  // subtopPages
  // - zobrazuji se pouze pokud neni aktivni kategorie
  // - pokud se zobrazuje tak se vyjima z vypisu clanku
  if (!activeCategory) {
    const subtopPages = allArticles.filter((article) => article.isSubtop).slice(0, 3);
    const subtopPagesIds = subtopPages ? subtopPages.map((p: any) => p.id) : [];
    allArticles = allArticles.filter((a: any) => !subtopPagesIds.includes(a.id));
    filteredArticles.subtopPages = subtopPages;
  }

  // odfiltrovani kategorie
  if (activeCategory) {
    allArticles = allArticles.filter((article) => article.category?.id == activeCategory.id);
  }

  // paginace
  filteredArticles.articles = paginationSplit(allArticles, ATICLES_PER_PAGE);

  return filteredArticles;
};

const createQueryString = (activeCategory: null | any = null, pageIndex: number = 0): string => {
  const queryStringArray: string[] = [];
  if (activeCategory) queryStringArray.push(`${CATEGORY_PARAM_SLUG}=${activeCategory.slug}`);
  if (pageIndex != 0) queryStringArray.push(`${PAGE_PARAM_SLUG}=${pageIndex + 1}`);
  if (queryStringArray.length == 0) return "";
  return `?${queryStringArray.join("&")}`;
};

export const ArticleSummaryPageContent = ({ page, allPages }: { page: any; allPages: any[] }) => {
  const router = useRouter();

  // filtry
  const [activeCategory, setActiveCategory] = useState<null | any>(null);
  const [pageIndex, setPageIndex] = useState<number>(0);

  const categories = removeEmptyCategories(page);
  const articles = prepareArticles(page, categories);

  // basePath
  const basePath = router.asPath.split("?")[0];

  // struktura filtrovanych clanku
  const [filteredArticles, setFilteredArticles] = useState<TFilteredArticles>(
    filterArticles(articles, activeCategory)
  );

  // reakce na zmenu querystringu
  useEffect(() => {
    const activeCategorySlug = router.query[CATEGORY_PARAM_SLUG];
    const activeCategory = categories.find((category: any) => category.slug == activeCategorySlug);
    const pageIndex = parseInt(router.query[PAGE_PARAM_SLUG] as string) - 1 || 0;
    setActiveCategory(activeCategory);
    setPageIndex(pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  // reakce na zmenu filtracnich parametru
  useEffect(() => {
    setFilteredArticles(filterArticles(articles, activeCategory));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory, pageIndex]);

  // povolene "nekonecne" strankovani
  const [infinitePaginationEnabled, setInfinitePaginationEnabled] = useState<boolean>(false);

  // handler "nekonecneho strankovani"
  const handleInfinitePagination = (e: any) => {
    e.preventDefault();
    setInfinitePaginationEnabled(true);
    setPageIndex(pageIndex + 1);
  };

  // handle na zmenu kategorie
  const handleSwitchCategory = (e: any) => {
    e.preventDefault();
    const newUrl = e.target.href;
    router.push(newUrl, undefined, { shallow: true });
  };

  return (
    <section className={classNames(topTags)}>
      {/** subnadpis */}
      {page.subtitle ? <h2 className={classNames(articleH2)}>{page.subtitle}</h2> : null}

      {/** kategorie */}
      {categories.length != 0 ? (
        <ul className={classNames(topTagsList)}>
          <li key="link0">
            <Link
              href={basePath}
              onClick={(e: any) => {
                handleSwitchCategory(e);
              }}
              className={!activeCategory ? topTagsLinkPrimary : topTagsLinkSecondary}
            >
              Vše
            </Link>
          </li>
          {categories.map((category: any) => (
            <li key={category.slug}>
              <Link
                href={`${basePath}${createQueryString(category)}`}
                onClick={(e: any) => {
                  handleSwitchCategory(e);
                }}
                className={
                  activeCategory?.id == category.id ? topTagsLinkPrimary : topTagsLinkSecondary
                }
              >
                {category.label}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}

      {/** TOP clanek */}
      {filteredArticles.topPage ? <TopArticle article={filteredArticles.topPage} /> : null}

      {/** SubTOP articles */}
      {filteredArticles.subtopPages.length > 0 ? (
        <SubtopArticles articles={filteredArticles.subtopPages} />
      ) : null}

      {/** standard articles */}
      {typeof filteredArticles.articles[pageIndex] !== "undefined" &&
      filteredArticles.articles[pageIndex].length > 0 ? (
        <Articles
          articles={filteredArticles.articles}
          pageIndex={pageIndex}
          infinitePagination={infinitePaginationEnabled}
        />
      ) : null}

      {/** pagination button */}
      {typeof filteredArticles.articles[pageIndex + 1] !== "undefined" &&
      filteredArticles.articles[pageIndex + 1].length > 0 ? (
        <div className={buttonWrap}>
          <ButtonPrimary
            anchor
            href={createQueryString(activeCategory, pageIndex + 1)}
            onClick={(e: any) => {
              handleInfinitePagination(e);
            }}
            className={topTagsLinkPrimary}
          >
            Zobrazit další zprávy
          </ButtonPrimary>
        </div>
      ) : null}
    </section>
  );
};

const TopArticle = ({ article }: { article: any }) => {
  return (
    <Link href={pageHtmlUrl2path(article.htmlUrl)}>
      <div className={topLink}>
        <ArticleImage
          article={article}
          cropAspectRatio={ASPECT_RATIO.NONE}
          className={topLinkImage}
        />
        <div className={topLinkTxt}>
          <h3 className={topLinkHeader}>{article.title}</h3>
          {article?.displayDate ? (
            <p className={topLinkTxtDate}>{formatDateTime(article.displayDate)}</p>
          ) : null}
          {article?.perex ? <HTMLBlock HTML={article.perex} /> : null}
        </div>
      </div>
    </Link>
  );
};

const SubtopArticles = ({ articles }: { articles: any[] }) => {
  return (
    <div className={subTop}>
      {articles.map((article: any) => (
        <div key={article.id} className={subTopItem}>
          <Link href={pageHtmlUrl2path(article.htmlUrl)}>
            <div>
              <ArticleImage
                article={article}
                cropAspectRatio={ASPECT_RATIO.HORIZONTAL_16_9}
                className={classNames(subTopItemImage)}
              />
              <h3>{article.title}</h3>
              {article?.displayDate ? (
                <p className={topLinkTxtDate}>{formatDateTime(article.displayDate)}</p>
              ) : null}
              {article?.perex ? <HTMLBlock HTML={article.perex} /> : null}
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

const Articles = ({
  articles,
  pageIndex,
  infinitePagination,
}: {
  articles: any[];
  pageIndex: number;
  infinitePagination: boolean;
}) => {
  const showArticles = infinitePagination
    ? articles.slice(0, pageIndex + 1).flat()
    : articles[pageIndex];

  return (
    <div>
      {showArticles.map((article: any) => (
        <div key={article.id} className={classNames(articleItem)}>
          <Link href={pageHtmlUrl2path(article.htmlUrl)}>
            <div className={articleItemContent}>
              <div>
                <ArticleImage article={article} cropAspectRatio={ASPECT_RATIO.HORIZONTAL_16_9} />
              </div>
              <div>
                <h3 className={articleH3}>{article.title}</h3>
                {article?.displayDate ? (
                  <p className={topLinkTxtDate}>{formatDateTime(article.displayDate)}</p>
                ) : null}
                {article?.perex ? <HTMLBlock HTML={article.perex} /> : null}
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

const ArticleImage = ({
  article,
  cropAspectRatio = ASPECT_RATIO.ORIGINAL,
  className,
}: {
  article: any;
  cropAspectRatio: ASPECT_RATIO;
  className?: string;
}) => {
  const image = article?.image
    ? {
        src: article.image.downloadUrl,
        width: article.image.width,
        height: article.image.height,
      }
    : {
        src: emptyImage.src,
        width: emptyImage.width,
        height: emptyImage.height,
      };
  return (
    <SmartImage2
      {...{
        radius: RADIUS.BASE,
        cropAspectRatio: cropAspectRatio,
        alt: article.title,
        ...image,
        className: className,
      }}
    />
  );
};

import { ICarouselHorizontalMenuItem } from '@components/reusables/CarouselHorizontalMenu/CarouselHorizontalMenu';
import { getCzechDate } from '@czechtv/utils'
import { DATE_FORMAT } from '@consts/consts'
import dayjs from 'dayjs';
import { THTMLString } from '../../types/index'

export const historicalMilestones2events = (historicalMilestones: any[]) => {
  let events: any[] = []
  historicalMilestones.forEach((item: any) => {
    events = [...events, ...item.value]
  })
  return events
}

export interface IEvent {
  id: string;
  date: dayjs.Dayjs;
  isMostImportant: boolean;
  description: THTMLString;
  year: string;
  image: any;
}

export const prepareEvents = (items: any[]): IEvent[] => {
  items.sort((a: any, b: any) => a.date < b.date ? 1 : -1)
  return items.map((item: any, i: number) => {
    const eventDate = getCzechDate(item.date).hour(12)
    return {
      ...item,
      id: `${item.date}-${i}`,
      date: eventDate,
      year: eventDate.format(DATE_FORMAT.YEAR),
    }
  })
}

export const createEventsCarouselHorizontalNavigationMenuItems = (events: IEvent[]): ICarouselHorizontalMenuItem[] => {
  let years: string[] = []
  events.forEach((event: IEvent) => {
    !years.includes(event.year) && years.push(event.year)
  })
  return years.map((year: string) => { return {id: year, label: year} })
}

export const filterEventsByYear = (events: IEvent[], year: string): IEvent[] => events.filter((event: IEvent) => event?.year == year)

import camelcaseKeys from "camelcase-keys";

const getBackendBaseURL = () => {
  try {
    // existuje hodnota override?
    if (typeof process !== "undefined" && process.env?.NEXT_PUBLIC_BACKEND_BASE_URL_OVERRIDE) {
      return process.env?.NEXT_PUBLIC_BACKEND_BASE_URL_OVERRIDE;
    }
    // hodnota z env
    return process.env.NEXT_PUBLIC_BACKEND_BASE_URL;
  } catch (_err) {
    return "https://admin-vse-o-ct.nm-ct.cz";
  }
};

export const BACKEND_BASE_URL = getBackendBaseURL();

export const API_BASE_URL = `${BACKEND_BASE_URL}/api/v2/`;

type TBaseWagtailEntity = "pages" | "images" | "documents";

export interface IWagtailListResponseMeta {
  total_count: number;
}

export interface IWagtailPagesResponseItemMeta {
  type: string;
  detail_url: string;
  html_url: string;
  slug: string;
  show_in_menus: boolean;
  first_published_at: string;
}

export interface IWagtailPagesResponseItem {
  id: number;
  meta: IWagtailPagesResponseItemMeta;
  title: string;
}

export interface IWagtailPagesResponse {
  meta: IWagtailListResponseMeta;
  items: IWagtailPagesResponseItem[];
}

export interface IWagtailProjectDefaultPageResponseItemMetaParent {
  id: number;
  meta: Pick<IWagtailPagesResponseItemMeta, "type" | "detail_url" | "html_url">;
  title: string;
}

export interface IWagtailProjectDefaultPageResponseItemMeta extends IWagtailPagesResponseItemMeta {
  seo_title: string;
  search_description: string;
  alias_of: null | number;
  parent: null | IWagtailProjectDefaultPageResponseItemMetaParent;
}

export interface IWagtailProjectDefaultPageResponse {
  id: number;
  meta: IWagtailProjectDefaultPageResponseItemMeta;
  title: string;
  displayed_author_full_name: null | string;
  menu_title_custom: string;
  page_image: null;
  extended_data: Record<string, any>;
}

export interface IWagtailImagesResponseItemMeta {
  type: string;
  detail_url: string;
  tags: string[];
  download_url: string;
}

export interface IWagtailImagesResponseItem {
  id: number;
  meta: IWagtailImagesResponseItemMeta;
  title: string;
}

export interface IWagtailImagesResponse {
  meta: IWagtailListResponseMeta;
  items: IWagtailImagesResponseItem[];
}

export interface IWagtailImageResponse extends IWagtailImagesResponseItem {
  width: number;
  height: number;
}

export const createAPIUrl = (
  wagtailEntity: TBaseWagtailEntity,
  id: number | null = null
): string => {
  const url = `${API_BASE_URL}${wagtailEntity}${!!id ? `/${id}` : ""}/${
    !id ? "?limit=1000000" : ""
  }`;
  console.log("backend query", wagtailEntity, id, url);
  return url;
};

export const getAPIData = async (wagtailEntity: TBaseWagtailEntity, id: number | null = null) => {
  const url = createAPIUrl(wagtailEntity, id);
  try {
    const res = await fetch(url);
    const data = await res.json();
    return camelcaseKeys(data, { deep: true });
  } catch (err) {
    console.log(`Fetch url error: ${url}`);
    console.log(err);
  }
};

import { useState } from "react";
import classNames from "classnames";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import Link from "next/link";
import {
  ValidatedTextInput,
  ValidatedTextArea,
  ValidatedCheckbox,
  ValidatedSelect,
  useForm,
  Form,
} from "@czechtv/form";
import { MessageSuccess, MessageFailed } from "@czechtv/components";
import { sendForm } from "@utils/forms";
import { FileInput, MB } from "@components/reusables/Form/inputs/FileInput/FileInput";
import { intershipType } from "./../utils";
import {
  formCareer,
  formCareerCheck,
  formCareerCheckAgreedLink,
  formCareerFileField,
  formCareerFileFieldLabel,
  formCareerFileFieldMandatory,
  formCareerSendGroup,
  formCareerSendGroupButton,
  formCareerTextArea,
  formCareerCheckAgreed,
  formCareerTwoC,
  movedUp
} from "@components/webCareer/PageTypes/UneditablePage/pages/InternshipApplicationFormPage/components/InternshipApplicationForm.css";

interface FormValues {
  firstname: string;
  surname: string;
  intershipType: string;
  departmentType: string;
  location: string;
  phone: string;
  email: string;
  coverLetter: string;
  gdpr: string;
  attachement: null | File[];
}

// ----- definice stáže start
const intershipTypeOptions = intershipType.map((item: string) => {
  return { id: item, text: item };
});

// placeholder pro select typ stáže
// (musi byt ve variable protoze je potreba v selectu validovat ze hodnota != placeholder)
const intershipTypePlaceholder = "Vyberte prosím typ stáže";
// ----- definice stáže konec

// ----- definice oboru start
const departmentType = [
  "Technika",
  "Výroba",
  "Zpravodajství",
  "Administrativa",
  "Digitální média a marketing",
  "Informační technologie",
  "Jiný",
];
const departmentTypeOptions = departmentType.map((item: string) => {
  return { id: item, text: item };
});

// placeholder pro select zajmy
// (musi byt ve variable protoze je potreba v selectu validovat ze hodnota != placeholder)
const departmentTypePlaceholder = "Vyberte prosím obor";
// ----- definice oboru konec

// ----- definice location start
const location = ["Praha", "Brno", "Ostrava", "Jiná"];
const locationOptions = location.map((item: string) => {
  return { id: item, text: item };
});

// placeholder pro select zajmy
// (musi byt ve variable protoze je potreba v selectu validovat ze hodnota != placeholder)
const locationPlaceholder = "Vyberte prosím lokalitu";
// ----- definice location konec

export const InternshipApplicationForm = () => {

  const form = useForm<FormValues>();
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    setValue,
    formState: { isSubmitting, isValid },
    reset,
  } = form;

  // prasácké handlování selectu :(
  const [selectedintershipType, setSelectedintershipType] = useState("");
  const [selectedDepartmentType, setSelectedDepartmentType] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  const onFormSubmit = async (data: FormValues) => {
    const url = "/vse-o-ct/api/forms/internship-application-form";
    try {
      await sendForm(url, data);
      resetForm();
    } catch (error: any) {
      console.log(error);
      throw new Error(error.message);
    } finally {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, 10000);
    }
  };

  const resetForm = () => {
    reset();
    setSelectedintershipType("");
    setSelectedDepartmentType("");
    setSelectedLocation("");
  };

  return (
    <article
      className={classNames(formCareer, movedUp)}
      id="kontaktniFormularKariera"
    >
      <Form errorState={FormError} form={form} successState={FormSuccess} onSubmit={onFormSubmit}>
        {!disabled ? (
          <>
            <div className={classNames(formCareerTwoC)}>
              <div>
                <ValidatedSelect
                  {...register("intershipType", {
                    required: true,
                    validate: (value: string) => {
                      return (
                        (value.trim() != "" && value != intershipTypePlaceholder) ||
                        "Mám zájem o stáž"
                      );
                    },
                  })}
                  displayValidationStatus={true}
                  label={{
                    required: true,
                    title: "Mám zájem o stáž",
                  }}
                  options={intershipTypeOptions}
                  placeholder={intershipTypePlaceholder}
                  selectedOption={selectedintershipType}
                  onSelected={(text: string) => {
                    setSelectedintershipType(text);
                    setValue("intershipType", text, { shouldValidate: true });
                  }}
                  maxWidth={2000}
                />
              </div>
              <div>
                <ValidatedSelect
                  {...register("departmentType", {
                    required: true,
                    validate: (value: string) => {
                      return (
                        (value.trim() != "" && value != departmentTypePlaceholder) || "Vyberte obor"
                      );
                    },
                  })}
                  displayValidationStatus={true}
                  label={{
                    required: true,
                    title: "Vyberte obor",
                  }}
                  options={departmentTypeOptions}
                  placeholder={departmentTypePlaceholder}
                  selectedOption={selectedDepartmentType}
                  onSelected={(text: string) => {
                    setSelectedDepartmentType(text);
                    setValue("departmentType", text, { shouldValidate: true });
                  }}
                  maxWidth={2000}
                />
              </div>
            </div>
            <div className={classNames(formCareerTwoC)}>
              <div>
                <ValidatedSelect
                  {...register("location", {
                    required: true,
                    validate: (value: string) => {
                      return (
                        (value.trim() != "" && value != locationPlaceholder) || "Vyberte lokalitu"
                      );
                    },
                  })}
                  displayValidationStatus={true}
                  label={{
                    required: true,
                    title: "Vyberte lokalitu",
                  }}
                  options={locationOptions}
                  placeholder={locationPlaceholder}
                  selectedOption={selectedLocation}
                  onSelected={(text: string) => {
                    setSelectedLocation(text);
                    setValue("location", text, { shouldValidate: true });
                  }}
                  maxWidth={2000}
                />
              </div>
              <div></div>
            </div>
            <div className={classNames(formCareerTwoC)}>
              <div>
                <ValidatedTextInput
                  {...register("firstname", {
                    required: "Zadejte jméno",
                  })}
                  autoComplete="firstname"
                  label={{
                    required: true,
                    title: "Jméno",
                  }}
                  maxWidth={2000}
                  showValidationIcon
                />
              </div>
              <div>
                <ValidatedTextInput
                  {...register("surname", {
                    required: "Zadejte příjmení",
                  })}
                  autoComplete="surname"
                  label={{
                    required: true,
                    title: "Příjmení",
                  }}
                  maxWidth={2000}
                  showValidationIcon
                />
              </div>
            </div>
            <div className={classNames(formCareerTwoC)}>
              <div>
                <ValidatedTextInput
                  {...register("email", {
                    required: { value: true, message: "Zadejte e-mail" },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message:
                        "E-mail, který jste zadali, je ve špatném formátu. Zadejte platný e-mail.",
                    },
                  })}
                  autoComplete="email"
                  label={{
                    required: true,
                    title: "E-mail",
                  }}
                  maxWidth={2000}
                  showValidationIcon
                />
              </div>
              <div>
                <ValidatedTextInput
                  {...register("phone", {
                    required: { value: true, message: "Zadejte telefon" },
                    pattern: {
                      value: /^(\+420|00420)? ?(\d{3} ?){3}$/,
                      message: "Telefon který jste zadali je ve špatném formátu",
                    },
                  })}
                  autoComplete="phone"
                  label={{
                    required: true,
                    title: "Telefon",
                  }}
                  type="tel"
                  maxWidth={2000}
                  showValidationIcon={true}
                />
              </div>
            </div>

            <div className={classNames(formCareerTextArea)}>
              <ValidatedTextArea
                {...register("coverLetter", {
                  required: { value: true, message: "Vyplňte motivační dopis" },
                })}
                label={{
                  required: true,
                  title: "Motivační dopis",
                }}
                maxWidth={2000}
              />
            </div>
            <div className={classNames(formCareerFileField)}>
              <label className={classNames(formCareerFileFieldLabel)} htmlFor="attachement">
                Nahrát životopis <span className={classNames(formCareerFileFieldMandatory)}>*</span>
              </label>
              <div>
                <FileInput
                  {...register("attachement", {
                    required: {
                      value: true,
                      message: "Nahrajte prosím životopis",
                    },
                  })}
                  required={true}
                  accept=".doc,.png,.jpg,.jpeg,.docx,.pdf"
                  maxSize={10 * MB}
                />
              </div>
              <p>Přijímáme všechny přílohy typu JPG, PNG, PDF do velikosti 10 Mb.</p>
            </div>
            <div className={classNames(formCareerSendGroup)}>
              <div className={classNames(formCareerCheck)}>
                <ValidatedCheckbox
                  {...register("gdpr", {
                    required: {
                      value: true,
                      message: "Pro odeslání zprávy musíte souhlasit se zpracováním osobních údajů",
                    },
                  })}
                  item={{
                    id: "gdpr",
                    title: "",
                  }}
                />{" "}
                <span className={classNames(formCareerCheckAgreed)}>
                  Souhlasím se{" "}
                  <Link className={classNames(formCareerCheckAgreedLink)} href="./gdpr/" rel="noreferrer" target="_blank">
                    zpracováním osobních údajů
                  </Link>
                </span>
              </div>
              <ButtonPrimary
                disabled={isSubmitting || !isValid || disabled}
                type="submit"
                waiting={isSubmitting}
                className={classNames(formCareerSendGroupButton)}
              >
                Odeslat
              </ButtonPrimary>
            </div>
          </>
        ) : null}
      </Form>
    </article>
  );
};

const FormError = ({ error }: { error: unknown }) => (
  <>
    <MessageFailed
      description={error instanceof Error ? error.message : "Vyskytla se neočekávaná chyba"}
    />
    <br />
  </>
);

const FormSuccess = () => (
  <>
    <MessageSuccess
      description={"Formulář byl úspěšně odeslán, budeme Vás kontaktovat na uvedeném e-mailu."}
    />
    <br />
  </>
);

import React from "react";
import {
  UneditablePageRegister,
  UneditablePageBase,
  UneditablePageProps,
} from "@components/core/PageTypes/UneditablePage/UneditablePage";
import { PhotoservicePage } from "./pages/PhotoservicePage/PhotoservicePage";

// registrovane staticke stranky
const staticPages: UneditablePageRegister = {
  PhotoservicePage: PhotoservicePage,
};

export const UneditablePage = ({ page, allPages }: UneditablePageProps) => {
  return (
    <UneditablePageBase page={page} allPages={allPages} registerdUneditablePages={staticPages} />
  );
};

import { LayoutProps } from "@components/core/Layouts";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { Header } from "@components/layouts/components/Header/Header";
import {
  regionalBroadcastingGlobalMenuConfig,
  regionalBroadcastingHomePageGlobalMenuConfig,
} from "./regionalBroadcastingGlobalMenuConfig";
import { ContactBox } from "./components/ContactBox/ContactBox";
import { Content } from "@czechtv/components";
import classNames from "classnames";

import { narrowContent } from "./RegionalBroadcastingLayout.css";

// rozsireni props layoutu o typ menu
type RegionalBroadcastingLayout = LayoutProps & {
  layoutSubtype?: "HOMEPAGE" | "STANDARD";
};

export const RegionalBroadcastingLayout: React.FC<RegionalBroadcastingLayout> = ({
  children,
  layoutSubtype = "STANDARD",
}) => {
  return (
    <div>
      <Header
        globalMenuConfig={
          layoutSubtype == "HOMEPAGE"
            ? regionalBroadcastingHomePageGlobalMenuConfig
            : regionalBroadcastingGlobalMenuConfig
        }
      />
      {layoutSubtype == "STANDARD" ? (
        <Content>
          <div className={classNames(narrowContent)}>{children ? children : null}</div>
        </Content>
      ) : (
        <>{children ? children : null}</>
      )}

      <ContactBox />
      <CTGlobalFooter />
    </div>
  );
};

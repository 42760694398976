import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcMy7Do4iZu6y6tO0qYd9t0HJpbSmuoatNJB%2FpF%2F8SG2oVuvd%2FDnA8Bo9AurZXdb1zrAeL3vps24Xm2%2FdevR92a%2BX9Sdgm292o62TdfefHplgUOLcbNsP68Xs7dNaGjyZVcv2unbBibAj58Mj6dd06zev5QdGPz%2FPm5Af21m7X75Nu8DdPNJ%2FbHyCtBrBeScArx6TRU9pRX%2FDL%2BW%2FVc2jWvIVJExhaJSGS%2BI%2BoUX9CajM%2BkJVyjSXmFFApn2iHEKSlSAVSGjmfUQkldYlhmlJhLeclqMAixJOrqJV7YspVGBySRvngIzCoiw34bYbcIoypVGYm3C2MdR2GawuwCvD36%2FW4BFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmXXPeK%2BAFFhJbJIXBaffIXEfYyLOrOFru4yjLjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabmDzGAQsHxBZaZlD9eAh1eJOvYGTqTiKcesspo5WUHPEcnx6pRJnjUZwQ5Kp9hME0LIKsglx6k9HQ4z2kTnEklZiRtgrOpeVntvA05l142fz1vQ86nZjg%2Fs0wBMU5GxGNG0jbk4ozkdx2XgOKQdIWCuAQU56QpFcQloElKGyfstAQ0TRCl3OaczS7kmgu5m1Qr15eJct2K81QrF8tU5LjbC7k2vZEi1arKcHc9VxSnOlW55MV5ypOE6%2BRztYoj1dCpTrl5gmmk%2BlOdTO7uqwDl5jA4XAdAL043AciK0%2FsAhsRpF8BqcboNYKXaLoCTavsAXqo9BCik2mOAUqodApRS7SlAJdWeA0%2B24THpAIhCjzAAkhAk4mOhSCYAGiFJNgBaKekCoJOSPsB0P2mno0nz3DYd%2F8d0jUpfGzFsqWAPerkdHJdp8rz4C6uv6Qr05gC4OQBtDiCjqvJ27mxnsnZ1ABndJNNuNI3t9vcfw7HEwm8LAAA%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fcomponents.css.ts.vanilla.css%22%2C%22source%22%3A%22W29iamVjdCBPYmplY3RdOmhvdmVyIHsKICB0cmFuc2l0aW9uOiBhbGwgdmFyKC0tXzE5bXJoYWEyNykgdmFyKC0tXzE5bXJoYWEyNik7CiAgYmFja2dyb3VuZC1wb3NpdGlvbjogcmlnaHQgMHB4IGNlbnRlcjsKfQoueDdhMHdtMCB7CiAgcGFkZGluZzogMCAwIHZhcigtLV8xOW1yaGFhMW8pIDA7CiAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFxKSAwIHZhcigtLV8xOW1yaGFhMXEpIDA7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwogIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1fMTltcmhhYTFlKTsKfQoueDdhMHdtMSB7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc2OHB4KSB7CiAgLng3YTB3bTAgewogICAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFzKSAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDEwMjRweCkgewogIC54N2Ewd20wIHsKICAgIG1hcmdpbjogMCAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FwebContacts%2FLayouts%2FRulesForComplaintsLayout%2FRulesForComplaintsContactBox%2FRulesForComplaintsContactBox.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62UzXKbMBDH734KzfQSDmKA2KZRLj31NTqytAalICkrkdjt%2BN07CNsV%2BIN0Gg7ASPvx29Vfm3JYf%2BVZRn4vCNlw8bNC02lJhWkMMvLle3ieF4RYLqXSFSNvHB8o%2FZE%2FtVhzntuk390YlIAUuVSdm9oUIthI5WzD94xsG9j1C%2F2Xkbz%2Fpf0%2FlQpBeGU0I8I0XatPVtd3Wo6V0nRjvDftBZhJnheHRToUmIcCt0Z76tQvYCRPyxVC24dplAZag6pqz0hxXAymW96qZj%2BNvAzVHDs03tonEZc3lpFsHnTo4Lm%2FWYRdXGJfpY6quQO%2B%2Bidwu%2FtYj8foJ49xHY%2BhjjuZPXLt1PmADU4lVCbTlXV8vo%2BsNm%2BAt9OUn5JmGRKM6624vdGXC8HzRlWaKg%2BtY0SA9oDzPXYjANZw56moVSMDy8Q3bvoqGFxAXOMdJHgD76VzXm33VBjtQfshEnWeoz%2Ffz3fso%2FbvKwXFUOv7SjjblXfg82KkzViuokPXR7ZGnfA97DyVIAzy4eC10fAZYig%2FqLlp%2Fk5LwP76%2FhfEtxak4sQJBNCEa0keWqXpu5K%2BZmS9zOwuCWhpPOJvz%2FHXADsnxsHq8DdscQx7vvVTD0zsjmTDVTnMcudZsZyA58cM0RQs0uI07qbjO402wHtA6iwXoWCapVnkNzvhD4vDHxaSkvwfBwAA%22%7D"
export var container = 'ae68a00';
export var containerBox = 'ae68a04';
export var containerBoxIcon = 'ae68a07';
export var containerBoxlogo = 'ae68a05';
export var containerBoxlogo_link = 'ae68a06';
export var container_h2 = 'ae68a01';
export var container_h3 = 'ae68a02';
export var container_link = 'ae68a03';
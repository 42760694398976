import React from "react";
import { WithoutSideMenuLayout } from "@components/core/Layouts/WithoutSideMenuLayout/WithoutSideMenuLayout";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { Content } from "@czechtv/components";
import classNames from "classnames";
import { DisplayStripBlock } from "@components/blocks/DisplayStripBlock/DisplayStripBlock";
import { SpeedLinkBox } from "@components/blocks/SpeedLinkBox/SpeedLinkBox";
import { historicalMilestones2events, prepareEvents } from "@components/webHistory/utils";
import { HistoricalMilestonesBlock } from "@components/blocks/HistoricalMilestonesBlock/HistoricalMilestonesBlock";
import { VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";
import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import { DirectorsStrip } from "@components/blocks/DirectorsStrip/DirectorsStrip";
import { RADIUS } from "@consts/roundCorners";

import {
  headerHP,
  headerHP_h1,
  twoColumnWrap,
  twoColumnMedia,
  twoColumnText,
  twoColumnText_h3,
  twoColumnText_h4,
  displayStripBlockWrap,
} from "./HistoryHomePage.css";

export const HistoryHomePage = ({ page, allPages }: any) => {
  const prepareDisplayStripData = (data: any) => {
    return {
      headline: data.headline,
      headlineLink: data.headlineLink.url,
      subheadline: data.subheadline,
      textHTML: data.textHtml,
      linksColumns: data.linksColumns,
      imagePosition: data.imagePosition,
      image: {
        src: data.image.downloadUrl,
        alt: data.headline,
        width: data.image.width,
        height: data.image.height,
      },
      links: data.links.map((link: any) => {
        return { label: link.title, url: link.url };
      }),
    };
  };

  //priprava event dat
  const events = prepareEvents(historicalMilestones2events(page.historicalMilestones));

  return (
    <WithoutSideMenuLayout page={page} allPages={allPages}>
      <>
        <Content>
          <div className={classNames(headerHP)}>
            <h1 className={classNames(headerHP_h1)}>{page.title}</h1>
            <HTMLBlock HTML={page.perex} />
          </div>
        </Content>

        {/** DisplayStripBlocks */}
        <Content>
          <div className={classNames(displayStripBlockWrap)}>
            {page.displayStrips.map((item: any, i: number) => (
              <DisplayStripBlock key={i} {...prepareDisplayStripData(item.value)} />
            ))}
          </div>
        </Content>
        {/** historicke milniky */}
        <Content>
          <HistoricalMilestonesBlock events={events} headline={"Historické milníky"} />
        </Content>
        {/* Box pro rychlé odkazy */}
        <SpeedLinkBox></SpeedLinkBox>

        <Content>
          {/** two columns strips */}
          <div className={classNames(twoColumnWrap)}>
            {page?.twoColumnsStrips?.length &&
              page.twoColumnsStrips.map((strip: any) => (
                <TwoColumnsStrip key={strip.id} twoColumnsStripData={strip.value} />
              ))}
          </div>
        </Content>
        {/** reditele */}
        <Content>
          {page?.directors ? <DirectorsStrip data={page.directors} displayedCount={4} /> : null}
        </Content>
      </>
    </WithoutSideMenuLayout>
  );
};

const TwoColumnsStrip = ({ twoColumnsStripData }: any) => {
  return (
    <div>
      <TwoColumnsStripMediaColumn twoColumnsStripData={twoColumnsStripData} />
      <TwoColumnsStripTextColumn twoColumnsStripData={twoColumnsStripData} />
    </div>
  );
};

const TwoColumnsStripTextColumn = ({ twoColumnsStripData }: any) => {
  return (
    <div className={classNames(twoColumnText)}>
      <div>
        <h3 className={classNames(twoColumnText_h3)}>{twoColumnsStripData.headline}</h3>
        <h4 className={classNames(twoColumnText_h4)}>{twoColumnsStripData.subheadline}</h4>
        <HTMLBlock HTML={twoColumnsStripData.text} />
      </div>
    </div>
  );
};

const TwoColumnsStripMediaColumn = ({ twoColumnsStripData }: any) => {
  const getMediumItem = () => twoColumnsStripData.medium[0];
  const getMediumType = () => getMediumItem().type;
  const getMedium = () => getMediumItem().value;

  return (
    <div className={classNames(twoColumnMedia)}>
      {/** image */}
      {getMediumType() === "image" ? (
        <ImageBlock
          image={{
            downloadUrl: getMedium().image.downloadUrl,
            width: getMedium().image.width,
            height: getMedium().image.height,
            title: getMedium().title,
          }}
          cropAspectRatio={twoColumnsStripData.crop}
          radius={RADIUS.BASE}
          title={getMedium().title}
          subtitle={getMedium().subtitle}
        />
      ) : null}

      {/** video */}
      {getMediumType() === "video" ? (
        <VideoBlock
          videoID={getMedium().id}
          videoIdType={getMedium().videoIdType}
          title={getMedium().title}
          subtitle={getMedium().subtitle}
          previewImageUrl={getMedium().thumbnail?.downloadUrl}
        />
      ) : null}
    </div>
  );
};

import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcMy7Do4iZu6y6tO0qYd9t0HJpbSmuoatNJB%2FpF%2F8SG2oVuvd%2FDnA8Bo9AurZXdb1zrAeL3vps24Xm2%2FdevR92a%2BX9Sdgm292o62TdfefHplgUOLcbNsP68Xs7dNaGjyZVcv2unbBibAj58Mj6dd06zev5QdGPz%2FPm5Af21m7X75Nu8DdPNJ%2FbHyCtBrBeScArx6TRU9pRX%2FDL%2BW%2FVc2jWvIVJExhaJSGS%2BI%2BoUX9CajM%2BkJVyjSXmFFApn2iHEKSlSAVSGjmfUQkldYlhmlJhLeclqMAixJOrqJV7YspVGBySRvngIzCoiw34bYbcIoypVGYm3C2MdR2GawuwCvD36%2FW4BFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmXXPeK%2BAFFhJbJIXBaffIXEfYyLOrOFru4yjLjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabmDzGAQsHxBZaZlD9eAh1eJOvYGTqTiKcesspo5WUHPEcnx6pRJnjUZwQ5Kp9hME0LIKsglx6k9HQ4z2kTnEklZiRtgrOpeVntvA05l142fz1vQ86nZjg%2Fs0wBMU5GxGNG0jbk4ozkdx2XgOKQdIWCuAQU56QpFcQloElKGyfstAQ0TRCl3OaczS7kmgu5m1Qr15eJct2K81QrF8tU5LjbC7k2vZEi1arKcHc9VxSnOlW55MV5ypOE6%2BRztYoj1dCpTrl5gmmk%2BlOdTO7uqwDl5jA4XAdAL043AciK0%2FsAhsRpF8BqcboNYKXaLoCTavsAXqo9BCik2mOAUqodApRS7SlAJdWeA0%2B24THpAIhCjzAAkhAk4mOhSCYAGiFJNgBaKekCoJOSPsB0P2mno0nz3DYd%2F8d0jUpfGzFsqWAPerkdHJdp8rz4C6uv6Qr05gC4OQBtDiCjqvJ27mxnsnZ1ABndJNNuNI3t9vcfw7HEwm8LAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fcomponents.css.ts.vanilla.css%22%2C%22source%22%3A%22W29iamVjdCBPYmplY3RdOmhvdmVyIHsKICB0cmFuc2l0aW9uOiBhbGwgdmFyKC0tXzE5bXJoYWEyNykgdmFyKC0tXzE5bXJoYWEyNik7CiAgYmFja2dyb3VuZC1wb3NpdGlvbjogcmlnaHQgMHB4IGNlbnRlcjsKfQoueDdhMHdtMCB7CiAgcGFkZGluZzogMCAwIHZhcigtLV8xOW1yaGFhMW8pIDA7CiAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFxKSAwIHZhcigtLV8xOW1yaGFhMXEpIDA7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwogIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1fMTltcmhhYTFlKTsKfQoueDdhMHdtMSB7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc2OHB4KSB7CiAgLng3YTB3bTAgewogICAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFzKSAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDEwMjRweCkgewogIC54N2Ewd20wIHsKICAgIG1hcmdpbjogMCAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FwebLawsAndRules%2FPageTypes%2FDocumentsPage%2FDocumentsPage.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B1XTXObMBC9%2B1dsp5cwU3kAO%2F4gl576NzIyyEYNSIy0xNCO%2F3tHAoL5sp1M2kMaHzxjade8ffv2Ic0fvTz2S9Qu%2FJ4BpFQduAjAzQqgOUp4puqOkEdvm6qYUk87dvlhdprNm0zPZmY0irg42NSHGUDCNRKNZcIIlhkLQEjBzEbEdZbQMoB9wmzkgWZB%2FznCMTsmghyV2TffZulnrpHvSxJKgUxg9S9EI1XYAeVbUHspkGj%2BiwXgzT3%2FXrG0wiYYiRk%2FxGg2mmUbvacpT8oBIIvnyCOMg5oBgJ1UEVMBeFkBWiY8AlRU6IwqJtAEhLnSUgWQSS6QqU71XFgUDQkyR%2FO7pYlkUnPkUgSgWEKRP1fLO1mYiizVFQCzZLbsw%2BuUHQ2fDkrmIuoV4q8d6C%2BtHHD1t%2BbfQplI9ZqsguiYRvJ4a47BShN%2BEIQjS3UAIWvYqSEoGvFcD3ognVEFtOnICiQRC6WiFQ0NmbamAL7%2BsB%2F4wtNMKqRVl1qu%2Bk98cipQTYX9fX9jA86lD36t%2F0Zey009DwyRKaIzGp6PidXcsYl13a6Ig1g%2BM2WlfHN339TcN%2FR2qrXTdCbX6Nw6vfJpaHT%2Fv9a%2F%2BPSwTw97IXNaV5u%2Fb1O1gY7Z40UHW3xEBxsl42oT%2BsP9Ic3tXahZDn1vPWp7%2FnXTW07DKu1WdeIlKLMXsddLO4ko04kJboepA%2F1%2BCH3CsNc3OPb9vwW%2F6l4BwB1mg9s5PJ0lr23ytLOntCD1u2ezqrG2aiLdE1oH5GgJt7js4KZh7woRVyysX0ryeMHIL141Ll1brrfgjLeN5W20x95TN3TbF5c%2FHz8O%2BK3oenZO3Lnr36C89x8bMaK87ymLOAUdKsYEUBHBXcpFIxPP9ZdZ4diie94wxcIkD1eYuIGL0zmK7RDFop3oHorlRQyr14E4%2FQGo8WU3sQ8AAA%3D%3D%22%7D"
export var componetHeader = '_1uh2yts8 x7a0wm0';
export var componetHeaderH1 = '_1uh2yts9';
export var faqCategory = '_1uh2yts4';
export var faqSelect = '_1uh2yts7';
export var faqSubCategory = '_1uh2yts5';
export var faqTopTags = '_1uh2yts0';
export var faqTopTags_ul = '_1uh2yts1';
export var faqTxtContent = '_1uh2yts6';
export var topTagsLinkPrimary = '_1uh2yts2';
export var topTagsLinkSecondary = '_1uh2yts3';
import React from 'react'
import { UneditablePageProps } from "@components/core/PageTypes/UneditablePage/UneditablePage"
import { DefaultLayout } from '@components/webCareer/Layouts/DefaultLayout/DefaultLayout'
import { CareerContactIntro } from './components/CareerContactIntro/CareerContactIntro'
import { CareerFormContact } from './components/CareerFormContact/CareerFormContact'
import imageTop from './images/bgcarrercontacts.jpg'


export const CareerContact: React.FC<UneditablePageProps> = ({ page, allPages }) => {
  const headerImage = {
    downloadUrl: imageTop.src,
    title: "",
    width: imageTop.width,
    height: imageTop.height,
  }

  return (
    <DefaultLayout page={page} allPages={allPages} headerImage={headerImage}>
      <>
        <CareerContactIntro />
        <CareerFormContact />
      </>
    </DefaultLayout>
  )
}

import { ISideMenuItem } from "./SideMenu";
import { getPageChildrens } from "@utils/pages";
import { getPageSiteSection, getSectionRootPage } from "@utils/pages";

const createMenuItem = (page: any, pages: any[], disabledSubpaths: string[] = []) => {
  const childrenPages = getPageChildrens(page, pages);
  const childrens: any = [];
  childrenPages.map((childrenPage: any) => {
    childrens.push(createMenuItem(childrenPage, pages));
  });
  return {
    label: page?.menuTitle ? page?.menuTitle : page.title,
    url: page.path,
    childrens: childrens,
    hideChildrens: disabledSubpaths.includes(page.path),
  };
};

type TgetSideMenuItemsProps = {
  pages: any[];
  rootPage: any;
  depth?: undefined | number;
  disabledSubpaths?: string[];
};

export const getRootPage = (page: any, allPages: any[]) => {
  const section = getPageSiteSection(page);
  return section?.getSideMenuRootPage
    ? section.getSideMenuRootPage(page, allPages)
    : getSectionRootPage(page, allPages);
};

export const getSideMenuItems = ({
  pages,
  rootPage,
  depth = 4,
  disabledSubpaths = [],
}: TgetSideMenuItemsProps): ISideMenuItem[] => {
  const items: ISideMenuItem[] = [];
  const childrenPages = getPageChildrens(rootPage, pages, depth);
  getPageChildrens(rootPage, childrenPages, 1).map((rootChildrenPage: any) => {
    items.push(createMenuItem(rootChildrenPage, childrenPages, disabledSubpaths));
  });
  return items;
};

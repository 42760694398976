import React from "react";
import classNames from "classnames";
import { LayoutProps } from "@components/core/Layouts";
import { Header } from "@components/layouts/components/Header/Header";
import { BreadcrumbNavigation } from "@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation";
import { Content } from "@czechtv/components";
import {
  SideMenu,
  getSideMenuItems,
  getRootPage,
} from "@components/layouts/components/SideMenu/SideMenu";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { createBreadcrumbs } from "@utils/pages";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import {
  containerMain,
  containerSideMenu,
  containerContent,
  containerHeader,
  containerHeader_H1,
  containerSideMenu_label,
} from "./WithSideMenuLayout.css";

export const WithSideMenuLayout: React.FC<LayoutProps> = ({ page, allPages, children }) => {
  const rootPage = getRootPage(page, allPages);

  const sideMenuItems = getSideMenuItems({
    pages: allPages,
    rootPage: rootPage,
  });

  return (
    <>
      <Header />
      <Content>
        {allPages ? <BreadcrumbNavigation items={createBreadcrumbs(page, allPages)} /> : null}
        <div className={classNames(containerHeader)}>
          <h1 className={classNames(containerHeader_H1)}>{page.title}</h1>
          {page.perex ? <HTMLBlock HTML={page.perex} /> : null}
        </div>
        <div className={classNames(containerMain)}>
          <div className={classNames(containerSideMenu)}>
            <p className={classNames(containerSideMenu_label)}>Vyberte oblast</p>
            {allPages ? <SideMenu items={sideMenuItems} /> : null}
          </div>
          <div className={classNames(containerContent)}>{children ? children : null}</div>
        </div>
      </Content>
      <CTGlobalFooter />
    </>
  );
};

import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { StaticContentBlockComponentProps } from "@components/blocks/StructuredContentBlock/sublocks/StaticContentBlock/StaticContentBlock";
import { Spinner } from "@components/reusables/Spinner/Spinner";

const url = "/vse-o-ct/api/services/web-technology/transmitters-lockouts/";
const dateColumnIndex = 1;

const getData = async () => {
  const result = { data: [], loaded: true };
  try {
    const response = await fetch(url);
    if (response.ok) {
      result.data = await response.json();
    }
  } catch (err) {
    //
  } finally {
    return result;
  }
};

const filterData = (data: any[]): any[] => {
  let filteredData = data;
  const now = dayjs();

  // multiplex
  filteredData = filteredData.filter((item: any) => item[5] == "Multiplex 21");

  // zobrazume jen today - 2 dny
  filteredData = filteredData.filter((item: any) => {
    const dateString = item[dateColumnIndex];
    const lockoutsDate = dayjs(
      `${dateString.substring(0, 4)}-${dateString.substring(4, 6)}-${dateString.substring(6, 8)}`
    );
    return lockoutsDate.diff(now, "day") >= -2;
  });

  return filteredData;
};

const sortData = (data: any[]): any[] => {
  return data.sort((a: any, b: any) => (a[1] > b[1] ? 1 : -1));
};

const prepareData = (data: any[]): any[] => {
  return sortData(filterData(data));
};

const formatData = (
  line: string[],
  column: "DATE" | "FROM_TIME" | "TO_TIME" | "TRANSMITTER" | "MULTIPLEX" | "CHANNEL" | "LOCATION"
): string => {
  try {
    switch (column) {
      case "DATE":
        return `${line[dateColumnIndex].substring(6, 8)}. ${line[dateColumnIndex].substring(
          4,
          6
        )}. ${line[dateColumnIndex].substring(0, 4)}`;

      case "FROM_TIME":
        const ft = line[3].split("-")[0];
        return `${ft.substring(0, 2)}:${ft.substring(2, 4)}`;

      case "TO_TIME":
        const tt = line[3].split("-")[1];
        return `${tt.substring(0, 2)}:${tt.substring(2, 4)}`;

      case "TRANSMITTER":
        // odstranuje se posledni cast textu za posledni mezerou
        return line[4].slice(0, line[4].lastIndexOf(" "));

      case "MULTIPLEX":
        return line[5];

      case "CHANNEL":
        return line[6];

      case "LOCATION":
        // spojuji se dve originalni polozky
        return `${line[7]}, ${line[8]}`;
    }
  } catch (err) {
    return "";
  }
};

export const TransmittersLockouts = ({}: StaticContentBlockComponentProps) => {
  const [data, setData] = useState<any[] | null>(null);

  useEffect(() => {
    getData().then((result: any) => {
      const dataFromApi = prepareData(result.data);
      if (JSON.stringify(data) != JSON.stringify(dataFromApi)) {
        setData(prepareData(dataFromApi));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data === null) {
    return <Spinner />;
  }

  if (data != null && data.length === 0) {
    return <p>V následujících dnech není plánovaná žádná výluka.</p>;
  }

  if (data != null && data.length > 0) {
    return (
      <table>
        <thead>
          <tr>
            <th>Datum</th>
            <th>Od</th>
            <th>Do</th>
            <th>Vysílač</th>
            <th>Kanál</th>
            <th>Stanoviště</th>
          </tr>
        </thead>
        <tbody>
          {data.map((line, i) => (
            <tr key={i}>
              <td>{formatData(line, "DATE")}</td>
              <td>{formatData(line, "FROM_TIME")}</td>
              <td>{formatData(line, "TO_TIME")}</td>
              <td>{formatData(line, "TRANSMITTER")}</td>
              <td>{formatData(line, "CHANNEL")}</td>
              <td>{formatData(line, "LOCATION")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
};

import React from "react";
import { UneditablePageProps } from "@components/core/PageTypes/UneditablePage/UneditablePage";
import { DefaultLayout } from "@components/core/Layouts/DefaultLayout/DefaultLayout";
import { PhotoserviceBox } from "./PhotoserviceBox";

import imageHeader from "./promedia.png";

export const PhotoservicePage: React.FC<UneditablePageProps> = ({ page, allPages }) => {
  const updatedPage: any = {
    ...page,
    perex: "Fotografie k programu České televize",
    headerImage: {
      downloadUrl: imageHeader.src as string,
      width: 2516,
      height: 1724,
    },
  };

  return (
    <DefaultLayout page={updatedPage} allPages={allPages}>
      <PhotoserviceBox />
    </DefaultLayout>
  );
};

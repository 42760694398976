import { FaqPage } from "./PageTypes/FaqPage/FaqPage";
import { BaseContentPage } from "./PageTypes/BaseContentPage/BaseContentPage";

const sectionId = "webFaq";

const baseSectionPath = "/caste-dotazy";

const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);

const pageTypes = [
  { id: "FaqPage", component: FaqPage },
  { id: "BaseContentPage", component: BaseContentPage },
];

export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
};

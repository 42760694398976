import React from "react";
import { getSubblockComponent } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import classNames from "classnames";
import { IconBlock } from "@components/blocks/IconBlock/IconBlock";
import {
  basicCard,
  borderIcon,
  cardBoxClear,
  cardBox,
  cardWrapper,
  gridContent,
  gridIcon,
  linkContainerBlock,
  linkContainerBlockBorder,
} from "@components/blocks/StructuredContentBlock/sublocks/LinkContainerBlock/LinkContainerBlock.css";

const LinkContainerBlockContentIcon = ({ container }: { container: any }) => {
  return (
    <>
      {container?.icon ? (
        <div className={`gridContent ${classNames(borderIcon, gridContent, gridIcon)}`}>
          <IconBlock value={container.icon} />
        </div>
      ) : null}
      {container.childrens.map((children: any) => (
        <div key={children.id} className={`gridContent ${classNames(gridContent)}`}>
          {getSubblockComponent(children)}
        </div>
      ))}
    </>
  );
};

const LinkContainerBlockContent = ({ container }: { container: any }) => {
  return (
    <div className={classNames(linkContainerBlock)}>
      {container.childrens.map((children: any) => (
        <div key={children.id} className={`gridContent ${classNames(gridContent)}`}>
          {getSubblockComponent(children)}
        </div>
      ))}
    </div>
  );
};

export const LinkContainerBlock = ({ container }: { container: any }) => {
  return (
    <div
      className={
        (container.border ? classNames(linkContainerBlockBorder) : undefined,
        !!container.displayAsCard ? classNames(cardBoxClear) : undefined,
        classNames(cardWrapper))
      }
    >
      <div className={classNames(basicCard, !!container.displayAsCard ? cardBox : undefined)}>
        {!!container?.icon?.text || !!container?.icon?.icon ? (
          <LinkContainerBlockContentIcon container={container} />
        ) : (
          <LinkContainerBlockContent container={container} />
        )}
      </div>
    </div>
  );
};

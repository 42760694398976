import { BaseContentPage } from "@components/core/PageTypes/BaseContentPage/BaseContentPage";
import { UneditablePage } from "@components/core/PageTypes/UneditablePage/UneditablePage";
import { PersonPage } from "@components/core/PageTypes/PersonPage/PersonPage";
import { PersonListPage } from "@components/core/PageTypes/PersonListPage/PersonListPage";
import { MediaPage } from "@components/core/PageTypes/MediaPage/MediaPage";
import { DictionaryPage } from "@components/core/PageTypes/DictionaryPage/DictionaryPage";
import { DictionaryItemPage } from "@components/core/PageTypes/DictionaryItemPage/DictionaryItemPage";
import { FaqPage } from "@components/core/PageTypes/FaqPage/FaqPage";
import { RedirectPage } from "@components/core/PageTypes/RedirectPage/RedirectPage";
import { ArticlePage } from "@components/core/PageTypes/ArticlePage/ArticlePage";
import { ArticleSummaryPage } from "@components/core/PageTypes/ArticleSummaryPage/ArticleSummaryPage";
import { DocumentsPage } from "@components/core/PageTypes/DocumentsPage/DocumentsPage";

const sectionId = "core";

const isThisSection = (page: any) => true;

const pageTypes = [
  { id: "UneditablePage", component: UneditablePage },
  { id: "BaseContentPage", component: BaseContentPage },
  { id: "PersonPage", component: PersonPage },
  { id: "PersonListPage", component: PersonListPage },
  { id: "MediaPage", component: MediaPage },
  { id: "DictionaryPage", component: DictionaryPage },
  { id: "DictionaryItemPage", component: DictionaryItemPage },
  { id: "FaqPage", component: FaqPage },
  { id: "RedirectPage", component: RedirectPage },
  { id: "ArticlePage", component: ArticlePage },
  { id: "ArticleSummaryPage", component: ArticleSummaryPage },
  { id: "DocumentsPage", component: DocumentsPage },
];

export const staticContentBlocks = [];

export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
  staticContentBlocks: staticContentBlocks,
};

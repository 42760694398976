import React from "react";
import { getRegisteredComponent, TComponentSection } from "./index";

export type StaticContentBlockComponentProps = {
  content?: string;
  parameters?: Record<string, any>;
};

type StaticContentBlockProps = StaticContentBlockComponentProps & {
  section: TComponentSection;
  componentName?: string;
  id: number;
  title: string;
};

export const StaticContentBlock = ({
  section,
  componentName = undefined,
  content = undefined,
  parameters = undefined,
}: StaticContentBlockProps) => {
  // HTML content
  if (componentName == undefined && !!content) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }

  // staticka komponenta obsahu
  if (section && componentName) {
    const component = getRegisteredComponent(section, componentName);
    if (component) {
      return React.createElement(component, { content: content, parameters: parameters });
    }
  }

  // nenalezeno
  return <></>;
};

import { HTMLBlock } from '@components/blocks/HTMLBlock/HTMLBlock';
import { IconBlock } from '@components/blocks/IconBlock/IconBlock';
import classNames from 'classnames';
import {
  headerDecoration,
  headerIcon,
  headerIconRest
} from "@components/blocks/StructuredContentBlock/sublocks/HeadlineWithIconBlock/HeadlineWithIconBlock.css";

export const HeadlineWithIconBlock = ({ value }: { value: any }) => {
  return (
    <div  className={classNames(headerIcon)}>
      {value?.icon ? <IconBlock value={value.icon} /> : null}
      <div  className={classNames(headerIconRest)}>
        <HTMLBlock HTML={value.text} />
        {!!value.decoration ? (
          <div  className={classNames(headerDecoration)}></div>
        ) : null}
      </div>
    </div>
  )
}

import { StaticContentBlockComponentProps } from "@components/blocks/StructuredContentBlock/sublocks/StaticContentBlock/StaticContentBlock";
import {
  cardBoxStyles,
  cardBoxTitle,
  companyRulesGrid,
  companyRulesTitle
} from "@components/webCareer/StaticContentComponents/OurCompanyRules/OurCompanyRules.css";


export const OurCompanyRules = ({}: StaticContentBlockComponentProps) => {

  return (
    <>
      <h2 className={companyRulesTitle}>Naším posláním je veřejná služba</h2>
      <div className={companyRulesGrid}>
        <div className={cardBoxStyles}>
          <h3 className={cardBoxTitle}>Budujeme</h3>
          <p>silnou nezávislou a důvěryhodnou mediální instituci.</p>
        </div>
        <div className={cardBoxStyles}>
          <h3 className={cardBoxTitle}>Dbáme</h3>
          <p>na otevřenosti, nestrannosti a nezávislosti. Dodržujeme zásady objektivity a vyváženosti.</p>
        </div>
        <div className={cardBoxStyles}>
          <h3 className={cardBoxTitle}>Usilujeme</h3>
          <p>
            o každodenní poskytování pravdivé, úplné, vyvážené a nezaujaté zprávy o stavu světa,
            v němž žijeme.
          </p>
        </div>
        <div className={cardBoxStyles}>
          <h3 className={cardBoxTitle}>Stojíme</h3>
          <p>
            vždy na straně lidské důstojnosti, základních lidských práv a svobod, úctě k přírodě
            a kulturnímu dědictví.
          </p>
        </div>
      </div>
    </>
  );
};


import Link from 'next/link';
import { UneditablePageProps } from "@components/core/PageTypes/UneditablePage/UneditablePage"
import { RegionalBroadcastingLayout } from "./../../../../Layouts/RegionalBroadcastingLayout/RegionalBroadcastingLayout"
import { PAGES } from './../../../../Layouts/RegionalBroadcastingLayout/regionalBroadcastingGlobalMenuConfig'
import { Content } from '@czechtv/components'
import { createUseStyles } from 'react-jss';
import { SmartImage2 } from '@components/reusables/SmartImage/SmartImage2';
import classNames from 'classnames';
import {
  mediaMinTablet768,
  mediaMinTablet640,
  Body18Medium,
  Body21Large,
} from '@czechtvPackagesOverrides/styles/fromCTV/index';
import { GRID_GAP } from '@consts/consts';
import { buttonPrimary } from '@czechtvPackagesOverrides/styles/utilities/buttonPrimary'
import { HeaderH1, HeaderH2 } from '@czechtvPackagesOverrides/styles/utilities/headings'

import imageTop from './images/header.jpg'
import imageMapaNorth from './images/mapa_sever.jpg'
import imageMapaSouth from './images/mapa_jih.jpg'

const basicContent = {
  '& h2': {
    ...HeaderH2,
    marginTop: `${GRID_GAP * 2}px`,
    [mediaMinTablet640]: {
      marginTop: `0px`
    },
  },
  '& p': {
    marginBottom: `${GRID_GAP * 2}px`,
    ...Body18Medium,
    color: '#393948',
    [mediaMinTablet640]: {
      marginBottom: `${GRID_GAP * 3}px`,
    },
  },
}

const articleBasicShape = {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: `0px ${GRID_GAP * 5}px`,
    marginBottom: `${GRID_GAP * 8}px`,
    [mediaMinTablet640]: {
      flexWrap: 'nowrap',
      marginTop: `${GRID_GAP * 6}px`,
    },
    [mediaMinTablet768]: {
      marginTop: `${GRID_GAP * 13}px`,
    },

}

const useStyles = createUseStyles({
  headerWrapper: {
    width: `100%`,
    height: `calc(100vh - 120px)`,
    position: `relative`,
    boxSizing: `border-box`,
    maxHeight: `600px`,
    minHeight: `440px`,
    backgroundColor: '#22647c',
    [mediaMinTablet640]: {
      maxHeight: `576px`,
      minHeight: `480px`,
    }
  },
  headerBackgroundWrap: {
    top: `0px`,
    width: `100%`,
    height: `100%`,
    overflow: `hidden`,
    position: `absolute`,
    '& > div': {
      top: `0px`,
      width: `100%`,
      height: `100%`,
      overflow: `hidden`,
      position: `absolute`,
    },
    '& img': {
      top: `0px`,
      width: `100%`,
      height: `100%`,
      position: `absolute`,
      objectPosition: '50% 50%',
    },
  },
  headerBackgroundGradient: {
    top: `0px`,
    width: `100%`,
    height: `100%`,
    overflow: `hidden`,
    position: `absolute`,
    display: `block`,
    background: `linear-gradient(270deg, rgba(29, 36, 56, 0) 0%, rgba(29, 36, 56, 0.7) 100%)`,
  },
  headerTxt: {
    paddingTop: `210px`,
    position: 'relative',
    width: `100%`,
    textAlign: `center`,
    color: '#FFFFFF',
    marginBottom: `${GRID_GAP * 3}px`,
    '& h1': {
      ...HeaderH1,
      color: '#FFFFFF',
      marginBottom: `${GRID_GAP * 3}px`,
      [mediaMinTablet640]: {
        marginBottom: `${GRID_GAP * 4}px`,
      },
    },
    '& p': {
      ...Body18Medium,
      marginBottom: `${GRID_GAP * 3}px`,
      [mediaMinTablet640]: {
        ...Body21Large,
        fontSize: `28px`,
        marginBottom: `${GRID_GAP * 4}px`,
      }
    }
  },
  articles: {
    marginTop: `${GRID_GAP * 4}px`,
    [mediaMinTablet640]: {
      marginTop: `${GRID_GAP * 6}px`,
    },
    [mediaMinTablet768]: {
      marginTop: `${GRID_GAP * 13}px`,
    },
  },
  articleLeft: {
    ...articleBasicShape,
  },
  articleLeftTxt: {
    width: '100%',
    order: '2',
    textAlign: 'center',
    [mediaMinTablet640]: {
      order: '1',
      width: '43%',
      textAlign: 'left',
    },
    ...basicContent,

  },
  articleLeftImg: {
    order: '1',
    width: '100%',
    [mediaMinTablet640]: {
      order: '2',
      width: '57%',
    },
  },
  articleRight: {
    ...articleBasicShape,
  },
  articleRightTxt: {
    width: '100%',
    order: '2',
    textAlign: 'center',
    [mediaMinTablet640]: {
      order: '2',
      width: '43%',
      textAlign: 'left',
    },
    ...basicContent,

  },
  articleRightImg: {
    order: '1',
    width: '100%',
    [mediaMinTablet640]: {
      order: '1',
      width: '57%',
    },
  },
  articleCenter: {
    maxWidth: '950px',
    margin: `0px auto ${GRID_GAP * 8}px auto`,
    paddingTop: `${GRID_GAP * 0}px`,
    textAlign:'center',
    ...basicContent,
    [mediaMinTablet640]: {
      paddingTop: `${GRID_GAP * 4}px`,
      margin: `0px auto ${GRID_GAP * 8}px auto`,
    },
  },
  articleContent: {
    fontStyle: 'italic',
  },
  articleSign: {
    fontWeight: 'bold',
  }  ,
  linkLikeButton: {
    ...buttonPrimary,
  }
})

export const RegionalBroadcastingHomePage: React.FC<UneditablePageProps> = ({ page, allPages }) => {
  const classes = useStyles();

  return (
    <RegionalBroadcastingLayout page={page} allPages={allPages} layoutSubtype="HOMEPAGE">
      <>
      <header className={classNames(classes.headerWrapper)}>
          <div className={classNames(classes.headerBackgroundWrap)}>
            <SmartImage2 width={2083} height={576} src={imageTop.src} alt="ČT 24 údálosti v regionech" bigImage={true} />
          </div>
          <div className={classNames(classes.headerBackgroundGradient)}></div>
          <Content>
            <div className={classNames(classes.headerTxt)}>
              <h1>Jsme tam, kde jste i Vy</h1>
              <p>Nové Události v regionech</p>
            </div>
          </Content>
      </header>
      <Content>

        <section className={classNames(classes.articles)}>
          <article className={classNames(classes.articleLeft)}>
            <div className={classNames(classes.articleLeftImg)}>
              <SmartImage2
                src={imageMapaNorth.src}
                alt="Mapa Regionální vysílání sever"
                width={625} height={492}
              />
            </div>
            <div className={classNames(classes.articleLeftTxt)}>
              <h2>Od Aše až po Žermanice: nově vysíláme i od Vás</h2>
              <p>Zajímá Vás co se děje ve Vašem okolí? Pusťte si každý všední den v 18 hodin Českou televizi. Jako jediní máme zpravodaje po celé republice a tak vám můžeme přinášet zprávy na míru právě z Vašeho kraje. Souhrn toho nejdůležitějšího nabízí každý všední den <strong>od 18 hodin na ČT1</strong>  zpravodajská relace <strong>Události v regionech</strong>.
              </p>
              <p>Ze středních Čech, z jižní i severní Moravy a <strong>od pondělí 3. dubna</strong> nově také ze severovýchodu a jihozápadu Čech. Zkrátka z  celé České republiky.</p>
            </div>
          </article>
          <article className={classNames(classes.articleRight)}>
            <div className={classNames(classes.articleRightImg)}>
              <SmartImage2
                src={imageMapaSouth.src}
                alt="Mapa Regionální vysílání Jih"
                width={625} height={492}
              />
            </div>
            <div className={classNames(classes.articleRightTxt)}>
              <h2>Jak si naladím zprávy ze svého kraje?</h2>
              <p>Nové krajské relace si vyžadují také nové naladění. K tomu, abyste mohli sledovat zpravodajství ze svého okolí, je proto potřeba naladit <strong>správnou verzi programu ČT1</strong>, který pořad vysílá. K dispozici jich máte celkem pět.</p>
              <p>Zajímá Vás dění třeba z Brna? Pak jednoduše nalaďte program ČT1 označený písmeny „JM“, tedy zkratkou pro jižní Moravu.</p>
              <p>Obdobně můžete sledovat zpravodajství ze severní Moravy a Slezska („SM“), jihu a západu Čech („JZC“), severu a východu Čech („SVC“) i z Prahy a jejího okolí (kanál ČT1 bez dalšího označení). Naladěním kanálu ČT1 s regionálním označením Vám zůstane po většinu dne program stejný, vysílání se vydělí na pět různých verzí pouze v době večerních Událostí v regionech.</p>
              <Link
                href={PAGES.regionalBradacastingHowToTunePage.url}
                className={classNames(classes.linkLikeButton)}>
                Detailní návod na přeladění
              </Link>
            </div>
          </article>
        </section>
      </Content>
      </>
    </RegionalBroadcastingLayout>
  );
}

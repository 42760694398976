import classNames from "classnames";
import {
  containerHeader,
  containerHeader_h2,
  contact,
  contact_paragraph,
  contact_subHead,
  contactIn,
  contactLink,
  contactTel,
  contactMail,
} from "./ContactBox.css";

export const ContactBox = () => {
  return (
    <section className={classNames(containerHeader)}>
      <div className={classNames(contact)}>
        <h2 className={classNames(containerHeader_h2)}>Nevíte si rady? Poradíme Vám</h2>
        <p className={classNames(contact_paragraph, contact_subHead)}>
          Nedaří se Vám zprávy z Vašeho kraje naladit? Obraťte se na Divácké centrum České televize,
          které je Vám k dispozici od pondělí do pátku od 7:30 do 17:30 hodin.
        </p>
        <div className={classNames(contactIn)}>
          <a href="tel:+420261136113" className={classNames(contactLink, contactTel)}>
            +420 261 136 113
          </a>
          <a href="mailto:info@ceskatelevize.cz" className={classNames(contactLink, contactMail)}>
            info@ceskatelevize.cz
          </a>
        </div>
      </div>
    </section>
  );
};

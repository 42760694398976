/* eslint-disable @next/next/no-img-element */
import React, { useRef, useEffect, useState, memo } from "react";
import Image, { ImageProps } from "next/image";
import classNames from "classnames";
import { imageMediaServerUrl, isStaticImportImage } from "@utils/imageLoaders";
import { TRadiusValue } from "@consts/roundCorners";
import { ASPECT_RATIO, value2CssValue } from "@consts/aspectRatio";
import {
  smartImageContainer,
  smartImage,
  smartImageStyles,
  smartImageContainerLoader,
  smartImageContainerLoaderWide,
} from "./SmartImage2.css";

type ExtendProps = {
  cropAspectRatio?: ASPECT_RATIO;
  radius?: TRadiusValue;
  bigImage?: boolean;
  full?: boolean;
};

export type SmartImageProps = ImageProps & ExtendProps;

const brakpoints = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000, 2500];

const isSvg = (srcValue: any) => {
  const src = srcValue?.src ? srcValue.src : srcValue;
  const extension = src.split(".").pop() as string;
  return extension != undefined && ["svg", "svgz"].includes(extension.toLowerCase());
};

export const SmartImage2 = (props: SmartImageProps) => {
  const { cropAspectRatio, radius, bigImage, alt, full, ...imageProps } = props;

  // svg obrazek
  if (isSvg(imageProps.src)) {
    return <Image {...props} alt={!alt ? "" : alt} unoptimized={true} />;
  }

  // standardni obrazek
  return <BitmapSmartImage {...props} />;
};

const BitmapSmartImage = (props: SmartImageProps) => {
  const { className, cropAspectRatio, radius, bigImage, alt, full, ...imageProps } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [src, setSrc] = useState<string | null>(null);
  const [isStaticImport, setIsStaticImport] = useState<boolean | null>(null);
  const [show, setShow] = useState<boolean>(true);

  // pomer stran kontejneru
  const calculateCrosAspectRatioContainer = () => {
    const cropAspectRatioImage = cropAspectRatio || ASPECT_RATIO.ORIGINAL;
    return cropAspectRatioImage == ASPECT_RATIO.ORIGINAL
      ? `${imageProps.width}/${imageProps.height}`
      : value2CssValue[cropAspectRatioImage];
  };

  // ziskani src podle pozadovane sirky
  const getResizedImageUrl = (originalSrc: string) => {
    const containerWidth = containerRef.current?.clientWidth || 0;
    const targetWidth =
      brakpoints.find((width) => width > containerWidth) || brakpoints[brakpoints.length - 1];
    return imageMediaServerUrl(imageProps.src as string, targetWidth);
  };

  // prvotni nastaveni isStaticImport
  useEffect(() => {
    setShow(false);
    setIsStaticImport(isStaticImportImage(imageProps.src));
  }, [imageProps.src]);

  const setInitSrc = () => {
    if (isStaticImport !== null) {
      const src = imageProps.src as string;
      setSrc(isStaticImport ? src : getResizedImageUrl(src));
    }
  };

  // prvotni nastaveni src
  useEffect(() => {
    setInitSrc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStaticImport, imageProps]);

  const containerRefWidth = containerRef.current?.clientWidth || 0;

  return (
    <>
      <div
        ref={containerRef}
        style={{
          aspectRatio: calculateCrosAspectRatioContainer(),
        }}
        className={smartImageContainer}
      >
        <div
          className={classNames(
            smartImageContainerLoader,
            containerRefWidth > 1300 ? smartImageContainerLoaderWide : null
          )}
        ></div>
        {src !== null ? (
          <img
            src={src}
            ref={imageRef}
            style={{
              ...(show ? {} : { opacity: 0 }),
              ...(props.radius ? { borderRadius: props.radius } : {}),
              ...(props.cropAspectRatio
                ? { aspectRatio: `${value2CssValue[props.cropAspectRatio]}` }
                : {}),
              ...{ objectFit: "cover" },
            }}
            width="100%"
            height="100%"
            alt=""
            className={classNames(className, smartImageStyles, smartImage)}
            onLoad={() => {
              setShow(true);
            }}
          />
        ) : null}
      </div>
    </>
  );
};

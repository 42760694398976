import { DefaultLayout } from "@components/core/Layouts/DefaultLayout/DefaultLayout";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import { formatDateTime } from "@utils/datetime";

import { AuthorDateBox } from "@components/reusables/AuthorDateBox/AuthorDateBox";

export const ArticlePage = ({ page, allPages }: { page: any; allPages: any[] }) => {
  return (
    <DefaultLayout page={page} allPages={allPages}>
      <>
        {/** výpis autora a data */}
        <AuthorDateBox
          author={page?.displayedAuthorFullName}
          date={formatDateTime(page?.displayDate)}
        />

        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? (
          <AttachedDocumentsBlock
            documents={page.attachedDocuments.map((item: any) => item.value)}
          />
        ) : null}
      </>
    </DefaultLayout>
  );
};

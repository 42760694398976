import { ArticleSummaryPageContent } from './ArticleSummaryPageContent'
import { DefaultLayout } from "@components/core/Layouts/DefaultLayout/DefaultLayout";

export const ArticleSummaryPage = ({ page, allPages }: { page: any; allPages: any[] }) => {
  return (
    <DefaultLayout page={page} allPages={allPages}>
      <ArticleSummaryPageContent page={page} allPages={allPages}/>
    </DefaultLayout>
  );
};

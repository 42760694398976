import { Content } from "@czechtv/components";
import { WithoutSideMenuLayout } from "@components/core/Layouts/WithoutSideMenuLayout/WithoutSideMenuLayout";
import { DisplayStripBlock } from "@components/blocks/DisplayStripBlock/DisplayStripBlock";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import classNames from "classnames";
import {
  headerHP,
  headerHP_h1,
  displayStripBlockWrap,
  techHpOverwrite,
} from "./TechnologyHomePage.css";

export const TechnologyHomePage = ({ page, allPages }: any) => {
  const prepareDisplayStripData = (data: any) => {
    return {
      headline: data.headline,
      headlineLink: data.headlineLink.url,
      subheadline: data.subheadline,
      textHTML: data.textHtml,
      linksColumns: data.linksColumns,
      imagePosition: data.imagePosition,
      image: {
        src: data.image.downloadUrl,
        alt: data.headline,
        width: data.image.width,
        height: data.image.height,
      },
      links: data.links.map((link: any) => {
        return {
          label: link.title,
          url: link.url,
          target: link?.target ? link.target : undefined,
        };
      }),
    };
  };

  return (
    <WithoutSideMenuLayout page={page} allPages={allPages}>
      <>
        <Content>
          <div className={classNames(headerHP)}>
            <h1 className={classNames(headerHP_h1)}>{page.title}</h1>
          </div>
        </Content>

        {/** DisplayStripBlocks */}
        <Content>
          <div className={classNames(displayStripBlockWrap)}>
            {page.displayStrips.map((item: any, i: number) => (
              <DisplayStripBlock key={i} {...prepareDisplayStripData(item.value)} />
            ))}
          </div>
        </Content>
        {/** strukturovany obsah */}
        <Content>
          <div className={classNames(techHpOverwrite)}>
            <StructuredContenBlock content={page.structuredContent} />
          </div>
        </Content>
      </>
    </WithoutSideMenuLayout>
  );
};

import { projectGlobalMenuConfig } from "@components/layouts/components/Header/projectGlobalMenuConfig";
import { Blue_base } from "@czechtvPackagesOverrides/styles/fromCTV/index";
import { WrapperPositions } from "@czechtv/global-components";

export const PAGES = {
  regionalBradacastingHomePage: {
    url: "/jak-sledovat/televizni-signal/sireni-signalu/regionalni-vysilani/",
    label: "Úvod",
  },
  regionalBradacastingHowToTunePage: {
    url: "/jak-sledovat/televizni-signal/sireni-signalu/regionalni-vysilani/jak-naladit/",
    label: "Jak naladit",
  },
};

export const regionalBroadcastingGlobalMenuConfig = {
  ...projectGlobalMenuConfig,
};

export const regionalBroadcastingHomePageGlobalMenuConfig = {
  ...regionalBroadcastingGlobalMenuConfig,
  defaultPosition: WrapperPositions.absolute,
  style: {
    default: {
      dark: true,
      backgroundColor: "transparent",
    },
    floating: {
      dark: true,
      backgroundColor: Blue_base,
    },
  },
};

import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcMy7Do4iZu6y6tO0qYd9t0HJpbSmuoatNJB%2FpF%2F8SG2oVuvd%2FDnA8Bo9AurZXdb1zrAeL3vps24Xm2%2FdevR92a%2BX9Sdgm292o62TdfefHplgUOLcbNsP68Xs7dNaGjyZVcv2unbBibAj58Mj6dd06zev5QdGPz%2FPm5Af21m7X75Nu8DdPNJ%2FbHyCtBrBeScArx6TRU9pRX%2FDL%2BW%2FVc2jWvIVJExhaJSGS%2BI%2BoUX9CajM%2BkJVyjSXmFFApn2iHEKSlSAVSGjmfUQkldYlhmlJhLeclqMAixJOrqJV7YspVGBySRvngIzCoiw34bYbcIoypVGYm3C2MdR2GawuwCvD36%2FW4BFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmXXPeK%2BAFFhJbJIXBaffIXEfYyLOrOFru4yjLjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabmDzGAQsHxBZaZlD9eAh1eJOvYGTqTiKcesspo5WUHPEcnx6pRJnjUZwQ5Kp9hME0LIKsglx6k9HQ4z2kTnEklZiRtgrOpeVntvA05l142fz1vQ86nZjg%2Fs0wBMU5GxGNG0jbk4ozkdx2XgOKQdIWCuAQU56QpFcQloElKGyfstAQ0TRCl3OaczS7kmgu5m1Qr15eJct2K81QrF8tU5LjbC7k2vZEi1arKcHc9VxSnOlW55MV5ypOE6%2BRztYoj1dCpTrl5gmmk%2BlOdTO7uqwDl5jA4XAdAL043AciK0%2FsAhsRpF8BqcboNYKXaLoCTavsAXqo9BCik2mOAUqodApRS7SlAJdWeA0%2B24THpAIhCjzAAkhAk4mOhSCYAGiFJNgBaKekCoJOSPsB0P2mno0nz3DYd%2F8d0jUpfGzFsqWAPerkdHJdp8rz4C6uv6Qr05gC4OQBtDiCjqvJ27mxnsnZ1ABndJNNuNI3t9vcfw7HEwm8LAAA%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22utils%2Fvanilla%2Fshorthand.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xMThmMXAzMCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LXdyYXA6IHdyYXA7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKfQouXzExOGYxcDMxIHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZmxleDogMTsKfQ%3D%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fcomponents.css.ts.vanilla.css%22%2C%22source%22%3A%22W29iamVjdCBPYmplY3RdOmhvdmVyIHsKICB0cmFuc2l0aW9uOiBhbGwgdmFyKC0tXzE5bXJoYWEyNykgdmFyKC0tXzE5bXJoYWEyNik7CiAgYmFja2dyb3VuZC1wb3NpdGlvbjogcmlnaHQgMHB4IGNlbnRlcjsKfQoueDdhMHdtMCB7CiAgcGFkZGluZzogMCAwIHZhcigtLV8xOW1yaGFhMW8pIDA7CiAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFxKSAwIHZhcigtLV8xOW1yaGFhMXEpIDA7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwogIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1fMTltcmhhYTFlKTsKfQoueDdhMHdtMSB7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc2OHB4KSB7CiAgLng3YTB3bTAgewogICAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFzKSAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDEwMjRweCkgewogIC54N2Ewd20wIHsKICAgIG1hcmdpbjogMCAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fblocks%2FTestimonialBlock%2FTestimonialBlock.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62V0W7CIBSG732Ks4st60Ub6Fq19WZvsqCgsrXAEKdu2bsvRXRSWuuSxRgjcD7%2B858%2FbfKCdabMa4HgawRQE73iIp5LY2RdwgfRj3H8gotarwnB79Fs9D1KTiXYlqyICg7KaDYIUz4stbAdp2ZdAkbo3tt9srtLKUy8JDWvDm1cam%2B0Bzb8k5WAkzTXrG5WKy5YvGZ8tTYlpG7xKK4E1PxZyErqQGHhK8x%2BNZyuwN134OS0fEUxsoop36iKHEqYV3Lxdi7ZOVKGkKchDzX0KJjcICGPeps%2FXE7QSFUCUvvhoR4HrwilXKycuSenW0dF1CDP3zteK6kNEcZreHw1FlNvN809ifpohW9gEfLaBR0teYJaw%2BhpT0aA7KenscmNHDHASeEBKP%2BwiLnUlOlYE8q3m55GnmtGOYHNQjMmgAgKjzUXsXNknCG1jyws8Z8MQ5Pf2sl%2FXxbmXmGnQz0BaIHGDuREkq2RM7uwrNi%2BBBwUTFxBsx%2FvdPOEErL5DU5OfXSauZgPhEKH3Rb%2FRgoi1muh6gpH4MVfBtJJG0rNZDwNUoOvjaD7raGj0IvUd%2FUJOVcvRF0qKfIwvy1EjqZnxA9VvIMa%2FgYAAA%3D%3D%22%7D"
export var testimonial = '_1r4ptj90';
export var testimonialDescription = '_1r4ptj93';
export var testimonialHeading = '_1r4ptj95';
export var testimonialImageRounded = '_1r4ptj98';
export var testimonialItemRounded = '_1r4ptj97 _118f1p30';
export var testimonialPosition = '_1r4ptj94';
export var testimonialRectangle = '_1r4ptj91 _118f1p30';
export var testimonialTxt = '_1r4ptj96';
export var testimonialVideoRectangle = '_1r4ptj92';
export var testimonialVideoRounded = '_1r4ptj99';
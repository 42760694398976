import React from "react";
import classNames from "classnames";
import {
  authorDateBox,
  authorDateBoxAuthor,
  authorDateBoxDate,
  authorDateBoxParagraph
} from "@components/reusables/AuthorDateBox/AuthorDateBox.css";

interface AuthorDateBoxProps {
  author: string;
  date: string;
}

export const AuthorDateBox: React.FC<AuthorDateBoxProps> = ({ author, date }) => {
  return (
    <div className={classNames(authorDateBox)}>
      <p className={classNames(authorDateBoxParagraph, authorDateBoxDate)}>{date}</p>
      <p className={classNames(authorDateBoxParagraph, authorDateBoxAuthor)}>{author}</p>
    </div>
  );
};

import { UneditablePage } from "@components/webCareer/PageTypes/UneditablePage/UneditablePage";
import { BaseContentPage } from "@components/webCareer/PageTypes/BaseContentPage/BaseContentPage";
import { ArticleSummaryPage } from "@components/webCareer/PageTypes/ArticleSummaryPage/ArticleSummaryPage";
import { ArticlePage } from "@components/webCareer/PageTypes/ArticlePage/ArticlePage";
import { staticContentBlocks } from "./StaticContentComponents/index";

const sectionId = "webCareer";

const baseSectionPath = "/kariera";

const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);

const pageTypes = [
  { id: "UneditablePage", component: UneditablePage },
  { id: "BaseContentPage", component: BaseContentPage },
  { id: "ArticleSummaryPage", component: ArticleSummaryPage },
  { id: "ArticlePage", component: ArticlePage },
];

export { staticContentBlocks };

export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
  staticContentBlocks: staticContentBlocks,
};

import React from "react";
import classNames from "classnames";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { getCzechDate } from "@czechtv/utils";
import { DATE_FORMAT, TDateFormat } from "@consts/consts";
import { container, containerH3 } from "./EventBlock.css";

const YET_TEXT = "dosud";

export const EventBlock = ({ value }: { value: any }) => {
  const getDateFromAsString = (value: any) => {
    const formatString = DATE_FORMAT[value.dateFormat as TDateFormat];
    return getCzechDate(value.fromDatetime).format(formatString);
  };

  const getDateToAsString = (value: any) => {
    if (value.toDatetime) {
      const formatString = DATE_FORMAT[value.dateFormat as TDateFormat];
      return getCzechDate(value.toDatetime).format(formatString);
    } else {
      if (value.showYetText) {
        return YET_TEXT;
      }
    }
    return "";
  };

  const getDateRangeAsString = (value: any): string => {
    const fromString = getDateFromAsString(value);
    const toString = getDateToAsString(value);
    return !!toString && fromString != toString ? `${fromString} - ${toString}` : fromString;
  };

  return (
    <section className={classNames(container)}>
      <div>{getDateRangeAsString(value)}</div>
      <h3 className={classNames(containerH3)}>{value.title}</h3>
      {value?.text ? <HTMLBlock HTML={value.text} /> : null}
    </section>
  );
};

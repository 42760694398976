import { FaqBlock, TOP_CATEGORY_SLUG } from '@components/blocks/FaqBlock/FaqBlock'
import classNames from 'classnames';
import {
  headerFaq
} from "@components/blocks/StructuredContentBlock/sublocks/FaqWidgetBlock/FaqWidgetBLock.css";


export const FaqWidgetBlock = ({ value }: { value: any }) => {
  return (
    <div>
      {/** nadpis bloku */}
      {value.title ? (
        <h2  className={classNames(headerFaq)}>{value.title}DD</h2>
      ) : null}

      {/** vypis FAQs */}
      <FaqBlock faqCategories={value.faqs} displayCategorySlug={value.ident ? value.ident : TOP_CATEGORY_SLUG} />
    </div>
  )
}

import classNames from 'classnames';
import Link from 'next/link'
import {
  introBox,
  introBoxContactHR,
  introBoxContactHRcontact,
  introBoxContactHRIcon,
  introBoxContactHRLinkIcon,
  introBoxContactHRlogo,
  introBoxContactHRlogoIcon,
  introBoxContactHRlogoSubTitle,
  introBoxContactHRlogoTitle,
  introBoxContactHRmapLink,
  introBoxItemLinkArrow,
  moveToTop
} from "@components/core/PageTypes/UneditablePage/pages/GlobalContactsPage/components/GlobalContactTop/GlobalContatctTop.css";

export const GlobalContactTop = () => {
  return (
    <article className={classNames(moveToTop)}>

          <div className={classNames(introBox)} >
            <div className={classNames(introBoxContactHR)} >
              <div className={classNames(introBoxContactHRlogo)}>
                <svg className={classNames(introBoxContactHRlogoIcon)} viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="104" height="104" rx="52" transform="matrix(-1 0 0 1 104 0)" fill="#F2F4F8"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M39.9618 36.4177C43.1271 36.1676 46.5535 36.044 49.3333 36V40.1058C48.015 40.1526 46.5444 40.2407 45.1638 40.3874C40.3082 40.905 39.4014 41.9543 38.8138 46.6065C38.5687 48.5453 38.4963 50.601 38.4963 52.0025C38.4963 53.3984 38.5687 55.4586 38.8138 57.3913C39.4014 62.0412 40.3082 63.0916 45.1638 63.6092C46.5444 63.7571 48.015 63.8451 49.3333 63.892V68C46.5535 67.9554 43.1271 67.8318 39.9618 67.5801C31.2514 66.8858 29.6263 65.4741 28.5694 59.2376C28.1291 56.6394 28 53.8759 28 51.9992C28 50.1218 28.1291 47.3589 28.5694 44.7607C29.6263 38.5236 31.2514 37.1136 39.9618 36.4177Z" fill="#041E42"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M75.4076 51.9992C75.4076 53.8759 75.2779 56.6394 74.8381 59.2376C73.7818 65.4741 72.1565 66.8858 63.4451 67.5801C60.2802 67.8312 56.8542 67.9554 54.0742 68V63.892C55.3915 63.8451 56.8627 63.7571 58.2417 63.6092C63.0992 63.0916 64.005 62.0412 64.592 57.3913C64.8371 55.4586 64.9107 53.3984 64.9107 52.0025C64.9107 50.601 64.8371 48.5453 64.592 46.6065C64.005 41.9543 63.0992 40.905 58.2417 40.3874C56.8627 40.2407 55.3915 40.1526 54.0742 40.1058V36C56.8542 36.044 60.2802 36.1671 63.4451 36.4177C72.1565 37.1136 73.7818 38.5236 74.8381 44.7607C75.2779 47.3589 75.4076 50.1218 75.4076 51.9992Z" fill="#ED1C24"/>
                </svg>
                <h3 className={classNames(introBoxContactHRlogoTitle)}>Divácké centrum
                  <span className={classNames(introBoxContactHRlogoSubTitle)}>Na Hřebenech II 1132/4,<br/>140 70 Praha 4 - Kavčí hory</span>
                </h3>
              </div>
              <div className={classNames(introBoxContactHRcontact)}>
                <div>
                  <a href="mailto:info@ceskatelevize.cz" className={classNames(introBoxContactHRIcon, introBoxContactHRLinkIcon)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.22231 4.22199C8.5183 -0.073997 15.4823 -0.073997 19.7783 4.22199C24.0743 8.51798 24.0743 15.482 19.7783 19.778C15.4823 24.074 8.5183 24.074 4.22231 19.778C-0.0728005 15.482 -0.0728005 8.51798 4.22231 4.22199ZM19.0003 10.5L12.4476 13.7764L12.3241 13.8281C12.1143 13.8999 11.8864 13.8999 11.6765 13.8281L11.5531 13.7764L5.00035 10.5V15.4C5.00035 16.2346 5.63931 16.9199 6.45471 16.9935L6.60035 17H17.4003C18.2349 17 18.9202 16.361 18.9938 15.5456L19.0003 15.4V10.5ZM17.4003 7H6.60035C5.76578 7 5.08046 7.63896 5.00689 8.45437L5.00035 8.6V9.25L11.5531 12.5264L11.6765 12.5781C11.8864 12.6499 12.1143 12.6499 12.3241 12.5781L12.4476 12.5264L19.0003 9.25V8.6C19.0003 7.76544 18.3614 7.08011 17.546 7.00654L17.4003 7Z" fill="#041E42"/>
                    </svg>
                    info@ceskatelevize.cz
                  </a>
                  <a href="tel:+420261136113" className={classNames(introBoxContactHRIcon, introBoxContactHRLinkIcon)}>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.72143 19.778C9.01754 24.074 15.9818 24.074 20.2779 19.778C24.574 15.482 24.574 8.51798 20.2779 4.22199C15.9818 -0.073997 9.01754 -0.073997 4.72143 4.22199C0.426191 8.51798 0.426191 15.482 4.72143 19.778ZM10.6278 7.42711C10.9538 7.75235 11.023 8.25508 10.7969 8.6564L9.88427 10.0414C9.73307 10.3088 9.77932 10.644 9.99734 10.8607L13.6268 14.4812C13.8442 14.6984 14.1802 14.7442 14.448 14.5931L15.8357 13.682C16.2379 13.4558 16.7418 13.5248 17.068 13.8507L18.6991 15.4778C19.1003 15.8788 19.1003 16.5282 18.6991 16.9292L17.9269 17.6995C17.0462 18.5759 15.685 18.7527 14.6087 18.1305L14.535 18.0885C11.3351 16.2646 8.65874 13.651 6.76256 10.4986L6.39182 9.88131C5.74341 8.80512 5.91292 7.42673 6.80288 6.5388L7.54261 5.80088C7.73546 5.60824 7.99717 5.5 8.27008 5.5C8.54299 5.5 8.8047 5.60824 8.99754 5.80088L10.6278 7.42711Z" fill="#041E42"/>
                  </svg>
                  261 136 113
                  </a>
                  <p  className={classNames(introBoxContactHRIcon)}>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 1C18.5751 1 23.5 5.92487 23.5 12C23.5 18.0751 18.5751 23 12.5 23C6.42487 23 1.5 18.0751 1.5 12C1.5 5.92487 6.42487 1 12.5 1ZM12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3ZM12.5 6C13.0128 6 13.4355 6.38604 13.4933 6.88338L13.5 7V12C13.5 12.221 13.4268 12.4345 13.2939 12.608L13.2071 12.7071L9.70711 16.2071C9.31658 16.5976 8.68342 16.5976 8.29289 16.2071C7.93241 15.8466 7.90468 15.2794 8.2097 14.8871L8.29289 14.7929L11.5 11.584V7C11.5 6.48716 11.886 6.06449 12.3834 6.00673L12.5 6Z" fill="#041E42"/>
                    </svg>
                    všední dny: 7:30—17:30 hodin
                  </p>
                  </div>
              </div>
              <div className={classNames(introBoxContactHRmapLink)}>
                <div>
                  <Link
                    href="../kontakty/podatelna/"
                    target="_blank"
                    className={classNames(introBoxItemLinkArrow)}>
                    Podatelna
                  </Link>
                  <br/>
                  <a href="https://www.ceskatelevize.cz/vse-o-ct/press/" className={classNames(introBoxItemLinkArrow)}>Pro média</a>
                </div>
              </div>
            </div>
          </div>
    </article>
  );
}


import { BaseContentPage } from "./PageTypes/BaseContentPage/BaseContentPage";
import { DocumentsPage } from "./PageTypes/DocumentsPage/DocumentsPage";
const sectionId = "webLawsAndRules";

const baseSectionPath = "/zakony-a-pravidla";

const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);

const pageTypes = [
  { id: "BaseContentPage", component: BaseContentPage },
  { id: "DocumentsPage", component: DocumentsPage },
];

export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
  staticContentBlocks: [],
  getSideMenuRootPage: () => undefined,

};


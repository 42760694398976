import classNames from "classnames";
import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import { VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";
import Link from "next/link";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import {
  headerDecoration,
  topVideosItem,
  topVideosItem_h3,
  topVideosItem_h3_span,
  topVideosItem_p,
  topVideosItem_a,
  topVideosTxt,
  topVideosTxt_p,
  topVideosVideo,
} from "./MediaCardBlock.css";

export const MediaCardBlock = ({ item }: { item: any }) => {
  // nebyl zadan obrazek ani video
  if (!item?.video == false && !item?.image == false) return <></>;

  return (
    <div className={classNames(topVideosItem)}>
      {/** obrazek nebo video */}
      <div className={classNames(topVideosVideo)}>
        {!!item?.video ? (
          <>
            <VideoBlock videoID={item.video} />
          </>
        ) : (
          <>
            <ImageBlock
              cropAspectRatio={ASPECT_RATIO.HORIZONTAL_16_9}
              image={{
                downloadUrl: item.image.downloadUrl,
                width: item.image.width,
                height: item.image.height,
                title: item.image.title,
              }}
            />
          </>
        )}
      </div>

      <div className={classNames(topVideosTxt)}>
        <h3 className={classNames(topVideosItem_h3)}>
          {item?.title ? item.title : null}
          {item?.subtitle ? (
            <span className={classNames(topVideosItem_h3_span)}>{item.subtitle}</span>
          ) : null}
        </h3>
        <div className={classNames(headerDecoration)}></div>
        {item?.text ? (
          <p className={classNames(topVideosItem_p, topVideosTxt_p)}>{item.text}</p>
        ) : null}

        {/** linky */}
        {!!item?.links && item.links.length > 0 ? (
          <div>
            {item.links.map((link: any, i: number) => (
              <Link
                key={i}
                href={link.url}
                target={link?.target}
                className={classNames(topVideosItem_a)}
              >
                {link.title}
              </Link>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

import classNames from "classnames";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";

import {
  BASE_container, CHECKMARKS_container, DEFAULT_container
} from "@components/blocks/StructuredContentBlock/sublocks/UnorderedListBlock/UnorderedListBlock.css";

type TListStyle = "DEFAULT" | "CHECKMARKS";

export const UnorderedListBlock = ({
  markup,
  listStyle = "DEFAULT",
}: {
  markup: string;
  listStyle?: TListStyle;
}) => {
  const classes = listStyle === "DEFAULT" ? DEFAULT_container : CHECKMARKS_container;

  return (
    <div className={classNames(BASE_container, classes)}>
      <HTMLBlock HTML={markup} />
    </div>
  );
};

import { WithSideMenuLayout } from "@components/core/Layouts/WithSideMenuLayout/WithSideMenuLayout"
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock"
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock"
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock"
import classNames from 'classnames';
import {
  componentHeader,
  componentHeader_h1
}  from "./BaseContentPage.css"


export const BaseContentPage = ({ page, allPages }: any) => {

  return (
    <WithSideMenuLayout page={page} allPages={allPages}>
      <>
        {/** hlavicka */}
        <div className={classNames(componentHeader)}>
          <h1 className={classNames(componentHeader_h1)}>{page.title}</h1>
          <HTMLBlock HTML={page.perex} />
        </div>

        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? <AttachedDocumentsBlock documents={page.attachedDocuments.map((item: any) => item.value)} /> : null}
      </>
    </WithSideMenuLayout>
  )
}

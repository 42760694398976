import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { LayoutProps } from "@components/core/Layouts";
import { Header } from "@components/layouts/components/Header/Header";
import { BreadcrumbNavigation } from "@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation";
import { Content } from "@czechtv/components";
import {
  mediaMinDesktop1024,
  mediaMinTablet640,
} from "@czechtvPackagesOverrides/styles/fromCTV/index";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { createBreadcrumbs } from "@utils/pages";
import { GRID_GAP } from "@consts/consts";

const useStyles = createUseStyles({
  containerMain: {
    display: "block",
    [mediaMinDesktop1024]: {
      display: "flow-root",
    },
  },
  containerContent: {
    width: "100%",
    margin: `0px 0px ${GRID_GAP * 4}px 0px`,
    [mediaMinTablet640]: {
      margin: `0px 0px ${GRID_GAP * 8}px 0px`,
    },
  },
});

export const DefaultLayout: React.FC<LayoutProps> = ({ page, allPages, children }) => {
  const classes = useStyles();
  return (
    <>
      <Header />
      <Content>
        {allPages ? <BreadcrumbNavigation items={createBreadcrumbs(page, allPages)} /> : null}
        <div className={classNames(classes.containerMain)}>
          <div className={classNames(classes.containerContent)}>{children ? children : null}</div>
        </div>
      </Content>
      <CTGlobalFooter />
    </>
  );
};

// Link.tsx
import { default as NextLink } from "next/link";
import classNames from "classnames";
import { styles } from "./Link.css";

type TLinkStyle = "DEFAULT" | "BUTTON" | "ACTION_BUTTON";

type LinkProps = {
  url: string;
  title?: string;
  target?: string;
  linkStyle?: TLinkStyle;
};

export const Link = ({ url, title, target, linkStyle = "DEFAULT" }: LinkProps) => {
  const baseClass = styles.BASE;
  const linkClass = styles[linkStyle];
  return (
    <NextLink href={url} target={target} className={classNames(baseClass, linkClass)}>
      {title}
    </NextLink>
  );
};

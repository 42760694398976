import classNames from "classnames";
import Link from "next/link";
import { FaqBoxItem, FaqBoxItem_h3, FaqBoxItem_p, FaqBoxItemLink } from "./FaqContactUsBox.css";

export const FaqContactUsBox = () => {
  return (
    <article>
      <div className={classNames(FaqBoxItem)}>
        <h2 className={classNames(FaqBoxItem_h3)}>Nenašli jste odpověď na vaši otázku?</h2>
        <p className={classNames(FaqBoxItem_p)}>
          Kontaktujte Divácké centrum, kde vám rádi poradíme
        </p>
        <Link href="kontakty" className={classNames(FaqBoxItemLink)}>
          Zeptat se
        </Link>
      </div>
    </article>
  );
};

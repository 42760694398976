import { ArticleSummaryPageContent } from "@components/core/PageTypes/ArticleSummaryPage/ArticleSummaryPageContent";
import { DefaultLayout } from "@components/core/Layouts/DefaultLayout/DefaultLayout";
import { QuestionContactBox } from '@components/reusables/QuestionContactBox/QuestionContactBox'
import { XTweetBox } from "./XTweeteBlock";
import { ForMediaInfoBox } from "./ForMediaInfoBox";


export const ArticleSummaryPage = ({ page, allPages }: { page: any; allPages: any[] }) => {
  return (
    <DefaultLayout page={page} allPages={allPages}>
      <>
        <ArticleSummaryPageContent page={page} allPages={allPages} />
        <ForMediaInfoBox  />
        {/** <XTweetBox /> */}
        <QuestionContactBox/>
      </>
    </DefaultLayout>
  );
};

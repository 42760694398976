import { useRef, useState, useEffect } from "react";
import { useWidth } from "@czechtv/components";
import { useHeight } from "@czechtv/components";
import { ApplicationsLayout } from "./../../../../../Layouts/ApplicationsLayout/ApplicationsLayout";
import { PageContainer } from "@components/webTechnology/PageTypes/UneditablePage/pages/applications/PageContainer";
import { OtherPagesSwitch } from "../../../../../Layouts/ApplicationsLayout/components/OtherPagesSwitch/OtherPagesSwitch";
import { SmartImage2 } from "@components/reusables/SmartImage/SmartImage2";
import { Content } from "@czechtv/components";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import {
  mediaMinMobile480,
  mediaMinTablet640,
  mediaMinTablet768,
  mediaMinDesktop1024,
  Red_base,
  Grey_80,
  Body16Small,
  Body18Medium,
  ButtonTransitionDuration,
} from "@czechtvPackagesOverrides/styles/fromCTV/index";
import { GRID_GAP, ANIMATION_BEZIER_HOVER } from "@consts/consts";
import { GreyCool_10 } from "@czechtvPackagesOverrides/styles";
import { HeaderH1, HeaderH2 } from "@czechtvPackagesOverrides/styles/utilities/headings";

import imageTopSmartTV from "./images/aplikace_smartTv_top_x2.png";
import imgLogo1 from "./images/logo_android_x2.png";
import imgLogo2 from "./images/logo_samsung_x2.png";
import imgLogo3 from "./images/logo_lg_x2.png";
import imgLogo4 from "./images/logo_apple_x2.png";
import imgLogo5 from "./images/aplikace_hp_icon_hisense.svg";
import imgLogo6 from "./images/aplikace_hp_icon_philips.svg";

const useStyles = createUseStyles({
  header: {
    margin: `${GRID_GAP * 3}px auto ${GRID_GAP * 2}px auto`,
    position: "relative",
    textAlign: "center",
    color: "#FFFF",
    display: "block",
    padding: `0px 0px 0px 0px`,

    [mediaMinTablet640]: {
      textAlign: "left",
      margin: `${GRID_GAP * 6}px auto ${GRID_GAP * 6}px auto`,
      padding: `0px 0px ${GRID_GAP * 4}px 0px`,
      display: "flex",
      alignItems: "flex-end",
      gap: `0px ${GRID_GAP * 7}px`,
    },
    "@media (min-width: 700px)": {
      alignItems: "flex-start",
    },
    [mediaMinTablet768]: {
      margin: `${GRID_GAP * 6}px auto ${GRID_GAP * 6}px auto`,
      padding: `0px 0px ${GRID_GAP * 0}px 0px`,
    },
    "@media (min-width: 1100px)": {
      alignItems: "flex-start",
      padding: `0px 0px 3.5vw 0px`,
    },
    "@media (min-width: 1600px)": {
      alignItems: "flex-start",
      padding: `0px 0px 5vw 0px`,
      margin: `${GRID_GAP * 6}px auto ${GRID_GAP * 0}px auto`,
    },
    "@media (min-width: 2200px)": {
      alignItems: "flex-start",
      padding: `0px 0px ${GRID_GAP * 14}px 0px`,
    },
    "&::before": {
      position: "absolute",
      background: `radial-gradient(115% 115% at 50% 0%, #0A3368 0%, #001830 100%);`,
      content: '""',
      left: "-100vw",
      bottom: "0%",
      width: "300vw",
      height: "100vh",
      zIndex: "-1",
      "@media (min-width: 640px) and (max-height: 690px)": {
        height: "160vh",
      },
      "@media (min-width: 640px) and (max-height: 430px)": {
        height: "350vh",
      },
      "@media (min-width: 640px) and (max-height: 200px)": {
        height: "1050vh",
      },
      "@media (min-width: 640px) and (max-height: 100px)": {
        height: "10000vh",
      },
    },
    "& h1": {
      ...HeaderH1,
      color: `#FFFFFF`,
      order: "1",
    },
  },
  headerTxt: {
    ...Body18Medium,
    lineHeight: "1.628rem",
    flex: "0.9",
    position: "relative",
    zIndex: "2",
    "& a": {
      color: "#FFF",
      textDecoration: "underline",
      transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    },
    "& a:hover": {
      color: Red_base,
      transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    },
  },
  headerImage: {
    flex: "1.1",
    marginTop: `${GRID_GAP * 3}px`,
    position: "relative",
    zIndex: "2",
    "@media (min-width: 640px)": {
      marginTop: `${GRID_GAP * 18}px`,
    },
    "@media (min-width: 960px)": {
      marginTop: `${GRID_GAP * 12}px`,
    },
    "@media (min-width: 1100px)": {
      marginTop: `${GRID_GAP * 3}px`,
    },
  },
  headerWrap: {
    position: "relative",
  },
  headerShape: {
    position: "absolute",
    left: "0px",
    bottom: "0px",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    "@media (min-width: 460px)": {
      height: "100%",
    },
    "@media (min-width: 2440px)": {
      height: "70%",
    },
  },
  headerShapeInner: {
    backgroundColor: GreyCool_10,
    position: "absolute",
    transformOrigin: "left top",
    height: "150%",
    width: "140%",
    left: "-20%",
    bottom: "-135%",
    boxShadow: "-11px -11px 54px -18px rgba(0,0,0,0.46)",
    [mediaMinTablet640]: {
      left: "0px",
      bottom: "-150%",
    },
  },
  tvs: {
    display: "grid",
    marginBottom: `${GRID_GAP * 6}px`,
    gridTemplateColumns: "1fr",
    gap: `${GRID_GAP * 5}px ${GRID_GAP * 4}px`,
    [mediaMinMobile480]: {
      gap: `${GRID_GAP * 6}px ${GRID_GAP * 2}px`,
    },
    [mediaMinTablet640]: {
      gridTemplateColumns: "1fr 1fr",
      marginBottom: `${GRID_GAP * 11}px`,
      gap: `${GRID_GAP * 6}px ${GRID_GAP * 3}px`,
    },
    [mediaMinTablet768]: {
      gap: `${GRID_GAP * 6}px ${GRID_GAP * 8}px `,
    },
    [mediaMinDesktop1024]: {
      marginBottom: `${GRID_GAP * 15}px`,
    },
    "& div": {
      textAlign: "center",
      color: Grey_80,
      ...Body16Small,
      [mediaMinTablet640]: {
        ...Body18Medium,
      },
    },
    "& div h2": {
      ...HeaderH2,
      textAlign: "center",
    },
  },
  infoBox: {
    marginBottom: `${GRID_GAP * 6}px`,
    width: "100%",
    maxWidth: "640px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    color: Grey_80,
    ...Body16Small,
    [mediaMinTablet640]: {
      ...Body18Medium,
      marginBottom: `${GRID_GAP * 11}px`,
    },
    [mediaMinDesktop1024]: {
      marginBottom: `${GRID_GAP * 15}px`,
    },
    "& h2": {
      ...HeaderH2,
      textAlign: "center",
    },
  },
});

export const ApplicationsSmartTVPage: React.FC = () => {
  const classes = useStyles();
  const trackedElementRef = useRef<HTMLDivElement>(null);
  const trackedElementWidth = useWidth(trackedElementRef);
  const trackedElementHeight = useHeight(trackedElementRef);
  const [angle, setAngle] = useState<null | number>(null);

  // reakce na zmenu velikosti trackovaneho prvku
  useEffect(() => {
    let a = (Math.atan2(trackedElementHeight, trackedElementWidth) * 180) / Math.PI;
    if (trackedElementWidth < 2440) a = 9.77;
    setAngle(a);
  }, [trackedElementWidth, trackedElementHeight]);

  return (
    <PageContainer
      title="iVysílání v chytrých televizorech (Smart TV)"
      description="Prostřednictvím aplikace je iVysílání dostupné ve většině chytrých televizích. A vy máte tak možnost sledovat živé vysílání i archiv ČT. "
    >
      <ApplicationsLayout>
        <>
          <header className={classNames(classes.headerWrap)}>
            <Content>
              <section className={classNames(classes.header)}>
                <div className={classNames(classes.headerTxt)}>
                  <h1>iVysílání v chytrých televizorech (Smart TV)</h1>
                  <p>
                    Prostřednictvím aplikace je iVysílání dostupné pro řadu chytrých televizorů.
                    A vy máte tak možnost sledovat živé vysílání i archiv ČT.
                  </p>
                </div>
                <div className={classNames(classes.headerImage)}>
                  <SmartImage2 src={imageTopSmartTV.src} alt="SMart TV" width={594} height={382} />
                </div>
              </section>
            </Content>
            <div ref={trackedElementRef} className={classNames(classes.headerShape)}>
              <div
                className={classNames(classes.headerShapeInner)}
                style={{ transform: `rotate(-${angle}deg)` }}
              ></div>
            </div>
          </header>
          <main>
            <Content>
              <div className={classNames(classes.tvs)}>
                <div>
                  <div style={{ width: "163px", margin: `0 auto` }}>
                    <SmartImage2 src={imgLogo1.src} alt="Android TV" width={163} height={80} />
                  </div>
                  <h2>Android TV</h2>
                  <p>
                    Pokud běží váš televizor na systému Android TV, můžete si aplikaci iVysílání
                    jednoduše nainstalovat přímo z Google Play Store. Android TV podporují desítky
                    značek televizorů a další stále přibývají. Tento systém najdete také v různých
                    boxech, například v přijímačích O2TV.
                  </p>
                </div>
                <div>
                  <div style={{ width: "163px", margin: `0 auto` }}>
                    <SmartImage2 src={imgLogo2.src} alt="Samsung TV" width={163} height={80} />
                  </div>
                  <h2>Samsung</h2>
                  <p>
                    Aplikaci iVysílání si mohou nainstalovat majitelé televizorů Samsung s operačním
                    systémem Tizen vyrobených od roku 2018 dále.
                  </p>
                </div>
                <div>
                  <div style={{ width: "90px", margin: `0 auto` }}>
                    <SmartImage2 src={imgLogo3.src} alt="LG TV" width={90} height={80} />
                  </div>
                  <h2>LG</h2>
                  <p>
                    Také televizory LG s operačním systémem WebOS podporují aplikaci iVysílání, a to
                    od modelové řady 2018 výše.
                  </p>
                </div>
                <div>
                  <div style={{ width: "88px", margin: `0 auto` }}>
                    <SmartImage2 src={imgLogo4.src} alt="Apple TV" width={88} height={80} />
                  </div>
                  <h2>Apple TV</h2>
                  <p>
                    Zbrusu nová aplikace iVysílání nechybí ani na Apple TV, což jistě potěší všechny
                    majitelé jablečných zařízení.
                  </p>
                </div>
                <div>
                  <div style={{ width: "100px", margin: `0px auto 16px auto` }}>
                    <SmartImage2 src={imgLogo5.src} alt="Hisense" width={100} height={19} />
                  </div>
                  <h2>Hisense</h2>
                  <p>
                    Na televizorech Hisense najdete iVysílání v modelech s operačním systémem
                    Vidaa U6 nebo novějším.
                  </p>
                </div>
                <div>
                  <div style={{ width: "90px", margin: `0px auto 16px auto` }}>
                    <SmartImage2 src={imgLogo6.src} width={90} height={17} alt="Philips" />
                  </div>
                  <h2>PHILIPS</h2>
                  <p>
                    Aplikaci iVysílání rovněž podporují modely Philips se systémem Titan OS nebo
                    Saphi, které jsou na trhu od roku 2023 a později. Výjimečně může být aplikace
                    dostupná i na několika předchozích modelech.
                  </p>
                </div>
              </div>
              <OtherPagesSwitch linksTo={["toMobile", "toHbbTv"]} />
            </Content>
          </main>
        </>
      </ApplicationsLayout>
    </PageContainer>
  );
};

import React from "react";
import {
  UneditablePageRegister,
  UneditablePageBase,
  UneditablePageProps,
} from "@components/core/PageTypes/UneditablePage/UneditablePage";
import { CareerContact } from "./pages/CareerContact/CareerContact";
import { JobsCzTemplatePage } from "./pages/JobsCzTemplatePage/JobsCzTemplatePage";
import { InternshipApplicationFormPage } from "./pages/InternshipApplicationFormPage/InternshipApplicationFormPage";

// registrovane staticke stranky
const staticPages: UneditablePageRegister = {
  JobsCzTemplatePage: JobsCzTemplatePage,
  CareerContact: CareerContact,
  InternshipApplicationFormPage: InternshipApplicationFormPage,
};

export const UneditablePage = ({ page, allPages }: UneditablePageProps) => {
  return (
    <UneditablePageBase page={page} allPages={allPages} registerdUneditablePages={staticPages} />
  );
};

import classNames from 'classnames';
import { ImageBlock } from '@components/blocks/ImageBlock/ImageBlock'
import { VideoBlock } from '@components/blocks/VideoBlock/VideoBlock'
import { RADIUS } from '@consts/roundCorners'
import { ASPECT_RATIO } from '@consts/aspectRatio';
import {
  testimonial,
  testimonialDescription,
  testimonialHeading, testimonialImageRounded,
  testimonialItemRounded,
  testimonialPosition,
  testimonialRectangle,
  testimonialTxt, testimonialVideoRectangle,
  testimonialVideoRounded
} from "@components/blocks/TestimonialBlock/TestimonialBlock.css";

export const TestimonialBlock = ({ value }: { value: any }) => {
  if (value.displayType == 'WITH_ROUND_IMAGE') {
  return (
    <div  className={classNames(testimonial)}>
      {value.textPosition == 'LEFT' ? (
        <div  className={classNames(testimonialItemRounded)}>
        <TextContent value={value} />
        <MediaContent value={value} />
        </div>
      ) : (
        <div className={classNames(testimonialItemRounded)}>
          <MediaContent value={value} />
          <TextContent value={value} />
        </div>
      )}
    </div>
  ) }
  return (
    <div  className={classNames(testimonial)}>
      {value.textPosition == 'LEFT' ? (
        <div className={classNames(testimonialRectangle)}>
          <TextContent value={value} />
          <MediaContent value={value} />
        </div>
      ) : (
        <div className={classNames(testimonialRectangle)}>
          <MediaContent value={value} />
          <TextContent value={value} />
        </div>
      )}
    </div>
  )
}

const TextContent = ({ value }: { value: any }) => {
  return (
    <div className={classNames(testimonialTxt)}>
      <p className={classNames(testimonialDescription)}>
        {value.text}
      </p>
      <h3 className={classNames(testimonialHeading)}>
        {value.name}
        {!!value?.position ? (
          <span className={classNames(testimonialPosition)}>{value.position}</span>
        ) : null}
      </h3>
    </div>
  )
}

const MediaContent = ({ value }: { value: any }) => {

  // nebyl zadan obrazek ani video
  if (!value?.video == false && !value?.image == false) return (<></>)

  // typ: WITH_ROUND_IMAGE
  if (value.displayType == 'WITH_ROUND_IMAGE') {
    return (
      <>
        {!!value?.video ? (
          <div  className={classNames(testimonialVideoRounded)}>
            <VideoBlock videoID={value.video} />
          </div>
        ) : (
          <div className={classNames(testimonialImageRounded)}>
            <ImageBlock image={{
              downloadUrl: value.image.downloadUrl,
              width: value.image.width,
              height: value.image.height,
              title: value.image.title,
            }}
            cropAspectRatio={ASPECT_RATIO.SQUARE}
            radius={RADIUS.CIRCLE}
            />
          </div>
        )}
      </>
    )
  }

  // default
  return (
    <>
      {!!value?.video ? (
        <div className={classNames(testimonialVideoRectangle)}>
          <VideoBlock videoID={value.video} />
        </div>
      ) : (
        <div className={classNames(testimonialVideoRectangle)}>
          <ImageBlock image={{
            downloadUrl: value.image.downloadUrl,
            width: value.image.width,
            height: value.image.height,
            title: value.image.title,
          }} />
        </div>
      )}
    </>
  )
}

import { createUseStyles } from "react-jss";
import { DefaultLayout } from "@components/core/Layouts/DefaultLayout/DefaultLayout";
import { CtCouncilHomePageLayout } from "./../../Layouts/CtCouncilHomePageLayout/CtCouncilHomePageLayout";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";

const useStyles = createUseStyles({
  componentHeader: {},
});

const getLayout = (page: any) => {
  switch (page?.customLayout) {
    case "CtCouncilHomePageLayout":
      return CtCouncilHomePageLayout;
    default:
      return DefaultLayout;
  }
};

export const BaseContentPage = ({ page, allPages }: any) => {
  const classes = useStyles();
  const Layout = getLayout(page);
  return (
    <Layout page={page} allPages={allPages}>
      <>
        {/** hlavicka */}
        <div className={classes.componentHeader}>
          <h1>{page.title}</h1>
          <HTMLBlock HTML={page.perex} />
        </div>

        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? (
          <AttachedDocumentsBlock
            documents={page.attachedDocuments.map((item: any) => item.value)}
          />
        ) : null}
      </>
    </Layout>
  );
};

import classNames from "classnames";
import { ButtonSecondary } from "@czechtvPackagesOverrides/components/ButtonSecondary";
import { iconFromFilename, fileTypeHumanFromFilename, getHumanFileSize } from "./utils";

import {
  listWrap,
  listWrap_ul,
  listWrap_li,
  listWrapIcon,
  listWrapCont,
  listWrapCont_p,
  listWrapCont_span,
  listWrapLink,
} from "./AttachedDocumentsBlock.css";

interface IDocumentLink {
  id: number;
  title: string;
  downloadUrl: string;
  fileSize: number;
}

interface AttachedDocumentsBlockProps {
  documents: IDocumentLink[];
}

export const AttachedDocumentsBlock = ({ documents }: AttachedDocumentsBlockProps) => {
  return (
    <>
      {documents.length ? (
        <div className={classNames(listWrap)}>
          <ul className={classNames(listWrap_ul)}>
            {documents.map((document: IDocumentLink) => (
              <li key={document.id} className={classNames(listWrap_li)}>
                <div
                  className={classNames(listWrapIcon)}
                  dangerouslySetInnerHTML={{ __html: iconFromFilename(document.downloadUrl) }}
                ></div>
                <div className={classNames(listWrapCont)}>
                  {document.title}
                  <p className={classNames(listWrapCont_p)}>
                    <span className={classNames(listWrapCont_span)}>
                      {fileTypeHumanFromFilename(document.downloadUrl)}
                    </span>
                    Velikost: {getHumanFileSize(document.fileSize)}
                  </p>
                </div>
                <div className={classNames(listWrapLink)}>
                  <ButtonSecondary anchor href={document.downloadUrl} download>
                    Stáhnout soubor
                  </ButtonSecondary>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};

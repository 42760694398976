import { GetServerSideProps } from "next";
import merge from "deepmerge";
import { toCamelCase } from "@utils/toCamelCase";
import { BACKEND_BASE_URL, getAPIData } from "@utils/api";
import dayjs from "dayjs";
import "dayjs/locale/en";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { siteSection as siteSectionCore } from "@components/core";
import { siteSection as siteSectionWebHistory } from "@components/webHistory";
import { siteSection as siteSectionWebTechnology } from "@components/webTechnology";
import { siteSection as siteSectionWebCareer } from "@components/webCareer";
import { siteSection as siteSectionWebArchive } from "@components/webArchive";
import { siteSection as siteSectionForMedia } from "@components/webForMedia";
import { siteSection as siteSectionCtCouncil } from "@components/webCtCouncil";
import { siteSection as siteSectionPeople } from "@components/webPeople";
import { siteSection as siteSectionContacts } from "@components/webContacts";
import { siteSection as siteSectionFaq } from "@components/webFaq";
import { siteSection as siteSectionViewership } from "@components/webViewership";
import { siteSection as siteSectionLawsAndRules } from "@components/webLawsAndRules";

export const registeredSiteSections: any[] = [
  siteSectionWebCareer,
  siteSectionWebHistory,
  siteSectionWebTechnology,
  siteSectionWebArchive,
  siteSectionForMedia,
  siteSectionCtCouncil,
  siteSectionPeople,
  siteSectionContacts,
  siteSectionFaq,
  siteSectionViewership,
  siteSectionLawsAndRules,

  // core web musi byt posledni
  siteSectionCore,
];

export const getPageType = (page: any) => {
  if (!page?.meta) return "";
  const nameParts = page.meta.type.split(".");
  const pageSection = getPageSiteSection(page);
  const sitePageType = pageSection.pageTypes.find(
    (sectionPageType: any) => sectionPageType.id == nameParts[1]
  );
  const pageType = sitePageType
    ? sitePageType
    : siteSectionCore.pageTypes.find((pt) => pt.id == nameParts[1]);
  return pageType ? pageType.component : "";
};

export interface IAPIPagesResponse {
  items: {
    id: number;
    title: string;
    meta: {
      detail_url: string;
      first_published_at: string;
      html_url: string;
      show_in_menus: boolean;
      slug: string;
      type: string;
    };
  }[];
  meta: {
    total_count: number;
  };
}

export interface IPageBase {
  id: number;
  meta: {
    type: string;
    detailUrl: string;
    htmlUrl: string;
    slug: string;
    showInMenus: boolean;
    firstPublishedAt: string;
  };
  title: string;
  headerImage?: {
    meta?: {
      downloadUrl?: string | null;
    };
  };

  path: string;
  slugs: string[];
  siteSection: string;
}

export const pageHtmlUrl2path = (htmlUrl: string): string => {
  const pathTrimString = "/pages/";
  const pathTrimStringIndex = htmlUrl.indexOf(pathTrimString);
  const trimIndex = pathTrimStringIndex + pathTrimString.length;
  return "/" + htmlUrl.slice(trimIndex, -1);
};

export const getPageSiteSection = (page: any) => {
  const siteSection = registeredSiteSections.find((section) => section.isThisSection(page));
  return siteSection ? siteSection : siteSectionCore;
};

export const filterPagesBySection = (sectionId: string, allPages: IPageBase[]): IPageBase[] => {
  const section = registeredSiteSections.find((section) => section.sectionId == sectionId);
  return allPages.filter((page) => section.isThisSection(page));
};

// filtrace vsech stranek podle nastaveni v environment
const filterPagesByEnvironment = (pages: IPageBase[]): IPageBase[] => {
  const allowedPaths = !!process.env?.EXPORT_ALLOWED_PAGE_PATHS
    ? process.env.EXPORT_ALLOWED_PAGE_PATHS.split(",")
    : false;

  const isAllowed = (pagePath: string) => {
    if (allowedPaths == false) return true;
    for (let i = 0; i < allowedPaths.length; i++) {
      const allowedPath = `/${allowedPaths[i]}`;
      if (pagePath.startsWith(allowedPath)) return true;
    }
    return false;
  };

  return pages.filter((page: IPageBase) => isAllowed(page.path));
};

// filtrace allPages (priprava na pripadnou fitraci podle vice parametru)
const filterAllPages = (pages: IPageBase[]): IPageBase[] => {
  let filteredPages = pages;
  filteredPages = filterPagesByEnvironment(filteredPages);
  return filteredPages;
};

// uprava base page itemu
export const updatePageBaseItem = (item: any): IPageBase => {
  const path = pageHtmlUrl2path(item.meta.htmlUrl);
  const slugs = path.split("/").filter((slug) => !!slug);
  const pageItem: IPageBase = {
    ...item,
    path: path,
    slugs: slugs ? slugs : [],
  };
  pageItem.meta.type = toCamelCase(pageItem.meta.type);

  // pridani sekce
  pageItem.siteSection = getPageSiteSection(pageItem).sectionId;

  return pageItem as IPageBase;
};

export const getAllPages = async (): Promise<IPageBase[]> => {
  const data = await getAPIData("pages");
  const pages = data.items.map((item: any) => updatePageBaseItem(item));
  return filterAllPages(pages);
};

export const getPageBySlugs = async (slugs: string[], pages: IPageBase[] | undefined = undefined) => {
  if (pages == undefined) pages = await getAllPages();
  return pages.find((page) => page.slugs.join("/") == slugs.join("/"));
};

export const getPageById = async (id: undefined | null | string | number) => {
  if (!id) return undefined;
  const pages = await getAllPages();
  return pages.find((page) => page.id == (id as number));
};

export const getPageDetail = async (pageBase: IPageBase | undefined) => {
  if (pageBase) {
    const data = await getAPIData("pages", pageBase.id);
    const page = merge(data, pageBase);
    const updatedPage = await updateDetailPageRecursive(page);
    return updatedPage;
  }
  return null;
};

export const updateDetailPageRecursive = async (data: any) => {
  if (typeof data === "object" && data !== null) {
    // iterace pres properties
    for (let [key, value] of Object.entries(data)) {
      // absolutni URL
      if (key == "downloadUrl") {
        // dal se resi rewritem pres middleware.ts
        value = `/api/media/${data.mediaPath}/`
      }

      // linky
      if (key === "link" && Array.isArray(value) && value.length == 1) {
        const newValue = value[0] as any;
        if (newValue.type === "external") {
          newValue.url = newValue.value;
        } else {
          const linkedPage = await getAPIData("pages", newValue.value);
          const linkedPageUrl = linkedPage?.meta?.htmlUrl;
          newValue.url = linkedPageUrl ? pageHtmlUrl2path(linkedPageUrl) : "ERROR";
        }
        value = newValue;
      }

      const newData = await updateDetailPageRecursive(value);
      data[key] = newData;
    }

    // menuTitle
    if (data?.title && !data?.menuTitle) {
      data["menuTitle"] = data?.menuTitleCustom ? data.menuTitleCustom : data.title;
    }

    return data;
  } else {
    return data;
  }
};

export type PageProps = {
  page: any;
  allPages: IPageBase[];
};

export const getServerSideProps: GetServerSideProps<PageProps> = async ({ params, res }) => {
  const slugs = !!params?.slugs ? params.slugs : [];
  const allPages = await getAllPages();
  const pageBase = params && (await getPageBySlugs(slugs as string[]));
  const page = await getPageDetail(pageBase);

  //res.setHeader("Cache-Control", "public, s-maxage=3600, stale-while-revalidate=300");
  //dayjs.extend(localizedFormat);
  //const lastModified = dayjs().locale("en").format("ddd, DD MMM YYYY HH:mm:ss") + " GMT";
  //res.setHeader("Last-Modified", lastModified);

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      allPages: allPages,
      page: page,
    },
  };
};

export const createBreadcrumbs = (page: any, allPages: any) => {
  const breadcrumbsItems = [
    {
      label: page?.menuTitle ? page.menuTitle : page.title,
      url: page.path,
    },
  ];
  const slugs = [...page.slugs];
  page.slugs.forEach(() => {
    slugs.pop();
    const nextPage = allPages.find((p: any) => JSON.stringify(p.slugs) === JSON.stringify(slugs));
    nextPage &&
      breadcrumbsItems.push({
        label:  nextPage?.menuTitle ?  nextPage.menuTitle : nextPage.title,
        url: nextPage.path,
      });
  });
  return breadcrumbsItems.reverse();
};

export const getPageChildrens = (page: any, allPages: any[], depth: undefined | number = 1) => {
  const pageDepth = page.slugs.length;
  return allPages.filter((item: any) => {
    if (item.slugs.slice(0, page.slugs.length).join("*") != page.slugs.join("*")) return false;
    const depthDiff = item.slugs.length - pageDepth;
    return depthDiff > 0 && depthDiff <= depth;
  });
};

export const getSectionRootPage = (page: any, allPages: any[]) => {
  const section = getPageSiteSection(page);
  const sectionPages = filterPagesBySection(section.sectionId, allPages);
  return sectionPages.find((page: any) => page.slugs.length == 1);
};

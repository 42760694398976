import { useState, Fragment } from "react";
import classNames from "classnames";
import { useForm, Form, ValidatedTextInput, ValidatedCheckbox } from "@czechtv/form";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import { MessageSuccess, MessageFailed } from "@czechtv/components";
import { FileInput, MB } from "@components/reusables/Form/inputs/FileInput/FileInput";
import { sendForm } from "@utils/forms";
import {
  posBoxFormClose,
  posBoxFormGDPR,
  posBoxFormGDPROpener,
  posBoxFormHalf,
  posBoxFormHalfWrap, posBoxFormInfo, posBoxFormInfoSpan, posBoxFormLabel,
  posBoxFormRadio,
  posBoxFormRadioInput, posBoxFormRadioInputLabel,
  posBoxFormRadioParagraph,
  posBoxFormRadioParagraphSpan,
  posBoxFormWrap
} from "@components/webCareer/PageTypes/UneditablePage/pages/CT24AcademyPage/components/ContactForm/ContactForm.css";

interface FormValues {
  name: string;
  interest: string;
  phone: string;
  email: string;
  biography: File[];
  motivationalLetter: null | File[];
  gdpr: string;
}

// typ pozadavku
const requestTypeInputs = [
  {
    label: "Stáž",
    id: "requestTypeInternship",
    value: "Stáž",
  },
  {
    label: "Worskhop",
    id: "requestTypeWorkshop",
    value: "Worskhop",
  },
];

export const ContactForm = () => {

  const [disabled, setDisabled] = useState(false);
  const [openGdpr, setOpenGdpr] = useState(false);

  // form
  const defaultInterest = requestTypeInputs[0].value;
  const form = useForm<FormValues>({
    defaultValues: { interest: defaultInterest },
  });
  const {
    register,
    setValue,
    getValues,
    formState: { isSubmitting, isValid },
    reset,
  } = form;

  // odeslani formulare
  const onFormSubmit = async (data: FormValues) => {
    const url = "/vse-o-ct/api/forms/ct24-academy-form";

    try {
      await sendForm(url, data);
      resetForm();
    } catch (error: any) {
      console.log(error);
      throw new Error(error.message);
    } finally {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, 10000);
    }
  };

  const resetForm = () => {
    reset();
  };

  return (
    <Form
      form={form}
      onSubmit={onFormSubmit}
      successState={FormSuccess}
      errorState={FormError}
      className={classNames(posBoxFormWrap)}
    >
      {!disabled ? (
        <>
          <div className={classNames(posBoxFormHalfWrap)}>
            <div className={classNames(posBoxFormHalf)}>
              <ValidatedTextInput
                {...register("name", {
                  required: "Zadejte prosím jméno a příjmení",
                })}
                autoComplete="name"
                label={{
                  required: true,
                  title: "Jméno a příjmení",
                }}
                maxWidth={1250}
                showValidationIcon
              />
              <ValidatedTextInput
                {...register("phone", {
                  required: "Zadejte prosím telefon",
                })}
                autoComplete="phone"
                label={{
                  required: true,
                  title: "Telefon",
                }}
                type="tel"
                maxWidth={1250}
                showValidationIcon
              />
            </div>
            <div className={classNames(posBoxFormHalf)}>
              <div className={classNames(posBoxFormRadio)}>
                <p className={classNames(posBoxFormRadioParagraph)}>
                  Co Vás zajímá? <span className={classNames(posBoxFormRadioParagraphSpan)}>*</span>
                </p>
                {requestTypeInputs.map((item: any) => (
                  <Fragment key={item.id}>
                    <input
                      type="radio"
                      value={item.value}
                      {...register("interest")}
                      defaultChecked={item.value == defaultInterest}
                      className={classNames(posBoxFormRadioInput)}
                      id={item.id}
                    />
                    <label className={classNames(posBoxFormRadioInputLabel)} htmlFor={item.id}>{item.label}</label>
                  </Fragment>
                ))}
              </div>
              <ValidatedTextInput
                {...register("email", {
                  required: "Zadejte prosím e-mail",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Zadaný email je ve špatném formátu",
                  },
                })}
                label={{ title: "E-mail", required: true }}
                maxWidth={1250}
                showValidationIcon
              />
            </div>
          </div>
          <div className={classNames(posBoxFormHalfWrap)}>
            <div className={classNames(posBoxFormHalf)}>
              <label className={classNames(posBoxFormLabel)} htmlFor="biography">
                Nahrajte Váš životopis<span>*</span>
              </label>
              <FileInput
                {...register("biography", {
                  required: {
                    value: true,
                    message: "Nahrajte prosím životopis",
                  },
                })}
                id="biography"
                required={true}
                accept=".doc,.docx,.pdf"
                maxSize={3 * MB}
              />
              <p>Lze vložit PDF nebo DOC formát do velikosti 3 Mb.</p>
            </div>
            <div className={classNames(posBoxFormHalf)}>
              <label className={classNames(posBoxFormLabel)} htmlFor="motivationalLetter">
                Motivační dopis
              </label>
              <FileInput
                {...register("motivationalLetter")}
                id="motivationalLetter"
                required={false}
                accept=".doc,.docx,.pdf"
                maxSize={3 * MB}
              />
              <p>Lze vložit PDF nebo DOC formát do velikosti 3 Mb.</p>
            </div>
          </div>
          <p>
            Souhlasím se zpracováním{" "}
            <a
              onClick={() => {
                setOpenGdpr(!openGdpr);
              }}
              className={classNames(posBoxFormGDPROpener)}
            >
              osobních údajů
            </a>{" "}
          </p>
          <div className={classNames(posBoxFormGDPR, openGdpr == false ? null : "visible")}>
            <a
              className={classNames(posBoxFormClose)}
              onClick={(e) => {
                e.preventDefault();
                setOpenGdpr(false);
              }}
            ></a>
            <p>
              Uděluji souhlas ČESKÉ TELEVIZI, se sídlem: Praha 4 – Kavčí Hory, PSČ 140 70, IČO:
              00027383 , aby mě informovala o obdobných pracovních nabídkách
            </p>
            <p>Dobrovolný souhlas se zpracováním osobních údajů po dobu 3 let:</p>
            <p>
              Tímto uděluji ČESKÉ TELEVIZI, se sídlem: Praha 4 – Kavčí Hory, PSČ 140 70, IČO:
              00027383, souhlas se zpracováním osobních údajů, které byly uvedeny ve vyplněném
              dotazníku, v mém životopise, motivačním dopise, v elektronické poště, aby ČESKÁ
              TELEVIZE mohla uchovávat mé osobní údaje a oslovit mě s obdobnou budoucí nabídkou
              zaměstnání, a to po dobu 3 let od udělení tohoto souhlasu. Uděluji tímto souhlas podle
              čl. 6 odst. 1. písm. a) v souvislosti se zpracováním osobních údajů a podle čl. 9
              odst. 2. písm. a) v souvislosti se zpracováním osobních údajů zvláštní kategorie,
              Nařízení Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016 o ochraně
              fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto
              údajů a o zrušení směrnice 95/46/ES (GDPR). Osobní údaje budou zpřístupněny výhradně
              oprávněným zaměstnancům ČESKÉ TELEVIZE, a to pouze v míře nezbytné pro účely
              zpracování. Jsem si vědom, že mám právo kdykoliv odvolat tento souhlas. V případě, že
              bude tento souhlas odvolán, je další zpracování výše uvedených osobních údajů nemožné.
            </p>
          </div>
          <div>
            <div style={{ height: "50px" }}>
              <ValidatedCheckbox
                {...register("gdpr", {
                  required: {
                    value: true,
                    message:
                      "Pro odeslání formuláře musíte souhlasit se zpracováním osobních údajů",
                  },
                })}
                item={{ id: "1", title: "ano" }}
              />
            </div>
            <div className={classNames(posBoxFormHalf, "alignRight")}>
              <ButtonPrimary
                disabled={isSubmitting || !isValid || disabled}
                type="submit"
                waiting={isSubmitting}
              >
                Odeslat
              </ButtonPrimary>
            </div>
          </div>
          <p className={classNames(posBoxFormInfo)}>
            <span className={classNames(posBoxFormInfoSpan)}>*</span> Pro úspěšné odeslání formuláře je potřeba vyplnit všechna pole označená
            hvězdičkou.
          </p>
        </>
      ) : null}
    </Form>
  );
};

const FormError = ({ error }: { error: unknown }) => (
  <MessageFailed
    className="posBoxFormWrapMsg"
    description={"Bohužel se zprávu nepodařilo odeslat. Zkuste to prosím později."}
  />
);

const FormSuccess = () => (
  <MessageSuccess
    className="posBoxFormWrapMsg"
    description={"Formulář byl úspěšně odeslán, budeme Vás kontaktovat na uvedeném e-mailu."}
  />
);

// https://yet-another-react-lightbox.com/documentation
import React, { useRef, useEffect, useState } from "react";
import { Slide, useLightboxState, CustomSlide } from "yet-another-react-lightbox";

import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import { VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";

import classNames from "classnames";

import {
  lightboxCustomItem,
  lightboxCustomItem_p,
  lightboxCustomItem_span,
} from "./CustomSlideComponent.css";

const containerWidthDef = 1296;
const containerHeightDef = 852;

declare module "yet-another-react-lightbox" {
  export interface CustomSlide extends GenericSlide {
    type: "custom-slide";
    downloadUrl: any;
    title: string;
    item: any;
  }

  interface SlideTypes {
    "custom-slide": CustomSlide;
  }
}

export const isCustomSlide = (slide: Slide) => slide.type == "custom-slide";

export const CustomSlideComponent = ({
  slide,
  slides,
  rect,
}: {
  slide: CustomSlide;
  slides: CustomSlide[];
  rect: any;
}) => {
  const index = slides.findIndex((el) => el === slide);
  const { currentIndex } = useLightboxState();

  let imageWidth = 0;
  let imageWidthRecalc = 0;
  let imageHeight = 0;
  const [widthContainer, setWidthContainer] = useState(containerWidthDef);
  const [heightContainer, setHeightContainer] = useState(containerHeightDef);
  const containerForImage = useRef<HTMLDivElement>(null);
  const containerCaption = useRef<HTMLDivElement>(null);

  const ratioContainer = containerWidthDef / containerHeightDef;
  let ratioImage = 0;
  let captionWidth = 1;

  useEffect(() => {
    const updateWidth = () => {
      if (containerForImage.current) {
        // Použití getBoundingClientRect pro získání rozměrů elementu
        const dimensions = containerForImage.current.getBoundingClientRect();
        setWidthContainer(dimensions.width); // Nastavení šířky
        setHeightContainer(dimensions.height); // Nastavení výšky
      }
    };
    window.addEventListener("resize", updateWidth);
    // Počáteční aktualizace šířky
    updateWidth();
    // Odebrání posluchače při odmontování komponenty
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  !!slide.item?.video
    ? (imageWidth = 0)
    : ((imageWidth = slide.item.image.width),
      (imageHeight = slide.item.image.height),
      (ratioImage = slide.item.image.width / slide.item.image.height),
      ratioImage <= ratioContainer
        ? ((imageWidthRecalc = (heightContainer / imageHeight) * imageWidth),
          (captionWidth = imageWidthRecalc / widthContainer))
        : (captionWidth = 1));

  return index === currentIndex ? (
    <div
      style={{ width: rect.width, height: rect.height }}
      className={classNames(lightboxCustomItem)}
      ref={containerForImage}
    >
      {!!slide.item?.video ? (
        <VideoBlock videoID={slide.item.video} />
      ) : (
        <ImageBlock
          image={{
            downloadUrl: slide.item.image.downloadUrl,
            width: slide.item.image.width,
            height: slide.item.image.height,
            title: slide.item.image.title,
          }}
        />
      )}

      <p
        className={classNames(lightboxCustomItem_p)}
        ref={containerCaption}
        style={{ width: captionWidth * 100 + "%" }}
      >
        {!!slide.item.title ? <span>{slide.item.title} </span> : null}
        <span className={classNames(lightboxCustomItem_span)}>
          {currentIndex + 1} / {slides.length}
        </span>
      </p>
    </div>
  ) : null;
};

import type { ImageLoader } from "next/image";

export const isStaticImportImage = (src: any) => {
  try {
    return !src.startsWith("/api/media/");
  } catch (e) {
    console.log("Bad image", src);
    return true;
  }
};

const dummyImageLoader: ImageLoader = ({ src, width, quality }) => {
  return src;
};

export const djangoImageUrlResizerImageLoader: ImageLoader = ({ src, width, quality }) => {
  try {
    const filePath = src.startsWith("/api/media/")
      ? src.replace("/api/media/", "/api/resized-image/")
      : src;
    const finalPath = filePath != src ? `/vse-o-ct${filePath}/geometry/${width}/format/webp/` : src;
    return finalPath;
  } catch (err) {
    console.log("-------------------------------------");
    console.log(err);
    console.log("-------------------------------------");
    return src;
  }
};

export const mediaServerImageLoader: ImageLoader = ({ src, width }) => {
  return mediaServerUrl(src, width ? `width-${width}` : undefined);
};

export const mediaServerUrl = (
  src: string,
  formatString: undefined | string | "original" = undefined
) => {
  const url = `/vse-o-ct${src}${formatString ? `/format/${formatString}` : ""}`.replace("//", "/");
  return url;
};

export const imageMediaServerUrl = (
  src: string,
  width: undefined | number | "original" = undefined
) => {
  let formatString: undefined | string = width ? (width as string) : undefined;
  formatString =
    formatString && formatString !== "original" ? `width-${formatString}` : formatString;
  return mediaServerUrl(src, formatString);
};

const registeredImageLoaders = {
  dummy: dummyImageLoader,
  djangoImageUrlResizer: djangoImageUrlResizerImageLoader,
};

type TImageLoaderName = keyof typeof registeredImageLoaders;

export const getImageLoader = (
  name: TImageLoaderName | null | undefined
): ImageLoader | undefined => {
  return registeredImageLoaders["djangoImageUrlResizer"];
};

import { MediaList } from "@components/blocks/MediaList/MediaList";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import classNames from "classnames";
import { mainContainer, mainContainer_h2, btnContainer } from "./MediaListStrip.css";
interface MediaListStripProps {
  media: any[];
  detailLink: {
    url: string;
    label: string;
  };
  displayedCount?: number;
  cropAspectRatio?: undefined | ASPECT_RATIO;
  headline?: undefined | string;
}

export const MediaListStrip = ({
  media,
  detailLink,
  cropAspectRatio = undefined,
  displayedCount = undefined,
  headline = undefined,
}: MediaListStripProps) => {
  return (
    <div className={classNames(mainContainer)}>
      {/** headline */}
      {headline ? <h2 className={classNames(mainContainer_h2)}>{headline}</h2> : null}

      {/** media */}
      <MediaList media={media} cropAspectRatio={cropAspectRatio} displayedCount={displayedCount} />
      {/** detail link */}
      {/*
      <div  className={classNames(btnContainer)}>
        <ButtonPrimary anchor href={detailLink.url} >{detailLink.label}</ButtonPrimary>
      </div>
      */}
    </div>
  );
};

import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcMy7Do4iZu6y6tO0qYd9t0HJpbSmuoatNJB%2FpF%2F8SG2oVuvd%2FDnA8Bo9AurZXdb1zrAeL3vps24Xm2%2FdevR92a%2BX9Sdgm292o62TdfefHplgUOLcbNsP68Xs7dNaGjyZVcv2unbBibAj58Mj6dd06zev5QdGPz%2FPm5Af21m7X75Nu8DdPNJ%2FbHyCtBrBeScArx6TRU9pRX%2FDL%2BW%2FVc2jWvIVJExhaJSGS%2BI%2BoUX9CajM%2BkJVyjSXmFFApn2iHEKSlSAVSGjmfUQkldYlhmlJhLeclqMAixJOrqJV7YspVGBySRvngIzCoiw34bYbcIoypVGYm3C2MdR2GawuwCvD36%2FW4BFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmXXPeK%2BAFFhJbJIXBaffIXEfYyLOrOFru4yjLjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabmDzGAQsHxBZaZlD9eAh1eJOvYGTqTiKcesspo5WUHPEcnx6pRJnjUZwQ5Kp9hME0LIKsglx6k9HQ4z2kTnEklZiRtgrOpeVntvA05l142fz1vQ86nZjg%2Fs0wBMU5GxGNG0jbk4ozkdx2XgOKQdIWCuAQU56QpFcQloElKGyfstAQ0TRCl3OaczS7kmgu5m1Qr15eJct2K81QrF8tU5LjbC7k2vZEi1arKcHc9VxSnOlW55MV5ypOE6%2BRztYoj1dCpTrl5gmmk%2BlOdTO7uqwDl5jA4XAdAL043AciK0%2FsAhsRpF8BqcboNYKXaLoCTavsAXqo9BCik2mOAUqodApRS7SlAJdWeA0%2B24THpAIhCjzAAkhAk4mOhSCYAGiFJNgBaKekCoJOSPsB0P2mno0nz3DYd%2F8d0jUpfGzFsqWAPerkdHJdp8rz4C6uv6Qr05gC4OQBtDiCjqvJ27mxnsnZ1ABndJNNuNI3t9vcfw7HEwm8LAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fcomponents.css.ts.vanilla.css%22%2C%22source%22%3A%22W29iamVjdCBPYmplY3RdOmhvdmVyIHsKICB0cmFuc2l0aW9uOiBhbGwgdmFyKC0tXzE5bXJoYWEyNykgdmFyKC0tXzE5bXJoYWEyNik7CiAgYmFja2dyb3VuZC1wb3NpdGlvbjogcmlnaHQgMHB4IGNlbnRlcjsKfQoueDdhMHdtMCB7CiAgcGFkZGluZzogMCAwIHZhcigtLV8xOW1yaGFhMW8pIDA7CiAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFxKSAwIHZhcigtLV8xOW1yaGFhMXEpIDA7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwogIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1fMTltcmhhYTFlKTsKfQoueDdhMHdtMSB7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc2OHB4KSB7CiAgLng3YTB3bTAgewogICAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFzKSAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDEwMjRweCkgewogIC54N2Ewd20wIHsKICAgIG1hcmdpbjogMCAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fcore%2FPageTypes%2FDocumentsPage%2FDocumentsPage.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B1XwY7aMBC98xVT9bKRapRkYWGzl576GysTD8HdxI7sYQmt%2BPfKTgIkEKCrtoftcojEjMd582bmxR4%2FR%2BESl5iF8HMEUHCTSZVAWFbA16ThlZs7xp6jx8KsOI9s4M1Po91o3EZGPrLkQkiV%2BdCnEUAuLTFL2xwZbUtMQGmFziGkLXO%2BTWCZo1%2BZ8TLpv0cFzuNWsI1xfvd0pu9rS3K5ZalWhIrqXZglbqgDKvaglloRs%2FIHJhCNo3hqsKixKWQrlNmKnKM1%2B9VLXsh8ewLI49lIQaukYQBgoY1Ak0BUVmB1LgWQ4cqW3KAityBdG6tNAqWWitB0spfKo2hJ0Gty%2Fw80sVJbSVKrBAzmnORrbV7oymXkqa4BOJNz%2BZc3IQuevmRGr5XoJRLPAuibHgII7Zd2t1Tn2vxOVMXsigu9uTXGYeW5zBSThIVNIMWWnQaC4UKu7UkNdHC2Aw7hhBUxgak2vKahJdPnlMDnb%2F4Hn2RRakO8rtKBq%2F4bX4IaVJth3x%2FP%2FYLj1oe46f%2B2vSbzZh6QCA2zJU%2BPx8T33KZdG4bdJk5W%2BhWNb%2BWbq%2Fum4r6htkOlHaYzv0bnY9BLn6eu7%2F%2FX%2FO8%2FNOxDw%2FZkDvfV%2FO%2FLVCOg3X23wVUFu3%2BPCnaWjKtF6A%2F3uxS3P0LN5FT3ZmdlL74uepNhWHX71ideRrrcN3tjWmgiXQxM8GGYOtCnp9AHBHt2g2JP%2Fy34h%2B4VAMLTaAg7h6ej4JkPHlb2gles%2BfbMHxqsh25i3RNaB%2BTZFG5R2ZObhr8rCGkwbT5KenNByC9eNS5dW66XYDf6WqCQHGxqEBVwJeCukKplKArjSVkFntLeWHS6Kx7vzwP90RgfOXqazsJxeBR3dXp2o90vpJ028ScOAAA%3D%22%7D"
export var faqCategoryHeadline = '_10fefeg4';
export var faqSelect = '_10fefeg7';
export var faqSubCategoryHeadline = '_10fefeg5';
export var faqTopTags = '_10fefeg0';
export var faqTopTagsList = '_10fefeg1';
export var faqTxtContent = '_10fefeg6';
export var topTagsLinkPrimary = '_10fefeg2';
export var topTagsLinkSecondary = '_10fefeg3';
import Link from "next/link";
import { SmartImage2 } from "@components/reusables/SmartImage/SmartImage2";
import { PageContainer } from "@components/webTechnology/PageTypes/UneditablePage/pages/applications/PageContainer";
import { ApplicationsLayout } from "./../../../../../Layouts/ApplicationsLayout/ApplicationsLayout";
import { Content } from "@czechtv/components";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import {
  mediaMinTablet768,
  mediaMinTablet640,
  Red_base,
  Body18Medium,
  ButtonTransitionDuration,
} from "@czechtvPackagesOverrides/styles/fromCTV/index";
import { GRID_GAP, ANIMATION_BEZIER_HOVER } from "@consts/consts";
import { PAGES } from "./../../../../../Layouts/ApplicationsLayout/applicationsGlobalMenuConfig";
import { buttonPrimary } from "@czechtvPackagesOverrides/styles/utilities/buttonPrimary";
import { HeaderH1, HeaderH2 } from "@czechtvPackagesOverrides/styles/utilities/headings";

import imageTop from "./images/aplikace_hp_top_x2.png";
import imageArticle1 from "./images/aplikace_hp_mobilni_aplikace_x2.png";
import imageArticle2 from "./images/aplikace_hp_hbbtv_x2.png";
import imageArticle3 from "./images/aplikace_hp_smart_x2.png";
import imageIcon1 from "./images/aplikace_hp_icon_apple_x2.png";
import imageIcon2 from "./images/aplikace_hp_icon_androidtv_x2.png";
import imageIcon3 from "./images/aplikace_hp_icon_samsung_x2.png";
import imageIcon4 from "./images/aplikace_hp_icon_lg_x2.png";
import imageIcon5 from "./images/aplikace_hp_icon_philips.svg";
import imageIcon6 from "./images/aplikace_hp_icon_hisense.svg";

const useStyles = createUseStyles({
  header: {
    margin: `${GRID_GAP * 3}px auto ${GRID_GAP * 4}px auto`,
    position: "relative",
    textAlign: "center",
    color: "#FFFF",
    display: "flex",
    flexDirection: "column",
    padding: `0px 0px ${GRID_GAP * 3}px 0px`,
    [mediaMinTablet640]: {
      margin: `${GRID_GAP * 6}px auto ${GRID_GAP * 10}px auto`,
      padding: "0px",
    },
    "&::before": {
      position: "absolute",
      background: `radial-gradient(115% 115% at 50% 0%, #0A3368 0%, #001830 100%);`,
      content: '""',
      left: "-100vw",
      bottom: "0%",
      width: "300vw",
      height: "150vh",
      zIndex: "-1",
      [mediaMinTablet640]: {
        bottom: "23%",
        height: "100vh",
      },
      "@media (min-width: 640px) and (max-height: 640px)": {
        height: "160vh",
      },
      "@media (min-width: 640px) and (max-height: 430px)": {
        height: "350vh",
      },
      "@media (min-width: 640px) and (max-height: 200px)": {
        height: "1050vh",
      },
      "@media (min-width: 640px) and (max-height: 100px)": {
        height: "10000vh",
      },
    },
    "& h1": {
      ...HeaderH1,
      color: `#FFFFFF`,
      order: "1",
    },
  },
  headerTxt: {
    maxWidth: "728px",
    margin: `${GRID_GAP * 2}px auto 0px auto`,
    order: 3,
    ...Body18Medium,
    lineHeight: "1.628rem",
    [mediaMinTablet640]: {
      order: "2",
      margin: `0px auto ${GRID_GAP * 7}px auto`,
    },
    "& a": {
      color: "#FFF",
      textDecoration: "underline",
      transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    },
    "& a:hover": {
      color: Red_base,
      transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    },
  },
  headerImage: {
    maxWidth: `788px`,
    marginLeft: `auto`,
    marginRight: `auto`,
    order: 2,
    [mediaMinTablet640]: {
      order: 3,
    },
  },
  articles: {
    marginTop: `${GRID_GAP * 4}px`,
    [mediaMinTablet640]: {
      marginTop: `${GRID_GAP * 6}px`,
    },
    [mediaMinTablet768]: {
      marginTop: `${GRID_GAP * 13}px`,
    },
  },
  articleLeft: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    gap: `0px ${GRID_GAP * 5}px`,
    marginBottom: `${GRID_GAP * 8}px`,
    [mediaMinTablet640]: {
      flexWrap: "nowrap",
      marginTop: `${GRID_GAP * 6}px`,
    },
    [mediaMinTablet768]: {
      marginTop: `${GRID_GAP * 13}px`,
    },
  },
  articleLeftTxt: {
    width: "100%",
    order: "2",
    textAlign: "center",
    [mediaMinTablet640]: {
      order: "1",
      width: "43%",
      textAlign: "left",
    },
    "& h2": {
      ...HeaderH2,
      marginTop: `${GRID_GAP * 2}px`,
      [mediaMinTablet640]: {
        marginTop: `0px`,
      },
    },
    "& p": {
      marginBottom: `${GRID_GAP * 2}px`,
      ...Body18Medium,
      color: "#393948",
      [mediaMinTablet640]: {
        marginBottom: `${GRID_GAP * 3}px`,
      },
      "& a": {
        color: "#393948",
        textDecoration: "underline",
        transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
      },
      "& a:hover": {
        color: Red_base,
        transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
      },
    },
  },
  articleLeftImg: {
    order: "1",
    width: "100%",
    [mediaMinTablet640]: {
      order: "2",
      width: "57%",
    },
  },
  articleRight: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    gap: `0px ${GRID_GAP * 5}px`,
    marginBottom: `${GRID_GAP * 8}px`,
    [mediaMinTablet640]: {
      flexWrap: "nowrap",
      marginTop: `${GRID_GAP * 6}px`,
    },
    [mediaMinTablet768]: {
      marginTop: `${GRID_GAP * 13}px`,
    },
  },
  articleRightTxt: {
    width: "100%",
    order: "2",
    textAlign: "center",
    [mediaMinTablet640]: {
      order: "2",
      width: "43%",
      textAlign: "left",
    },
    "& h2": {
      ...HeaderH2,
      marginBottom: `${GRID_GAP * 2}px`,
      [mediaMinTablet640]: {
        marginTop: `${GRID_GAP * 5}px`,
      },
      "& span": {
        color: Red_base,
      },
    },
    "& p": {
      marginBottom: `${GRID_GAP * 2}px`,
      ...Body18Medium,
      color: "#393948",
      [mediaMinTablet640]: {
        marginBottom: `${GRID_GAP * 3}px`,
      },
      "& a": {
        color: "#393948",
        textDecoration: "underline",
        transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
      },
      "& a:hover": {
        color: Red_base,
        transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
      },
    },
  },
  articleRightImg: {
    order: "1",
    width: "100%",
    [mediaMinTablet640]: {
      order: "1",
      width: "57%",
    },
  },
  articleLast: {
    alignItems: "flex-start",
  },
  articleIconList: {
    maxWidth: `360px`,
    listStyleType: "none",
    margin: `0px 0px ${GRID_GAP * 3}px 0px`,
    padding: `0px 0px 0px 0px`,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: `${GRID_GAP * 3}px ${GRID_GAP * 4}px`,
    justifyContent: "center",
    [mediaMinTablet640]: {
      justifyContent: "flex-start",
    },
  },
  linkLikeButton: {
    ...buttonPrimary,
  },
});

export const ApplicationsPage: React.FC = () => {
  const classes = useStyles();

  return (
    <PageContainer
      title="Aplikace České televize"
      description="Česká televize, to už dávno není jen klasické televizní vysílání. S našimi chytrými aplikacemi pro online sledování TV nemůžete sáhnout vedle."
    >
      <ApplicationsLayout>
        <Content>
          <header className={classNames(classes.header)}>
            <h1>Aplikace České televize</h1>
            <p className={classNames(classes.headerTxt)}>
              Česká televize, to už dávno není jen klasické televizní vysílání. S našimi{" "}
              <Link href={PAGES.applicationsMobilePage.url}>mobilními aplikacemi</Link> nemůžete
              sáhnout vedle. A pokud dáváte přednost televiznímu přijímači, můžete kromě služeb{" "}
              <Link href={PAGES.applicationsHbbTVPage.url}>červeného tlačítka</Link> vyzkoušet také
              aplikace pro <Link href={PAGES.applicationsSmartTVPage.url}>chytré televizory</Link>.
            </p>
            <div className={classNames(classes.headerImage)}>
              <SmartImage2 src={imageTop.src} alt="Televizní obrazovka" width={788} height={376} />
            </div>
          </header>
          <section className={classNames(classes.articles)}>
            <article className={classNames(classes.articleLeft)}>
              <div className={classNames(classes.articleLeftImg)}>
                <SmartImage2
                  src={imageArticle1.src}
                  alt="Mobilní aplikace"
                  width={625}
                  height={416}
                />
              </div>
              <div className={classNames(classes.articleLeftTxt)}>
                <h2>Mobilní aplikace</h2>
                <p>
                  Aplikace České televize mají pro každého něco. Ať už dáváte přednost sledování
                  živého vysílání, filmů a seriálů v aplikaci{" "}
                  <Link href={`${PAGES.applicationsMobilePage.url}#ivysilani`}>iVysílání</Link>,
                  nejnovějším zprávám z domova i ze světa v aplikaci{" "}
                  <Link href={`${PAGES.applicationsMobilePage.url}#ct24`}>ČT24</Link>, sportovním
                  novinkám a přenosům v aplikaci{" "}
                  <Link href={`${PAGES.applicationsMobilePage.url}#ct-sport`}>ČT sport</Link> nebo
                  zábavným a vzdělávacím hrám, které přináší dětský kanál{" "}
                  <Link href={`${PAGES.applicationsMobilePage.url}#ct-sport`}>Déčko</Link>, svět
                  informací a zábavy máte vždy na dosah ruky.
                </p>
                <Link
                  href={`${PAGES.applicationsMobilePage.url}`}
                  className={classNames(classes.linkLikeButton)}
                >
                  Více o mobilních aplikacích
                </Link>
              </div>
            </article>
            <article className={classNames(classes.articleRight)}>
              <div className={classNames(classes.articleRightImg)}>
                <SmartImage2 src={imageArticle2.src} alt="HBBTV" width={625} height={402} />
              </div>
              <div className={classNames(classes.articleRightTxt)}>
                <h2>
                  Služba přes <span>červené tlačítko</span>
                </h2>
                <p>
                  Stěžejní aplikace iVysílání je kromě HbbTV (služba přes červené tlačítko) dostupná
                  i na vybraných platformách výrobců televizorů, v prostředí tzv. Smart TV
                  aplikacích. Stáhněte si televizní aplikaci iVysílání do vašeho chytrého
                  televizoru.
                </p>
                <Link
                  href={`${PAGES.applicationsHbbTVPage.url}`}
                  className={classNames(classes.linkLikeButton)}
                >
                  Více o červeném aplikacích
                </Link>
              </div>
            </article>
            <article className={classNames(classes.articleLeft, classes.articleLast)}>
              <div className={classNames(classes.articleLeftImg)}>
                <SmartImage2
                  src={imageArticle3.src}
                  alt="Mobilní aplikace"
                  width={625}
                  height={402}
                />
              </div>
              <div className={classNames(classes.articleLeftTxt)}>
                <h2>iVysílání v chytrých televizorech</h2>
                <p>
                  Aplikace iVysílání je kromě HbbTV (služba přes červené tlačítko) dostupná i na
                  vybraných platformách výrobců televizorů, v prostředí tzv. Smart TV aplikacích.
                  Stáhněte si televizní aplikaci iVysílání do vašeho chytrého televizoru.
                </p>
                <ul className={classNames(classes.articleIconList)}>
                  <li style={{ width: "110px" }}>
                    <SmartImage2 src={imageIcon2.src} width={110} height={19} alt="Android TV" />
                  </li>
                  <li style={{ width: "104px" }}>
                    <SmartImage2 src={imageIcon3.src} width={104} height={19} alt="Samsung" />
                  </li>
                  <li style={{ width: "63px" }}>
                    <SmartImage2 src={imageIcon4.src} width={63} height={28} alt="LG" />
                  </li>
                  <li style={{ width: "56px" }}>
                    <SmartImage2 src={imageIcon1.src} width={56} height={28} alt="Apple TV" />
                  </li>
                  <li style={{ width: "100px" }}>
                    <SmartImage2 src={imageIcon6.src} width={100} height={19} alt="Hisense" />
                  </li>
                  <li style={{ width: "90px" }}>
                    <SmartImage2 src={imageIcon5.src} width={90} height={17} alt="Philips" />
                  </li>
                </ul>
                <Link
                  href={`${PAGES.applicationsSmartTVPage.url}`}
                  className={classNames(classes.linkLikeButton)}
                >
                  Více o aplikacích pro chytré televize
                </Link>
              </div>
            </article>
          </section>
        </Content>
      </ApplicationsLayout>
    </PageContainer>
  );
};

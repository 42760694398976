import { ButtonSecondary as ButtonSecondaryOriginal } from "@czechtv/components";

export const ButtonSecondary = (props: any) => {
  let updatedProps = { ...props };
  if (!!props?.href) {
    const hrefParts = props.href.split("/");
    if (hrefParts[0] == "" && hrefParts[1] != "vse-o-ct") {
      updatedProps.href = `/vse-o-ct${props.href}`;
    }
  }
  return <ButtonSecondaryOriginal {...updatedProps} styleType="secondary" />;
};

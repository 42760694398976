import { WithSideMenuLayout } from "@components/core/Layouts/WithSideMenuLayout/WithSideMenuLayout";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import { FaqPageContent } from "./FaqPageContent";
import {contentLimit} from "@components/core/PageTypes/FaqPage/FaqPage.css";

export const FaqPage = ({ page, allPages }: any) => {
  return (
    <WithSideMenuLayout page={page} allPages={allPages}>
      <div className={contentLimit}>
        {/** FAQs s navigaci */}
        <FaqPageContent page={page} />

        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? (
          <AttachedDocumentsBlock
            documents={page.attachedDocuments.map((item: any) => item.value)}
          />
        ) : null}
      </div>
    </WithSideMenuLayout>
  );
};

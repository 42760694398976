import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WS2%2FbMAzH7%2FsUPK6AUojUw7Z2222HAcMy7Do4iZu6y6tO0qYd9t0HJpbSmuoatNJB%2FpF%2F8SG2oVuvd%2FDnA8Bo9AurZXdb1zrAeL3vps24Xm2%2FdevR92a%2BX9Sdgm292o62TdfefHplgUOLcbNsP68Xs7dNaGjyZVcv2unbBibAj58Mj6dd06zev5QdGPz%2FPm5Af21m7X75Nu8DdPNJ%2FbHyCtBrBeScArx6TRU9pRX%2FDL%2BW%2FVc2jWvIVJExhaJSGS%2BI%2BoUX9CajM%2BkJVyjSXmFFApn2iHEKSlSAVSGjmfUQkldYlhmlJhLeclqMAixJOrqJV7YspVGBySRvngIzCoiw34bYbcIoypVGYm3C2MdR2GawuwCvD36%2FW4BFDLriK3IrZHK3jJDlhjkmRkKrCGmngDktmXXPeK%2BAFFhJbJIXBaffIXEfYyLOrOFru4yjLjoqHQdGjGWquU2hcViFZSwjuosvhl3YQgHZTAr2sRE5%2FGPNTabmDzGAQsHxBZaZlD9eAh1eJOvYGTqTiKcesspo5WUHPEcnx6pRJnjUZwQ5Kp9hME0LIKsglx6k9HQ4z2kTnEklZiRtgrOpeVntvA05l142fz1vQ86nZjg%2Fs0wBMU5GxGNG0jbk4ozkdx2XgOKQdIWCuAQU56QpFcQloElKGyfstAQ0TRCl3OaczS7kmgu5m1Qr15eJct2K81QrF8tU5LjbC7k2vZEi1arKcHc9VxSnOlW55MV5ypOE6%2BRztYoj1dCpTrl5gmmk%2BlOdTO7uqwDl5jA4XAdAL043AciK0%2FsAhsRpF8BqcboNYKXaLoCTavsAXqo9BCik2mOAUqodApRS7SlAJdWeA0%2B24THpAIhCjzAAkhAk4mOhSCYAGiFJNgBaKekCoJOSPsB0P2mno0nz3DYd%2F8d0jUpfGzFsqWAPerkdHJdp8rz4C6uv6Qr05gC4OQBtDiCjqvJ27mxnsnZ1ABndJNNuNI3t9vcfw7HEwm8LAAA%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fblocks%2FAttachedDocumentsBlock%2FAttachedDocumentsBlock.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61UUW7iMBD95xSjVpXISkZxIAHMz371GpVJJuCtY0eOKc2ukPYse7Q9ySqOgxICpZU2EhGOn968mXkzsxcai3WdmxB%2BTQAKbnZCMQjLd%2FdLuUynb9xMCXmh68LsOacqgG%2BwCJr7zeQ0mXUM1DO8k6PI7J7BKnEQACkqSypbSyS2LpGB0go3%2FXDNoeRZJtTOnXq0kaPNRFVKXjPIJTrOH4fKirwmqVYWlWVQlTxFskV7RFSbK7mM0%2FDyzoFvZhsFDW6rTYaGGJ6JQ3UfzdPXndEHlZFUS20YPD67p7lssiBHw0sGzXuQ8Nwl7Gs4T1qN5%2Fzb0vXgCwfPtbKkEj%2BRAZ3RKDZYtJVXSPYodnvbXHSfHTrnhZA1u6xL0OljQJu%2FXvwQVQcDDbHTcBVJl8E54tELUdoUXH6hR71QyQeh1sGgVFup09deg4lpw3dt15WwQisGBiW34u3Skv2ojG0x1wZ9dO%2B5B%2Fj7%2B8%2FDkIxvKy0P1pH5gCRatyF9UxdeQdeYyJ%2BtLhnQew1f9v1Bw%2FBpcLsa2eG6FebLT5ghDPoybxo%2BvjYeI2DPTI%2Bhexrh3wvMBIcqNYgKuMpgWgjVrZCkKVXgUpoNN9UXdtXqPOmnPk%2FkeT4z%2Fv%2F%2Fbu7qMdgESre74ELn3Osc%2B3oAiy%2FLMkIkl0RCOVOc%2BUaTQpMrdRvOwl1dSw%2FrDLuMW85OKZGY2xt2OU1O%2FwCBl0XDoQYAAA%3D%3D%22%7D"
export var listWrap = '_15i9yfr0';
export var listWrapCont = '_15i9yfr4';
export var listWrapCont_p = '_15i9yfr5';
export var listWrapCont_span = '_15i9yfr6';
export var listWrapIcon = '_15i9yfr3';
export var listWrapLink = '_15i9yfr7';
export var listWrapLink_a = '_15i9yfr8';
export var listWrap_li = '_15i9yfr2';
export var listWrap_ul = '_15i9yfr1';
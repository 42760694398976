import React from "react";
import classNames from "classnames";
import { IconComponent, iconExists } from "@components/icons";
import { iconTxt, iconSvg } from "./IconBlock.css";

export const IconBlock = ({ value }: { value: any }) => {
  if (!value.text && !value.icon) return null;
  if (value.icon) {
    if (iconExists(value.icon)) {
      return (
        <div className={classNames(iconSvg)}>
          <IconComponent name={value.icon} />
        </div>
      );
    }
  } else if (value.text && value.text !== "") {
    return <span className={classNames(iconTxt)}>{value.text}</span>;
  }

  return null;
};

import classNames from "classnames";
import { ButtonSecondary } from "@czechtvPackagesOverrides/components/ButtonSecondary"
import { StaticContentBlockComponentProps } from "@components/blocks/StructuredContentBlock/sublocks/StaticContentBlock/StaticContentBlock";
import {
  writeToUs,
  writeToUsSvg, writeToUsTxt
} from "@components/webCareer/StaticContentComponents/AnotherPositionInterest/AnotherPositionInterest.css";

{
  /* TODO: až se vyhodí z BE tento staický blok (je nahrazen automatickým generování ve výpise inzerátu - ListJobsPositions.tsx) je potřeba ho vymazat i zde.*/
}
export const AnotherPositionInterest = ({}: StaticContentBlockComponentProps) => {

  return (
    <article className={classNames(writeToUs)}>
      <div className={classNames(writeToUsTxt)}>
        <h3>Nenašli jste, co jste hledali?</h3>
        Zašlete nám svůj životopis společně s kontaktními údaji a my se s vámi spojíme.
      </div>
      <ButtonSecondary anchor href={"/vse-o-ct/kariera/kontakty#formularKariera"}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classNames(writeToUsSvg)}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.22231 4.22199C8.5183 -0.073997 15.4823 -0.073997 19.7783 4.22199C24.0743 8.51798 24.0743 15.482 19.7783 19.778C15.4823 24.074 8.5183 24.074 4.22231 19.778C-0.0728006 15.482 -0.0728006 8.51798 4.22231 4.22199ZM19.0003 10.5L12.4476 13.7764L12.3241 13.8281C12.1143 13.8999 11.8864 13.8999 11.6765 13.8281L11.5531 13.7764L5.00035 10.5V15.4C5.00035 16.2346 5.63931 16.9199 6.45471 16.9935L6.60035 17H17.4003C18.2349 17 18.9202 16.361 18.9938 15.5456L19.0003 15.4V10.5ZM17.4003 7H6.60035C5.76578 7 5.08046 7.63896 5.00689 8.45437L5.00035 8.6V9.25L11.5531 12.5264L11.6765 12.5781C11.8864 12.6499 12.1143 12.6499 12.3241 12.5781L12.4476 12.5264L19.0003 9.25V8.6C19.0003 7.76544 18.3614 7.08011 17.546 7.00654L17.4003 7Z"
            fill="#041E42"
          />
        </svg>
        Napište nám
      </ButtonSecondary>
    </article>
  );
};

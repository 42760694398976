import React from 'react'
import { Content } from '@czechtv/components'
import classNames from 'classnames';
import Link from 'next/link'
import {
  speedLinkBoxContainer,
  speedLinkBoxHeader, speedLinkBoxLink,
  speedLinkBoxParagraph,
  headerDecoration
} from "@components/blocks/SpeedLinkBox/SpeedLinkBox.css";

export const SpeedLinkBox = () => {
  return (
    <Content>
        <div  className={classNames(speedLinkBoxContainer)}>
            <h2 className={classNames(speedLinkBoxHeader)}>Projekt historické a teoretické analýzy televizní tvorby</h2>
            <div className={classNames(headerDecoration)}></div>
            <p className={classNames(speedLinkBoxParagraph)}>Odborná reflexe televize jako média.</p>
            <Link
              href={"/historie/novodoba-historie-ct/projekt-historicke-a-teoreticke-analyzy-televizni-tvorby/"}
              className={classNames(speedLinkBoxLink)}>
              Studie - přehledy - analýzy
            </Link>
        </div>
    </Content>
  );
}
